/**
 *
 * useCompanyDetailsQuery
 * @format
 * @flow
 *
 */

import { gql, useLazyQuery, useQuery } from '@apollo/client';

import type { GetMyCompanyDetailsQuery } from 'app/services/graphQL/generated-types';
import { companyDetailsFragment } from 'company/graphQL/fragments/companyFragments';

const COMPANY_DETAILS = gql`
  query GetMyCompanyDetails($_id: ID!) {
    company(_id: $_id) {
      ...companyDetails
    }
  }
  ${companyDetailsFragment}
`;

export type CompanyDetailsReturnType = {
  loading: boolean,
  data: GetMyCompanyDetailsQuery,
  error: any,
  refetch: () => void,
};

export const useCompanyDetailsQuery = ({ _id }): CompanyDetailsReturnType => {
  const { loading, data, error, refetch } = useQuery<GetMyCompanyDetailsQuery>(
    COMPANY_DETAILS,
    {
      variables: { _id },
    }
  );

  return {
    loading,
    data,
    error,
    refetch,
  };
};

export type CompanyDetailsLazyReturnType = [
  any,
  { loading: boolean, data: GetMyCompanyDetailsQuery, error: any }
];

export const useCompanyDetailsLazyQuery = (): CompanyDetailsLazyReturnType => {
  const query = useLazyQuery<GetMyCompanyDetailsQuery>(COMPANY_DETAILS);

  return query;
};
