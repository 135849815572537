/**
 *
 * PredictiveTextField
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import PredictiveTextFieldBase from 'app/components/PredictiveTextField/PredictiveTextField';
import type { PropsType } from '../types';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';

const PredictiveTextField = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermProps,
    contractTermData,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);
  const { renderLinkData, getLinkDataProps } = useLinkDataComponent();

  const { value = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const {
    options = [],
    label = 'Type',
    linkData,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const { showSync, fieldId } = getLinkDataProps(linkData, 'value') || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      value: '',
    },
  });

  return (
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <PredictiveTextFieldBase
          id="listSelect"
          label={label}
          freeSolo
          options={options}
          sortByGroup
          inputValue={value}
          onBlur={(id, value) => {
            return onUpdate(contractTermKeyName, { value });
          }}
          renderLinkData={
            showSync &&
            renderLinkData(value, fieldId, (sourceFieldValue) => {
              if (sourceFieldValue === null && value === '') {
                onUpdate(contractTermKeyName, { [fieldId]: value });
              }
            })
          }
        />
      </Grid>
    </Grid>
  );
};

export default (memo(PredictiveTextField): AbstractComponent<PropsType, mixed>);
