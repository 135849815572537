/**
 *
 * ContractingParties
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Alert, Button, Fab, Grid, Paper, Snackbar } from '@mui/material';
import type { ContractingPartyType, PropsType } from './types';
import React, { Fragment, memo, useEffect, useState } from 'react';

import AddressInfo from 'app/components/AddressInfo/AddressInfo';
import Checkbox from 'app/components/Checkbox/Checkbox';
import type { CompanyDetailsReturnType } from 'company/graphQL/useCompanyDetailsQuery';
import { EntityTypeValues } from 'app/services/graphQL/generated-types';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import Tooltip from 'app/components/Tooltip/Tooltip';
import type { UpdateCompanyContractingPartiesReturnType } from 'company/graphQL/useUpdateCompanyContractingPartiesMutation';
import { useCompanyDetailsQuery } from 'company/graphQL/useCompanyDetailsQuery';
import { useParams } from 'react-router-dom';
import { useUpdateCompanyContractingPartiesMutation } from 'company/graphQL/useUpdateCompanyContractingPartiesMutation';

const ContractingParties: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const {
    data: companyDetailsData,
    loading,
    error,
  }: CompanyDetailsReturnType = useCompanyDetailsQuery({
    _id: cyid,
  });
  const {
    updateCompanyContractingParties,
    data: updateCompanyContractingPartiesData,
    loading: contractingPartiesIsSaving,
  }: UpdateCompanyContractingPartiesReturnType = useUpdateCompanyContractingPartiesMutation();

  const [data, setData] = useState<Array<ContractingPartyType>>([]);

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  // comment for now
  useEffect(() => {
    if (!!companyDetailsData?.company) {
      const company = companyDetailsData.company || {};
      // $FlowFixMe
      const { __typename: __typename1, ...restCompanyData } = company || {};
      const newContractingParties = restCompanyData?.contractingParties?.map(
        (item) => {
          const {
            // $FlowFixMe
            __typename: __typename2,
            address,
            ...restContractingPartiesData
          } = item || {};

          const {
            // $FlowFixMe
            __typename: __typename3,
            ...restAddressData
          } = address || {};
          return {
            ...restContractingPartiesData,
            // $FlowFixMe
            ...restAddressData,
          };
        }
      );

      // $FlowFixMe
      setData(newContractingParties);
    }
  }, [companyDetailsData]);

  useEffect(() => {
    if (
      !!updateCompanyContractingPartiesData?.updateCompanyContractingParties
        ?.company
    ) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the contracting parties details!',
      });
      setSnackbarOpen(true);
    }
    if (
      updateCompanyContractingPartiesData?.updateCompanyContractingParties
        ?.company === null
    ) {
      setAlertInfo({
        severity: 'error',
        message: 'Error saving the contracting parties details!',
      });
      setSnackbarOpen(true);
    }
  }, [updateCompanyContractingPartiesData]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!companyDetailsData?.company) return <p>Not found</p>;

  const defaultContractingPartyData = {
    name: '',
    type: EntityTypeValues.Company,
    abn: '',
    email: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    number: '',
    isCheckUseAccountDetails: false,
  };

  const handleStateChange = (
    fieldName: string,
    fieldValue: any,
    index: number
  ) => {
    const newItems = [...data];
    const currItem = newItems[index];
    const newItem = {
      ...currItem,
      [fieldName]: fieldValue,
    };
    newItems[index] = newItem;
    setData(newItems);
  };

  const handleUpdateCompanyDetails = () => {
    updateCompanyContractingParties({
      variables: {
        data: {
          _id: cyid,
          contractingPartyDetails: data.map((item) => {
            const {
              isCheckUseAccountDetails,
              street = '',
              suburb,
              state,
              postcode = '',
              country = '',
              number = '',
              abn,
              ...restItem
            } = item || {};
            // $FlowFixMe
            return {
              ...restItem,
              ...(abn && { abn: Number(abn) }),
              address: {
                number: Number(number),
                street,
                suburb,
                state,
                postcode,
                country,
              },
            };
          }),
        },
      },
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleAddContractingParty = () => {
    const newItems = [...data, defaultContractingPartyData];
    setData(newItems);
  };

  const getAccountDetails = (isCheckUseAccountDetails) => {
    const company = companyDetailsData.company || {};

    const { name, address, abn, email } = company || {};
    const { street, suburb, state, postcode, country, number } = address || {};
    const currentAccountDetails = {
      name: name || '',
      street,
      suburb: suburb || '',
      state: state || '',
      postcode,
      country,
      number: number?.toString(),
      abn: abn?.toString(),
      email: email || '',
      isCheckUseAccountDetails,
    };
    const accountDetails = isCheckUseAccountDetails
      ? currentAccountDetails
      : defaultContractingPartyData;
    return accountDetails;
  };

  const handleCopyAccountDetails = (
    isCheckUseAccountDetails: boolean,
    index: number
  ) => {
    const newItems = [...data];
    const currItem = newItems[index];
    // $FlowFixMe
    const newItem = {
      // $FlowFixMe
      ...currItem,
      ...getAccountDetails(isCheckUseAccountDetails),
    };
    newItems[index] = newItem;
    setData(newItems);
  };

  const handleDelete = (index) => {
    const newItems = data.filter((item, i) => i !== index);
    setData(newItems);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tooltip
          body={`${
            !data.length
              ? 'Add a contracting party'
              : 'Add another contracting party'
          }`}
          placement="top"
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={handleAddContractingParty}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
      {data.map((item, idx) => {
        const {
          name,
          type,
          street,
          suburb,
          state,
          postcode,
          country,
          number,
          abn,
          email,
          isCheckUseAccountDetails = false,
        } = item;
        return (
          <Fragment key={idx.toString()}>
            <Grid item>
              <Paper elevation={1}>
                <Grid
                  container
                  justifyContent={
                    type === EntityTypeValues.Individual
                      ? 'space-between'
                      : 'flex-end'
                  }
                >
                  {type === EntityTypeValues.Individual && (
                    <Grid item lg={6} md={6} xs={6}>
                      <Checkbox
                        id="isCheckUseAccountDetails"
                        label="Use account details"
                        value={isCheckUseAccountDetails}
                        onCheck={(id, val) => {
                          handleCopyAccountDetails(val, idx);
                        }}
                      />
                    </Grid>
                  )}
                  {idx !== 0 && (
                    <Grid item>
                      <Button
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          handleDelete(idx);
                        }}
                      ></Button>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <TextField
                    id="name"
                    label="Name"
                    value={name}
                    onBlur={(id, val) => {
                      handleStateChange(id, val, idx);
                    }}
                    tooltipBody="Include full name company name here (including Pty Ltd etc)."
                  />
                </Grid>
                <Grid container>
                  <Select
                    id="type"
                    label="Type"
                    value={type}
                    onSelectOption={(item) => {
                      !Array.isArray(item) &&
                        handleStateChange('type', item.value, idx);
                    }}
                    options={[
                      {
                        value: EntityTypeValues.Company,
                        label: 'Company (e.g. Pty Ltd)',
                      },
                      {
                        value: EntityTypeValues.Individual,
                        label: 'No Company use my details',
                      },
                    ]}
                  />
                </Grid>
                <AddressInfo
                  street={street}
                  suburb={suburb}
                  state={state}
                  postcode={postcode}
                  country={country}
                  number={number}
                  currFieldIndex={idx}
                  onUpdateData={handleStateChange}
                />
                <Grid container>
                  <Grid item lg={5.4} md={5.4} xs={5.4}>
                    <TextField
                      id="abn"
                      label="ABN"
                      value={abn}
                      numberOnly
                      onBlur={(id, val) => {
                        handleStateChange(id, val, idx);
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextField
                      id="email"
                      label="Email Contact"
                      value={email}
                      onBlur={(id, val) => {
                        handleStateChange(id, val, idx);
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Fragment>
        );
      })}
      {data.length > 0 && (
        <Grid item xs>
          <LoadingButton
            isLoading={contractingPartiesIsSaving}
            loadingText="Saving..."
            defaultText="Save"
            onClick={handleUpdateCompanyDetails}
          />
        </Grid>
      )}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default (memo(ContractingParties): AbstractComponent<PropsType, mixed>);
