// Account
import AccountView from 'account/AccountView/AccountView';
//Company
import CompanyAccount from 'company/CompanyAccount/CompanyAccount';
import CompanyContractsView from 'company/CompanyContractsView/CompanyContractsView';
import ContractTemplatesView from 'contract/ContractTemplatesView/ContractTemplatesView';
import ContractView from 'contract/ContractView/ContractView';
//Users
// import ProfileView from 'user/ProfileView/ProfileView';
import ProfileView from 'user/AccountView/AccountView';
import ProjectView from 'project/ProjectView/ProjectView';
//Project
import ProjectsView from 'project/ProjectsView/ProjectsView';

const dashboardRoutes = [
  {
    path: ['/dashboard/account'],
    name: 'AccountView',
    component: AccountView,
    exact: true,
  },
  {
    path: ['/dashboard/profile'],
    name: 'Profile',
    component: ProfileView,
    exact: true,
  },
  {
    path: ['/dashboard/:cyid/contracts'],
    name: 'Company',
    component: CompanyContractsView,
    exact: true,
  },
  {
    path: ['/dashboard/:cyid/projects'],
    name: 'Projects',
    component: ProjectsView,
    exact: true,
  },
  {
    path: ['/dashboard/:cyid/company'],
    name: 'Projects',
    component: CompanyAccount,
    exact: true,
  },
  {
    path: [
      '/dashboard/:cyid/project/:ptid/contract/:ctid',
      '/dashboard/:cyid/contract/:ctid',
    ],
    component: ContractView,
    exact: true,
  },
  {
    path: ['/dashboard/:cyid/contract-templates'],
    name: 'Templates',
    component: ContractTemplatesView,
    exact: true,
  },
  {
    path: ['/dashboard/:cyid/project/:ptid'],
    name: 'Projects',
    component: ProjectView,
    exact: true,
  },
];

export default dashboardRoutes;
