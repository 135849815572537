/**
 *
 * NetBudget
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo } from 'react';

import Grid from '@mui/material/Grid';
import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';

const NetBudget = (props: PropsType): Node => {
  const {
    maxLabel = 'Maximum',
    minLabel = 'Minimum',
    checkboxLabel = 'Less and amounts paid to Contributor before production',
    showCheckbox = true,
    approvalCheckboxText = '',
    budget = '',
    minBudget = '',
    maxBudget = '',
    approvalCheckboxTextValue = '',
    onUpdate,
  } = props;

  return (
    <>
      <Grid item xs={1.5}>
        <TextField
          id="budget"
          value={budget}
          formatMoney
          numberOnly
          endAdornment={<>%</>}
          onBlur={(id, val) => onUpdate(id, val)}
        />
      </Grid>
      <Grid container justifyContent="flex-start" alignItems="row" spacing={5}>
        <Grid item xs={3}>
          <TextField
            id="minBudget"
            label={minLabel}
            value={minBudget}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={(id, val) => onUpdate(id, val)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="maxBudget"
            label={maxLabel}
            value={maxBudget}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={(id, val) => onUpdate(id, val)}
          />
        </Grid>
      </Grid>
      {showCheckbox && (
        <TextGeneratingCheckbox
          id="approvalCheckboxTextValue"
          label={checkboxLabel}
          value={approvalCheckboxTextValue}
          textGenerating={approvalCheckboxText}
          onCheck={(id, val) => onUpdate(id, val)}
        />
      )}
    </>
  );
};

export default (memo(NetBudget): AbstractComponent<PropsType, mixed>);
