/**
 *
 * useCreateDocumentMutation
 * @format
 * @flow
 *
 */

import type {
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

const CREATE_DOCUMENT = gql`
  mutation CreateDocument($document: CreateDocumentInput!) {
    createDocument(document: $document) {
      document {
        _id
        company
        contract
        project
        tags
        fileName
        fileType
        mimeType
        uploadedBy
        notes
        fileData {
          uploadUrl
          downloadUrl
        }
      }
    }
  }
`;

export type CreateDocumentReturnType = {
  createDocument: ({
    variables: CreateDocumentMutationVariables,
  }) => void,
  loading: boolean,
  data: CreateDocumentMutation,
  error: any,
};

export const useCreateDocumentMutation = (): CreateDocumentReturnType => {
  const [createDocument, { data, loading, error }] = useMutation(
    CREATE_DOCUMENT,
    {
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    createDocument,
    loading,
    data,
    error,
  };
};
