/**
 *
 * TextGeneratingRadioButtonGroup
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useState } from 'react';

import type { PropsType } from './types';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import TextGeneratingRadioButton from 'app/components/TextGeneratingRadioButton/TextGeneratingRadioButton';
import TwoButtonDialogBox from 'app/components/TwoButtonDialogBox/TwoButtonDialogBox';

const TextGeneratingRadioButtonGroup = ({
  radioId = 'radioValue',
  radioValue = '',
  textId1 = 'textValue1',
  textValue1 = '',
  textId2 = 'textValue2',
  textValue2 = '',
  radioLabel1 = '',
  radioValue1 = '',
  textGenerating1 = '',
  radioLabel2 = '',
  radioValue2 = '',
  textGenerating2 = '',
  radio1ResetData = {},
  radio2ResetData = {},
  checkRadio1DataIsEmpty = () => true,
  checkRadio2DataIsEmpty = () => true,
  setRadio1DefaultData,
  setRadio2DefaultData,
  onUpdate,
}: PropsType): Node => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [currentRadioData, setCurrentRadioData] = useState({});
  const [currentTextGeneratingData, setCurrentTextGeneratingData] = useState(
    {}
  );

  const onDialogClickOne = (callback) => {
    setIsOpenDialog(false);
    setCurrentRadioData({ id: '', val: '' });
    setCurrentTextGeneratingData({ id: '', val: '' });
  };

  const onDialogClickTwo = (callback) => {
    const { id: radioId, val: radioVal } = currentRadioData;
    const { id: textGeneratingId, val: textGeneratingVal } =
      currentTextGeneratingData;

    const textGeneratingIdToEmpty =
      textGeneratingId === textId1 ? textId2 : textId1;

    const dataToReset =
      textGeneratingId === textId1 ? radio2ResetData : radio1ResetData;

    const data = {
      [radioId]: radioVal,
      [textGeneratingId]: textGeneratingVal,
      // $FlowFixMe
      [textGeneratingIdToEmpty]: '',
      ...dataToReset,
    };

    onUpdate(data);
    setIsOpenDialog(false);
  };

  const handleRadioSelection = (
    radioVal,
    textGeneratingId,
    textGeneratingVal
  ) => {
    setCurrentRadioData({ id: `${radioId}`, val: radioVal });
    setCurrentTextGeneratingData({
      id: textGeneratingId,
      val: textGeneratingVal,
    });
    setIsOpenDialog(true);
  };

  const onUpdateData = (
    radioVal,
    textGeneratingId,
    textGeneratingVal,
    textGeneratingIdToEmpty
  ) => {
    onUpdate({
      [textGeneratingId]: textGeneratingVal,
      [`${textGeneratingIdToEmpty}`]: '',
      ...(radioValue1 && radioValue2 && { [`${radioId}`]: radioVal }),
    });
  };

  return (
    <>
      <RadioButtonGroup
        id={radioId}
        value={radioValue}
        onValueChange={(id, val) => {}}
      >
        <TextGeneratingRadioButton
          id={textId1}
          label={radioLabel1}
          value={radioValue1}
          textGenerating={textGenerating1}
          checked={textValue1 === textGenerating1}
          onValueChange={(id, val, textGenerating) => {
            if (setRadio1DefaultData) {
              setRadio1DefaultData();
            }
            if (!checkRadio2DataIsEmpty()) {
              handleRadioSelection(val, id, textGenerating);
            } else {
              onUpdateData(val, id, textGenerating, 'textValue2');
            }
          }}
        />
        <TextGeneratingRadioButton
          id={textId2}
          label={radioLabel2}
          value={radioValue2}
          textGenerating={textGenerating2}
          checked={textValue2 === textGenerating2}
          onValueChange={(id, val, textGenerating) => {
            if (setRadio2DefaultData) {
              setRadio2DefaultData();
            }
            if (!checkRadio1DataIsEmpty()) {
              handleRadioSelection(val, id, textGenerating);
            } else {
              onUpdateData(val, id, textGenerating, 'textValue1');
            }
          }}
        />
      </RadioButtonGroup>
      <TwoButtonDialogBox
        open={isOpenDialog}
        onClickOne={onDialogClickOne}
        onClickTwo={onDialogClickTwo}
        labelButtonOne="Cancel"
        labelButtonTwo="OK"
        titleDialog="Changing selection will delete the data for this term"
      />
    </>
  );
};

export default (memo(TextGeneratingRadioButtonGroup): AbstractComponent<
  PropsType,
  mixed
>);
