import * as React from 'react';

import { AppBar, DrawerHeader, Main } from './styles';
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Menu as MenuIcon,
} from '@mui/icons-material';

import ReactMarkdown from 'react-markdown';
import TableOfContents from './TableOfContents';
import baInfoData from './ba-info.json';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 400;

export default function InfoPage() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const renderTraverseContent = (arrData = []) => {
    return (
      <>
        {arrData.map((heading) => (
          <div key={heading.id}>
            <Typography variant="h4" id={heading.id}>
              {heading.title}
            </Typography>
            {heading?.content && (
              <ReactMarkdown
                children={heading.content}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            )}
            {heading?.items?.length > 0 &&
              renderTraverseContent(heading?.items)}
          </div>
        ))}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            businessaffairs.com-info
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Typography variant="h5" sx={{ marginLeft: 8, marginBottom: 0 }}>
            Table of Contents
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <TableOfContents nestedHeadings={baInfoData} />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {renderTraverseContent(baInfoData)}
      </Main>
    </Box>
  );
}
