import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from '../types';
import Select from 'app/components/Select/Select';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';

const LawJuris: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { juris = '' }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const { options = [], linkData }: ComponentPropsType =
    contractTermProps[contractTermKeyName] || {};

  const { showSync } = linkData || {};

  const { renderLinkData } = useLinkDataComponent();

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      juris: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <Select
      id="juris"
      value={juris}
      onSelectOption={(item) => onUpdateData('juris', item.value)}
      options={options.map((item) => ({
        value: item,
        label: item,
      }))}
      renderLinkData={showSync && renderLinkData(juris)}
    />
  );
};

export default (memo(LawJuris): AbstractComponent<PropsType, mixed>);
