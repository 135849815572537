// @flow

import * as React from 'react'; // use this format to import flow types

import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Snackbar,
  Typography,
  Box,
} from '@mui/material';
import { useContext, useEffect, useState, createRef } from 'react';

import CollapsableSwitchMenu from 'app/containers/CollapsableSwitchMenu/CollapsableSwitchMenu';
import ComponentWrapper from 'contract/ContractView/ContractBuilder/ComponentWrapper/ComponentWrapper';
import type { Contract } from '../../../app/services/graphQL/generated-types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import ContractStandardTerms from 'contract/components/ContractStandardTerms/ContractStandardTerms';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { scrollIntoView } from 'app/utils/contract';
import LinkDataProvider from 'app/components/LinkData/contexts/LinkDataContext';
import LinkDataWrapper from 'app/components/LinkData/LinkDataWrapper';
import { AccordionStyled } from './styles';
import { useTheme } from '@mui/material/styles';
import NotSynchronizeDialog from 'app/components/LinkData/modals/NotSynchronizeDialog/NotSynchronizeDialog';
import ChangeDetailsDialog from 'app/components/LinkData/modals/ChangeDetailsDialog/ChangeDetailsDialog';
import AffectedContractsDialog from 'app/components/LinkData/modals/AffectedContractsDialog/AffectedContractsDialog';

export type ComponentsModule = {};

type Props = {
  components: ComponentsModule,
  contractData: Contract,
  isExpandAll: boolean,
};

const ContractBuilder = ({
  components,
  contractData,
}: Props): React.Node => {
  const theme = useTheme();
  const {
    contractTermData,
    isErrorSavingContract,
    isSuccessSavingContract,
    onSetContractData,
    isErrorCreatingWordDoc,
    isSuccessCreatingWordDocument,
    contractExpandedPanels,
  } = useContext(ContractContext);
  const {
    expandedPanels: panels,
    contractSection: { sectionName, index },
    termId,
  } = contractExpandedPanels;

  const expandedPanelsData = Array(contractData?.sectionNames?.length).fill(
    false
  );
  const [expandedPanels, setExpandedPanels] = useState(expandedPanelsData);
  const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  // Contract sections and terms ref for scrollIntoView
  const sectionsRef = contractData.sectionNames
    .map((section) => ({
      section,
    }))
    .reduce((acc, sectionName, index) => {
      acc[sectionName.section] = createRef();
      return acc;
    }, {});

  const contractTerms = contractData.sectionNames
    .map((section, sectionIndex) => {
      return {
        sectionIndex,
        terms: contractData.terms.filter(
          (item) => item.uiComponentSectionIndex.indexOf(sectionIndex) !== -1
        ),
      };
    })
    .flatMap((t) => t.terms);

  const contractTermsRef = contractTerms.reduce((acc, term, index) => {
    acc[term.termId] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (isErrorSavingContract || isErrorCreatingWordDoc) {
      setAlertInfo({
        severity: 'error',
        message: isErrorSavingContract
          ? 'Error saving the contract!'
          : 'Error creating the word document!',
      });
      setSnackbarOpen(true);
    }
  }, [isErrorSavingContract, isErrorCreatingWordDoc]);

  useEffect(() => {
    if (isSuccessSavingContract || isSuccessCreatingWordDocument) {
      setAlertInfo({
        severity: 'success',
        message: isSuccessSavingContract
          ? 'Success saving the contract!'
          : 'Success creating the word document. Go to Project->Uploads->Other->Contracts',
      });
      setSnackbarOpen(true);
    }
  }, [isSuccessSavingContract, isSuccessCreatingWordDocument]);

  const onCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    onSetContractData(contractData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setExpandedPanels(panels);

    // settimeout for scroll to animate
    if (panels[index]) {
      const el = sectionsRef[sectionName]?.current;
      setTimeout(() => {
        scrollIntoView(el, -230);
      }, 200);
    }

    if (termId) {
      const el = contractTermsRef[termId]?.current;
      setTimeout(() => {
        scrollIntoView(el, -230);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels, termId]);

  const onAccordionChange = (index) => (event, newExpanded) => {
    let newExpandedPanels = [...expandedPanels];
    newExpandedPanels[index] = newExpanded ? true : false;
    setExpandedPanels(newExpandedPanels);
  };

  //Make sure onSetContractData(contractData) has been processed
  if (Object.keys(contractTermData).length === 0) {
    return '';
  }

  return (
    <div>
      {contractData.sectionNames?.map((sectionName, sectionIndex) => {
        const panelNum = sectionIndex + 1;
        const termComponents = contractData.terms.filter(
          (item) => item.uiComponentSectionIndex.indexOf(sectionIndex) !== -1
        );
        return (
          <AccordionStyled
            key={sectionIndex}
            expanded={expandedPanels[sectionIndex] || false}
            elevation={1}
            onChange={onAccordionChange(sectionIndex)}
            ref={sectionsRef[sectionName]}
            className="section"
          >
            <AccordionSummary
              // className={classes.summary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${panelNum}-content`}
              id={`panel${panelNum}-header`}
            >
              <Typography
                color={
                  expandedPanels[sectionIndex]
                    ? theme.palette.primary.main
                    : theme.palette.grey['800']
                }
                variant="h6"
              >
                {sectionName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                {termComponents.map((term, index) => {
                  const {
                    uiComponentName: componentName,
                    termId,
                    props,
                  } = term;
                  const contractTermKeyName = `${componentName}-${termId}`;

                  const Component = components[componentName];
                  //TODO: needs adding to logger when implemented (see AB#1216)
                  if (!Component) {
                    console.error(
                      `ContractBuilder could not find component ${componentName}`
                    );
                    return null;
                  }

                  return (
                    <Grid
                      item
                      key={`${termId}-${index}`}
                      ref={contractTermsRef[termId]}
                    >
                      <div>
                        <ComponentWrapper>
                          <CollapsableSwitchMenu
                            contractTermKeyName={contractTermKeyName}
                          >
                            <Box sx={{ marginLeft: '15px' }}>
                              <LinkDataProvider>
                                <LinkDataWrapper
                                  key={contractTermKeyName}
                                  contractTermKeyName={contractTermKeyName}
                                >
                                  <Component
                                    key={contractTermKeyName}
                                    contractTermKeyName={contractTermKeyName}
                                    linkData={props?.linkData}
                                  />
                                </LinkDataWrapper>
                                <NotSynchronizeDialog />
                                <ChangeDetailsDialog />
                                <AffectedContractsDialog />
                              </LinkDataProvider>
                            </Box>
                          </CollapsableSwitchMenu>
                        </ComponentWrapper>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </AccordionStyled>
        );
      })}
      <ContractStandardTerms />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onCloseSnackbar}
      >
        <Alert
          onClose={onCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContractBuilder;
