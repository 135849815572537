/**
 *
 * ProjectContributorContracts
 * @format
 * @flow
 *
 */

import { Fab, Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { Add as AddIcon } from '@mui/icons-material';
import type { ContractDetailsReturnType } from 'contract/graphQL/useProjectContractsQuery';
import ContractsTable from 'contract/components/ContractsTable/ContractsTable';
import type { Node } from 'react';
import React from 'react';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { useProjectContractsQuery } from 'contract/graphQL/useProjectContractsQuery';

const ProjectContributorContracts = (): Node => {
  const history = useHistory();
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const ptid = routeParams.ptid || '';
  const { loading, error, data }: ContractDetailsReturnType =
    useProjectContractsQuery({ _id: ptid ?? '' });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  const { projectContracts } = data;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ContractsTable data={projectContracts} />
      </Grid>
      <Grid item xs={12}>
        <Grid container alignItems="flex-end" justifyContent="flex-end">
          <Tooltip body="Create contract (select template)" placement="top-end">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                const routeTo = `/dashboard/${cyid}/project/${ptid}/contract-templates`;
                history.push(routeTo);
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectContributorContracts;
