/**
 *
 * NewProjectModal
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import type { ProjectsViewProjectsReturnType } from 'project/graphQL/useProjectsViewProjectsQuery';
import type { PropsType } from './types';
import RadioButton from 'app/components/RadioButton/RadioButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import { isOtherType } from 'app/utils/general';
import { useCreateProjectMutation } from 'project/graphQL/useCreateProjectMutation';
import { useParams } from 'react-router-dom';
import { useProjectsViewProjectsQuery } from 'project/graphQL/useProjectsViewProjectsQuery';
import { useUISelectValuesConfigQuery } from 'project/graphQL/useUISelectValuesConfigQuery';

const NewProjectModal: AbstractComponent<PropsType> = ({
  open,
  onClose,
}: PropsType): Node => {
  const {
    createProject,
    data: createProjectData,
    loading: createProjectLoading,
  } = useCreateProjectMutation();
  const {
    data,
    loading,
    // eslint-disable-next-line no-unused-vars
    error,
  } = useUISelectValuesConfigQuery();
  const { refetch }: ProjectsViewProjectsReturnType =
    useProjectsViewProjectsQuery();

  const routeParams = useParams();
  const cyid = routeParams.cyid || '';

  // eslint-disable-next-line no-unused-vars
  const [combinedState, setCombinedState] = useState({
    title: '',
    genreTypeId: 0,
    genreOtherSpecify: '',
    single: true,
    // seriesId: '',
    noOfEpisodes: 0,
    duration: 0,
    currency: 'AUD',
    form: 'test form',
    initialReleasePlatformTypeId: 0,
    initialReleaseOtherSpecify: '',
    shortDescription: '',
    shootLocation: '',
    developmentBudgets: [],
    productionBudget: 0,
    companySPVs: [],
  });

  useEffect(() => {
    if (data) {
      // Need to update our local data so it aligns with the data in the
      // GeneralDetailsBasicInfo component as the user may not update anything
      // in that component and expect the pre-selected dropdown to be saved.
      const {
        uiSelectValuesConfig: {
          projectDetailSelectValues: { genreValues, releasePlatformValues },
        },
      } = data;
      const sortedGenreValues = Array.isArray(genreValues)
        ? [...genreValues].sort((a, b) => a.order - b.order)
        : [];
      const sortedReleasePlatformValues = Array.isArray(releasePlatformValues)
        ? [...releasePlatformValues].sort((a, b) => a.order - b.order)
        : [];
      setCombinedState((prevState) => ({
        ...prevState,
        genreTypeId:
          sortedGenreValues.find((item) => item.displayValue === 'TBC')
            ?.typeInfo.id || 0,
        initialReleasePlatformTypeId:
          sortedReleasePlatformValues.find(
            (item) => item.displayValue === 'TBC'
          )?.typeInfo.id || 0,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (createProjectData) {
      refetch();
      onClose(new Event('project created'), 'project created');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createProjectData]);

  const handleStateChange = (key: string, value: any) => {
    setCombinedState((prev) => {
      const newCombinedState: any = { ...prev, [key]: value };
      setCombinedState(newCombinedState);
      return newCombinedState;
    });
  };

  const createProjectClicked = () => {
    const {
      title,
      genreTypeId,
      genreOtherSpecify,
      single,
      noOfEpisodes,
      duration,
      currency,
      form,
      initialReleasePlatformTypeId,
      initialReleaseOtherSpecify,
      developmentBudgets,
      productionBudget,
      shortDescription,
      shootLocation,
      companySPVs,
    } = combinedState;

    createProject({
      variables: {
        project: {
          company: cyid,
          title,
          genreTypeId: Number(genreTypeId),
          genreOtherSpecify,
          single,
          noOfEpisodes,
          duration,
          currency,
          form,
          initialReleasePlatformTypeId: Number(initialReleasePlatformTypeId),
          initialReleaseOtherSpecify,
          developmentBudgets,
          productionBudget,
          shortDescription,
          shootLocation,
          companySPVs,
        },
      },
    });
  };

  // as this is a modal we don't want to show the loading indicator in case the parent
  // page also shows one.
  if (loading) return <div />;
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!data?.uiSelectValuesConfig?.projectDetailSelectValues)
    return <p>Not found</p>;

  const {
    uiSelectValuesConfig: {
      projectDetailSelectValues: { genreValues, releasePlatformValues },
    },
  } = data;

  const sortedGenreValues = Array.isArray(genreValues)
    ? [...genreValues].sort((a, b) => a.order - b.order)
    : [];
  const sortedReleasePlatformValues = Array.isArray(releasePlatformValues)
    ? [...releasePlatformValues].sort((a, b) => a.order - b.order)
    : [];

  const {
    title,
    genreTypeId,
    genreOtherSpecify,
    single,
    noOfEpisodes,
    duration,
    initialReleasePlatformTypeId,
    initialReleaseOtherSpecify,
  } = combinedState;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="title">
              Add a title to create a new project
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <TextField
                id="title"
                label="Title"
                value={title}
                onBlur={handleStateChange}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              sx={{ marginTop: '40px', marginBottom: '40px' }}
            >
              <Button
                onClick={(e) => {
                  onClose(e, 'cancelled');
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                isLoading={createProjectLoading}
                loadingText="Creating..."
                defaultText="Create Project"
                onClick={createProjectClicked}
                containerStyle={{ marginTop: 0 }}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
              <Typography variant="title">
                Add the following details now or later
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '30px' }}>
              <Paper elevation={1}>
                <Select
                  id="genreTypeId"
                  label="Genre"
                  value={genreTypeId}
                  onSelectOption={(item) => {
                    !Array.isArray(item) &&
                      handleStateChange('genreTypeId', item.value);
                  }}
                  options={sortedGenreValues.map((item) => ({
                    value: String(item.typeInfo.id),
                    label: item.displayValue,
                  }))}
                />
                {isOtherType(genreTypeId) && (
                  <TextField
                    id="genreOtherSpecify"
                    label="Other (specify)"
                    value={genreOtherSpecify ?? ''}
                    onBlur={handleStateChange}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '30px' }}>
              <Paper elevation={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <RadioButtonGroup
                      id="single"
                      row={true}
                      label="Type"
                      value={single ? 'single' : 'series'}
                      onValueChange={(id, val) =>
                        handleStateChange(id, val === 'single')
                      }
                    >
                      <RadioButton id="Single" label="Single" value="single" />
                      <RadioButton id="Series" label="Series" value="series" />
                    </RadioButtonGroup>
                  </Grid>

                  {!single && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          id="noOfEpisodes"
                          label="Number of Episodes"
                          value={noOfEpisodes}
                          numberOnly
                          onBlur={(id, val) =>
                            handleStateChange(id, parseInt(val, 10))
                          }
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      id="duration"
                      label="Duration (mins)"
                      value={duration}
                      numberOnly
                      onBlur={(id, val) =>
                        handleStateChange(id, parseInt(val, 10))
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={1}>
                <Select
                  id="initialReleasePlatformTypeId"
                  label="Initial Release"
                  value={initialReleasePlatformTypeId}
                  onSelectOption={(item) => {
                    !Array.isArray(item) &&
                      handleStateChange(
                        'initialReleasePlatformTypeId',
                        item.value
                      );
                  }}
                  options={sortedReleasePlatformValues.map((item) => ({
                    value: String(item.typeInfo.id),
                    label: item.displayValue,
                  }))}
                />
                {isOtherType(initialReleasePlatformTypeId) && (
                  <TextField
                    id="initialReleaseOtherSpecify"
                    label="Other (specify)"
                    value={initialReleaseOtherSpecify ?? ''}
                    onBlur={handleStateChange}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default (memo(NewProjectModal): AbstractComponent<PropsType, mixed>);
