/**
 *
 * useUpdateProjectGeneralDetailsMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateProjectGeneralDetailsMutation,
  UpdateProjectGeneralDetailsMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { createProjectDetailsFragment } from 'project/graphQL/fragments/projectFragments';

const UPDATE_PROJECT_GENERAL_DETAILS = gql`
  mutation UpdateProjectGeneralDetails($project: ProjectGeneralDetailsInput!) {
    updateProjectGeneralDetails(project: $project) {
      project {
        ...createProjectDetails
      }
    }
  }
  ${createProjectDetailsFragment}
`;

export type UpdateProjectGeneralDetailsReturnType = {
  updateProjectGeneralDetails: ({
    variables: UpdateProjectGeneralDetailsMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateProjectGeneralDetailsMutation,
  error: any,
};

export const useUpdateProjectGeneralDetailsMutation =
  (): UpdateProjectGeneralDetailsReturnType => {
    const [updateProjectGeneralDetails, { data, loading, error }] = useMutation(
      UPDATE_PROJECT_GENERAL_DETAILS
    );

    return {
      updateProjectGeneralDetails,
      loading,
      data,
      error,
    };
  };
