/**
 *
 * ContractStandardTerms
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import React, { memo, useContext, useState } from 'react';

import AmendmentDialog from 'app/components/AmendmentDialog/AmendmentDialog';
// $FlowFixMe
import { ReactComponent as AsteriskIcon } from 'app/assets/svgs/asterisk.svg';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { PropsType } from './types';
import Tooltip from 'app/components/Tooltip/Tooltip';
import cloneDeep from 'lodash/cloneDeep';
import { useTheme } from '@mui/material/styles';
import { processContractStandardTermSections } from './ContractStandardTermsConditionParser';
import { AccordionStyled, ClauseParagraphsContainer } from './styles';

const ContractStandardTerms: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { accordionTitle = 'Standard Terms' } = props;
  const theme = useTheme();
  const {
    contractStandardTermSections = [],
    onSetContractStandardTermSections,
    contractTermData,
  } = useContext(ContractContext);
  const [openAmendmentDialogue, setOpenAmendmentDialogue] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const updateStandardTermsSections = (
    standardTermSectionIdx,
    termIdx,
    val,
    closeAmendmentDialogue = false
  ) => {
    const newContractStandardTermSections = cloneDeep(
      contractStandardTermSections
    );
    newContractStandardTermSections[standardTermSectionIdx].terms[
      termIdx
    ].amendment = val;

    onSetContractStandardTermSections(newContractStandardTermSections);

    if (closeAmendmentDialogue) {
      setOpenAmendmentDialogue(false);
    }
  };

  const processedContractStandardTermSections =
    processContractStandardTermSections(
      contractStandardTermSections,
      contractTermData
    );

  const generateClauseParagraphs = (clauseParagraphs) => {
    let orderedList = '';
    let textItems = '';

    clauseParagraphs.forEach((clause, index) => {
      if (clause.isList) {
        // If it's a list item
        textItems += `<li>${clause.text}</li>`;
      } else {
        // If it's not a list item
        if (textItems) {
          orderedList += `<ol>${textItems}</ol>`;
          textItems = '';
        }
        // Add the non-list item
        orderedList += `<p>${clause.text}</p>`;
      }

      // If it's the last item and list items are pending, wrap them in an ordered list
      if (index === clauseParagraphs.length - 1 && textItems) {
        orderedList += `<ol>${textItems}</ol>`;
      }
    });

    return orderedList;
  };

  return (
    <AccordionStyled
      expanded={expanded}
      key={0}
      elevation={1}
      onChange={(_e, val) => {
        setExpanded(val);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${1}-content`}
        id={`panel${1}-header`}
      >
        <Typography
          variant="h6"
          color={
            expanded ? theme.palette.primary.main : theme.palette.grey['800']
          }
        >
          {accordionTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {processedContractStandardTermSections?.map(
          (standardTermSections, standardTermSectionIdx) => {
            const { title, terms } = standardTermSections || {};
            return (
              <Grid
                container
                direction="column"
                spacing={2}
                key={standardTermSectionIdx.toString()}
                sx={{ marginBottom: 1 }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: theme.typography.buttonMedium }}
                    mb={2}
                  >
                    {title}
                  </Typography>
                </Grid>
                {terms.map((term, termIdx) => {
                  const { name, clauseParagraphs, amendment } = term || {};
                  return (
                    <React.Fragment key={termIdx}>
                      <Grid item key={`${standardTermSectionIdx} - ${termIdx}`}>
                        <Grid item xs={12}>
                          <Grid container direction="row" spacing={1}>
                            <Grid item xs={11.5}>
                              <>
                                <Grid container direction="row">
                                  <Grid item xs={3}>
                                    <Typography
                                      variant="title"
                                      color={theme.palette.grey['750']}
                                    >
                                      {name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={9}>
                                    <ClauseParagraphsContainer
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          generateClauseParagraphs(
                                            clauseParagraphs
                                          ),
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            </Grid>
                            <Grid item xs={0.5}>
                              <IconButton
                                size="large"
                                onClick={() => {
                                  setOpenAmendmentDialogue(true);
                                }}
                                disabled={
                                  amendment !== undefined && amendment !== null
                                }
                                color="secondary"
                              >
                                <Tooltip trigger="hover" title="Add Amendment">
                                  <AsteriskIcon
                                    height="20"
                                    width="20"
                                    fill={
                                      amendment !== undefined &&
                                      amendment !== null
                                        ? 'rgba(0, 0, 0, 0.26)'
                                        : theme.palette.secondary.main
                                    }
                                  />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <AmendmentDialog
                          open={openAmendmentDialogue}
                          labelTwo="OK"
                          value={amendment}
                          onCloseDialog={() => {
                            setOpenAmendmentDialogue(false);
                          }}
                          onClickTwo={() => {
                            updateStandardTermsSections(
                              standardTermSectionIdx,
                              termIdx,
                              '',
                              true
                            );
                          }}
                          onBlurData={(_, val) => {
                            updateStandardTermsSections(
                              standardTermSectionIdx,
                              termIdx,
                              val
                            );
                          }}
                          onResetData={() => {
                            updateStandardTermsSections(
                              standardTermSectionIdx,
                              termIdx,
                              null
                            );
                          }}
                        />
                      </Grid>
                      {standardTermSectionIdx !==
                        contractStandardTermSections.length - 1 ||
                      termIdx !== terms.length - 1 ? (
                        <Grid item py={3} xs={12}>
                          <Divider />
                        </Grid>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </Grid>
            );
          }
        )}
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default (memo(ContractStandardTerms): AbstractComponent<
  PropsType,
  mixed
>);
