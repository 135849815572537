/**
 *
 * ContractTemplateCard
 * @format
 * @flow
 *
 */

import {
  AddCircleOutlined as AddCircleOutlinedIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Favorite as FavoriteIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React, { memo, useState } from 'react';

import type { AbstractComponent } from 'react';
import IconButton from '@mui/material/IconButton';
import type { PropsType } from './types';
// $FlowFixMe
import ReactMarkdown from 'react-markdown';
// $FlowFixMe
import rehypeRaw from 'rehype-raw';
// $FlowFixMe
import remarkGfm from 'remark-gfm';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ContractTemplateCard: AbstractComponent<PropsType> = ({
  id,
  title,
  subheading = '',
  content,
  moreInfo,
  notes,
  onContractSelected,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const onOpenDialog = () => setIsOpenDialog(true);

  const onCloseDialog = () => setIsOpenDialog(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderNotesDialog = () => {
    return (
      <Dialog open={isOpenDialog} onClose={onCloseDialog} maxWidth="lg">
        <DialogTitle>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ReactMarkdown
            children={JSON.parse(`"${notes || ''}"`)}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          subheader={subheading}
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={() => onContractSelected(id)}
            >
              <AddCircleOutlinedIcon color="secondary" fontSize="large" />
            </IconButton>
          }
        />
        <CardContent sx={{ height: 100 }}>
          <Typography variant="body2" color="text.secondary" component="p">
            {content}
          </Typography>
        </CardContent>
        <Divider light />
        <CardActions disableSpacing>
          <IconButton aria-label="add to favourites">
            <FavoriteIcon />
          </IconButton>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container>
              <div className="markdown-body">
                <ReactMarkdown
                  children={JSON.parse(`"${moreInfo || ''}"`)}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
              <IconButton onClick={onOpenDialog}>
                <InfoOutlinedIcon />
              </IconButton>
            </Grid>
          </CardContent>
          <Divider light />
        </Collapse>
      </Card>
      {renderNotesDialog()}
    </>
  );
};

export default (memo(ContractTemplateCard): AbstractComponent<
  PropsType,
  mixed
>);
