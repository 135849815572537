/**
 *
 * ProjectDetails
 * @format
 * @flow
 *
 */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import Description from './Description/Description';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { Node } from 'react';
import ProductionSchedule from './ProductionSchedule/ProductionSchedule';
import Spv from './Spv/Spv';
import type { UpdateProjectGeneralDetailsReturnType } from 'project/graphQL/useUpdateProjectGeneralDetailsMutation';
import type { UpdateProjectSPVsReturnType } from 'project/graphQL/useUpdateProjectSPVsMutation';
import type { UpdateProjectScheduleReturnType } from 'project/graphQL/useUpdateProjectScheduleMutation';
import { useParams } from 'react-router-dom';
import { useProjectDetailsQuery } from 'project/graphQL/useProjectDetailsQuery';
import { useUpdateProjectGeneralDetailsMutation } from 'project/graphQL/useUpdateProjectGeneralDetailsMutation';
import { useUpdateProjectSPVsMutation } from 'project/graphQL/useUpdateProjectSPVsMutation';
import { useUpdateProjectScheduleMutation } from 'project/graphQL/useUpdateProjectScheduleMutation';
import { format } from 'date-fns';

const ProjectDetails = (): Node => {
  const routeParams = useParams();
  const ptid = routeParams.ptid || '';
  const {
    data,
    loading,
    // eslint-disable-next-line no-unused-vars
    error,
  } = useProjectDetailsQuery({
    _id: ptid,
  });
  const {
    updateProjectGeneralDetails,
    data: projectGeneralDetailsData,
    loading: projectGeneralDetailsIsSaving,
  }: UpdateProjectGeneralDetailsReturnType = useUpdateProjectGeneralDetailsMutation();
  const {
    updateProjectSchedule,
    data: projectScheduleData,
    loading: projectScheduleDataIsSaving,
  }: UpdateProjectScheduleReturnType = useUpdateProjectScheduleMutation();
  const {
    updateProjectSPVs,
    data: projectSPVsData,
    loading: projectSPVsIsSaving,
  }: UpdateProjectSPVsReturnType = useUpdateProjectSPVsMutation();

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  const [descriptionCombinedState, setDescriptionCombinedState] = useState({
    title: '',
    genreTypeId: 0,
    genreOtherSpecify: '',
    single: true,
    noOfEpisodes: 1,
    duration: 0,
    initialReleasePlatformTypeId: 0,
    initialReleaseOtherSpecify: '',
    shortDescription: '',
  });

  const defaultScheduleDateVal = format(new Date().getTime(), 'yyyy-MM-dd');
  const defaultScheduleNameVal = '';
  const [scheduleCombinedState, setScheduleCombinedState] = useState({
    preProductionStart: defaultScheduleDateVal,
    preProductionEnd: defaultScheduleDateVal,
    productionStart: defaultScheduleDateVal,
    productionEnd: defaultScheduleDateVal,
    postProductionStart: defaultScheduleDateVal,
    postProductionEnd: defaultScheduleDateVal,
    roughCutDelivery: [
      { name: defaultScheduleNameVal, date: defaultScheduleDateVal },
    ],
    fineCutDelivery: [
      { name: defaultScheduleNameVal, date: defaultScheduleDateVal },
    ],
    finalMaterialsDelivery: defaultScheduleDateVal,
  });

  const [spvsCombinedState, setSPVsCombinedState] = useState({
    companyName: '',
    type: '',
    abn: 0,
    acn: 0,
    address: {
      number: 0,
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
  });

  useEffect(() => {
    if (!!projectGeneralDetailsData) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the project general details!',
      });
      setSnackbarOpen(true);
    }
  }, [projectGeneralDetailsData]);

  useEffect(() => {
    if (!!projectScheduleData) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the project schedule!',
      });
      setSnackbarOpen(true);
    }
  }, [projectScheduleData]);

  useEffect(() => {
    if (!!projectSPVsData) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the project spvs!',
      });
      setSnackbarOpen(true);
    }
  }, [projectSPVsData]);

  const onDescriptionDataChanged = (description) => {
    setDescriptionCombinedState((prevState) => ({
      ...prevState,
      ...description,
    }));
  };

  const handleProductionScheduleDataChanged = (schedule) => {
    setScheduleCombinedState((prevState) => ({
      ...prevState,
      ...schedule,
    }));
  };

  const handleSPVsDataChanged = (spv) => {
    setSPVsCombinedState((prevState) => ({
      ...prevState,
      ...spv,
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateProjectGeneralDetails = () => {
    const {
      title,
      genreTypeId,
      genreOtherSpecify,
      single,
      noOfEpisodes,
      duration,
      initialReleasePlatformTypeId,
      initialReleaseOtherSpecify,
      shortDescription,
    } = descriptionCombinedState;
    updateProjectGeneralDetails({
      variables: {
        project: {
          _id: ptid,
          title,
          genreTypeId: Number(genreTypeId),
          genreOtherSpecify,
          single,
          noOfEpisodes,
          duration,
          initialReleasePlatformTypeId: Number(initialReleasePlatformTypeId),
          initialReleaseOtherSpecify,
          shortDescription,
        },
      },
    });
  };

  const handleUpdateProjectSchedule = () => {
    const {
      preProductionStart,
      preProductionEnd,
      productionStart,
      productionEnd,
      postProductionStart,
      postProductionEnd,
      finalMaterialsDelivery,
      roughCutDelivery,
      fineCutDelivery,
    } = scheduleCombinedState;
    updateProjectSchedule({
      variables: {
        schedule: {
          _id: ptid,
          stageTypeId: 0,
          preProductionStart,
          preProductionEnd,
          productionStart,
          productionEnd,
          postProductionStart,
          postProductionEnd,
          finalMaterialsDelivery,
          roughCutDelivery: roughCutDelivery?.map(
            ({ __typename, ...rest }) => rest
          ),
          fineCutDelivery: fineCutDelivery?.map(
            ({ __typename, ...rest }) => rest
          ),
        },
      },
    });
  };

  const handleUpdateProjectSPVs = () => {
    const { companyName, type, abn, acn, address } = spvsCombinedState;
    const {
      number = 0,
      street = '',
      suburb = '',
      state = '',
      postcode = '',
      country = '',
    } = address || {};
    updateProjectSPVs({
      variables: {
        data: {
          _id: ptid,
          spvDetails: [
            {
              companyName,
              type,
              abn: Number(abn),
              acn: Number(acn),
              address: {
                number,
                street,
                suburb,
                state,
                postcode,
                country,
              },
            },
          ],
        },
      },
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!data?.project) return <p>Not found</p>;

  const { project: projectData } = data;
  const {
    genreValues,
    releasePlatformValues,
    schedule,
    companySPVs = [spvsCombinedState],
  } = projectData ?? {};

  return (
    <Grid container>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Description
            projectData={projectData}
            configTypes={{ genreValues, releasePlatformValues }}
            isSaving={projectGeneralDetailsIsSaving}
            onUpdateData={onDescriptionDataChanged}
            onUpdateProjectGeneralDetails={handleUpdateProjectGeneralDetails}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Production Schedule</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProductionSchedule
            scheduleDetails={schedule}
            isSaving={projectScheduleDataIsSaving}
            onUpdateData={handleProductionScheduleDataChanged}
            onUpdateProjectSchedule={handleUpdateProjectSchedule}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Project (SPV) Company</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Spv
            // $FlowFixMe
            spvs={companySPVs}
            isSaving={projectSPVsIsSaving}
            onUpdateData={handleSPVsDataChanged}
            onUpdateProjectSPVs={handleUpdateProjectSPVs}
          />
        </AccordionDetails>
      </Accordion>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ProjectDetails;
