import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import { ApolloConsumer } from '@apollo/client';
import { Link } from 'react-router-dom';
import useAccountActions from 'app/services/auth/useAccountActions';
import { useStyles } from './styles.js';

const IconWithMenu = ({ iconAndMenu }) => {
  const classes = useStyles();
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const { logout } = useAccountActions();

  return (
    <ApolloConsumer>
      {(client) => (
        <Grid
          container
          className={classes.root}
          justifyContent="flex-end"
          direction="row"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              aria-controls="simple-menu-help"
              aria-haspopup="true"
              onClick={(e) => setMenuAnchorElement(e.currentTarget)}
              color="inherit"
              size="large"
            >
              {iconAndMenu.icon}
            </IconButton>
            <Menu
              id="simple-menu-help"
              anchorEl={menuAnchorElement}
              keepMounted
              open={Boolean(menuAnchorElement)}
              onClose={() => setMenuAnchorElement(null)}
            >
              {iconAndMenu.menuData.map((menuItem, i) => {
                switch (menuItem.type) {
                  case 'menu_item':
                    return (
                      <Link to={menuItem.url} key={i}>
                        <MenuItem
                          onClick={() => setMenuAnchorElement(null)}
                          key={i}
                        >
                          {menuItem.text}
                        </MenuItem>
                      </Link>
                    );
                  case 'functional_menu_item':
                    return (
                      <MenuItem onClick={() => menuItem.onClick()} key={i}>
                        {menuItem.text}
                      </MenuItem>
                    );
                  case 'logout_menu_item':
                    return (
                      <MenuItem
                        onClick={async () => {
                          await logout(client);
                        }}
                        key={i}
                      >
                        {menuItem.text}
                      </MenuItem>
                    );
                  case 'divider':
                    return <Divider key={i} />;
                  default:
                    return () => {
                      console.log('default');
                    };
                }
              })}
            </Menu>
          </Grid>
        </Grid>
      )}
    </ApolloConsumer>
  );
};

export default IconWithMenu;
