/**
 *
 * TextAreaSuggestions
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Box, Grid } from '@mui/material';
import React, { memo, useRef } from 'react';

import IconButton from 'app/components/core/IconButton/IconButton';
import { Macros } from 'app/assets/icons';
import type { PropsType } from './types';
import SelectDescription from 'app/containers/SelectDescription/SelectDescription';
import Textarea from 'app/components/core/Textarea/Textarea';
import { useTheme } from '@mui/material/styles';

const TextAreaSuggestions = ({
  id,
  label,
  value = '',
  suggestions,
  onSubmitDescription,
  onChangeTextarea,
}: PropsType): Node => {
  const dialogRef = useRef(null);
  const theme = useTheme();

  const handleSubmitDescription = (data) => {
    const text = data.join('\n');
    onSubmitDescription(id, text);
  };

  return (
    <>
      <SelectDescription
        dialogRef={dialogRef}
        value={value}
        options={suggestions}
        onUpdate={handleSubmitDescription}
      />
      <Grid container direction="row" sx={{ position: 'relative' }}>
        <Grid item xs={12}>
          <Textarea
            id={id}
            label={label}
            value={value}
            multiline
            maxRows={5}
            minRows={2}
            onBlur={(id, val) => {
              onChangeTextarea(id, val);
            }}
            controlPanel={
              <Box
                sx={{
                  borderRadius: '6px',
                  border: `1px solid ${theme.palette.grey['400']}`,
                  background: theme.palette.background.default,
                }}
              >
                <IconButton
                  onClick={() => {
                    // $FlowFixMe
                    dialogRef?.current?.open(true);
                  }}
                  size="small"
                >
                  <Macros />
                </IconButton>
              </Box>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default (memo(TextAreaSuggestions): AbstractComponent<PropsType, mixed>);
