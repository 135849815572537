import React from 'react';
import { Typography } from '@mui/material';

const SubTitle = ({ children }) => {
  return (
    <Typography variant="h5" gutterBottom>
      {children}
    </Typography>
  );
};

export default SubTitle;
