/**
 *
 * CrewRate
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext } from 'react';

import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import RadioButton from 'app/components/RadioButton/RadioButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const CrewRate: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const {
    pay = '',
    payType = 'flatFee',
    flatFeePerPeriod = '',
    ratePerPeriod = '',
    baseHourlyRate = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      pay: '',
      payType: 'flatFee',
      flatFeePerPeriod: '',
      ratePerPeriod: '',
      baseHourlyRate: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="pay"
            label="Pay"
            value={pay}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onBlur={onUpdateData}
          />
        </Grid>

        <Grid item xs={12}>
          <RadioButtonGroup
            id="payType"
            value={payType}
            onValueChange={onUpdateData}
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <RadioButton id="flatFee" value="flatFee" label="Flat Fee" />
              </Grid>
              <Grid item xs={4}>
                <Select
                  id="flatFeePerPeriod"
                  value={flatFeePerPeriod}
                  onSelectOption={(item) => {
                    if (!Array.isArray(item)) {
                      onUpdateData('flatFeePerPeriod', item.value);
                    }
                  }}
                  options={[
                    { value: 'per Week', label: 'per Week' },
                    { value: 'per Day', label: 'per Day' },
                  ]}
                  disabled={payType === 'rate'}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-end"
              direction="row"
              spacing={2}
            >
              <Grid item xs={2} alignSelf="center">
                <RadioButton id="rate" value="rate" label="Rate" />
              </Grid>
              <Grid item xs={4}>
                <Select
                  id="ratePerPeriod"
                  value={ratePerPeriod}
                  onSelectOption={(item) => {
                    !Array.isArray(item) &&
                      onUpdateData('ratePerPeriod', item.value);
                  }}
                  options={[
                    {
                      value: 'per Week (50 hour week)',
                      label: 'per Week (50 hour week)',
                    },
                    {
                      value: 'per Week (40 hour week)',
                      label: 'per Week (40 hour week)',
                    },
                    { value: 'per Day', label: 'per Day' },
                    { value: 'per Hour', label: 'per Hour' },
                  ]}
                  disabled={payType === 'flatFee'}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="baseHourlyRate"
                  label="Base hourly rate"
                  value={baseHourlyRate}
                  formatMoney
                  numberOnly
                  startAdornment={<>$</>}
                  onBlur={onUpdateData}
                  disabled={payType === 'flatFee'}
                />
              </Grid>
            </Grid>
          </RadioButtonGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default (memo(CrewRate): AbstractComponent<PropsType, mixed>);
