/**
 *
 * useSaveContractAndCreateWordDocMutation
 * @format
 * @flow
 *
 */

import type {
  SaveContractAndCreateWordDocMutation,
  SaveContractAndCreateWordDocMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import contractMetaFragment from 'contract/graphQL/fragments/contractMetaFragment';
import contractTermsFragment from 'contract/graphQL/fragments/contractTerms';
import { DOCUMENTS } from 'project/graphQL/useDocumentsQuery';
import { documentDetailsFragment } from 'project/graphQL/fragments/documentFragments';

const SAVE_CONTRACT_AND_CREATE_WORD_DOC = gql`
  mutation SaveContractAndCreateWordDoc(
    $contract: UpdateContractDetailsInput!
    $deleteMissingObjectAttributes: Boolean = true
    $contractId: ID!
    $tags: [String!]!
  ) {
    createWordDocument(contractId: $contractId, tags: $tags) {
      code
      success
      message
      downloadUrl
      document {
        _id
        ...documentDetailsFragment
      }
    }
    updateContract(
      contract: $contract
      deleteMissingObjectAttributes: $deleteMissingObjectAttributes
    ) {
      code
      success
      message
      contract {
        ...contractMeta
        ...contractTerms
      }
    }
  }
  ${documentDetailsFragment}
  ${contractMetaFragment}
  ${contractTermsFragment}
`;

export type SaveContractAndCreateWordDocReturnType = {
  saveAndCreateWordDoc: ({
    variables: SaveContractAndCreateWordDocMutationVariables,
  }) => void,
  loading: boolean,
  data: SaveContractAndCreateWordDocMutation,
  error: any,
};

export const useSaveContractAndCreateWordDocMutation =
  (): SaveContractAndCreateWordDocReturnType => {
    const [saveAndCreateWordDoc, { data, loading, error }] = useMutation(
      SAVE_CONTRACT_AND_CREATE_WORD_DOC,
      {
        onError: (err) => {
          console.error(err);
        },
        update: (
          cache,
          {
            data: {
              createWordDocument: { document },
            },
          }
        ) => {
          // Update documents in Upload > Other
          const newDoc = document;
          const existingDocs = cache.readQuery({
            query: DOCUMENTS,
          });

          if (newDoc) {
            cache.writeQuery({
              query: DOCUMENTS,
              data: {
                documents: [
                  ...(existingDocs ? existingDocs?.documents : []),
                  newDoc,
                ],
              },
            });
          }
        },
      }
    );

    return {
      saveAndCreateWordDoc,
      loading,
      data,
      error,
    };
  };
