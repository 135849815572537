/**
 *
 * ProjectView
 * @format
 * @flow
 *
 */

import { Grid, Typography } from '@mui/material';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import type { Node } from 'react';
import ProjectContributorContracts from './ProjectContributorContracts/ProjectContributorContracts';
import ProjectDetails from './ProjectDetails/ProjectDetails';
import ProjectExternalDocs from './ProjectExternalDocs/ProjectExternalDocs';
import Tabs from 'app/components/Tabs/Tabs';
// $FlowFixMe
import queryString from 'query-string';
import { useProjectDetailsQuery } from 'project/graphQL/useProjectDetailsQuery';

const ProjectView = (): Node => {
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState('0');
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const ptid = routeParams.ptid || '';
  const {
    data,
    loading,
    // eslint-disable-next-line no-unused-vars
    error,
  } = useProjectDetailsQuery({
    _id: ptid,
  });

  useEffect(() => {
    const searchValues = queryString.parse(location?.search || {});

    setTabValue(searchValues?.tab || '0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!data?.project) return <p>Not found</p>;

  const { project: projectData } = data;

  return (
    <Grid container>
      <Grid
        container
        direction="column"
        sx={{ paddingBottom: '40px' }}
        spacing={2}
      >
        <Grid item>
          <Typography variant="title">
            <Link to={`/dashboard/${cyid}/projects`}>Back to Projects</Link>
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="h4">{projectData?.title || ''}</Typography>
        </Grid>
      </Grid>

      <Tabs
        value={tabValue}
        onChange={(tabVal) => {
          const params = new URLSearchParams({ tab: tabVal.toString() });
          history.replace({
            pathname: location.pathname,
            search: params.toString(),
          });
        }}
        tabs={[
          {
            label: 'Contributor Contracts',
            component: <ProjectContributorContracts />,
          },
          {
            label: 'Details',
            component: <ProjectDetails />,
          },
          {
            label: 'Uploads',
            component: <ProjectExternalDocs />,
          },
        ]}
      />
    </Grid>
  );
};

export default ProjectView;
