/**
 *
 * CollapsableSwitchMenu
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type {
  PropsType,
  TermComponentDataType,
  TermComponentPropsType,
} from './types';
import React, { memo, useContext, useRef, useState } from 'react';

import AdditionalDetailDialog from 'app/components/AdditionalDetailDialog/AdditionalDetailDialog';
import CollapsableSection from 'app/components/CollapsableSection/CollapsableSection';
import { ContractContext } from 'app/contexts/Contract/Contract';
import ContractTermMenu from 'app/components/ContractTermMenu/ContractTermMenu';
import { Grid } from '@mui/material';
import isEqual from 'lodash/isEqual';

const CollapsableSwitchMenu = (props: PropsType): Node => {
  const { contractTermKeyName, children } = props;

  const {
    contractTermData,
    contractTermProps,
    contractTermDefaultData,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const { switchVal = true, additionalDetail = '' }: TermComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const {
    switchTitle = '',
    switchDisplay = true,
    info,
  }: TermComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const [showAdditionalDetailInputBox, setShowAdditionalDetailInputBox] =
    useState(!!additionalDetail);
  const dialogRef = useRef(null);

  const checkDataIsEmpty = () => {
    const { switchVal, ...rest } = contractTermData[contractTermKeyName] || {};
    const { switchVal: sv, ...defaultsRest } =
      contractTermDefaultData[contractTermKeyName] || {};
    return isEqual(rest, defaultsRest);
  };

  const showDialogueOnClose = () => {
    const overWriteDialogDisplay = checkDataIsEmpty();
    return overWriteDialogDisplay !== true || additionalDetail !== '';
  };

  const toggleSwitch = () => {
    onUpdate(contractTermKeyName, { additionalDetail, switchVal: !switchVal });
  };

  const resetData = () => {
    setShowAdditionalDetailInputBox(false);
    onUpdate(contractTermKeyName, {
      ...contractTermDefaultData[contractTermKeyName],
      switchVal: false,
    });
  };

  const includeAdditionalDetail = () => {
    setShowAdditionalDetailInputBox(true);
    dialogRef?.current?.open(false);
  };

  const resetAdditionalDetail = () => {
    setShowAdditionalDetailInputBox(false);
    onUpdate(contractTermKeyName, { additionalDetail: '', switchVal });
  };

  return (
    <Grid
      container
      direction="row-reverse"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <ContractTermMenu
          disableInfo={!switchVal}
          disableAdditionalDetail={showAdditionalDetailInputBox || !switchVal}
          infoTooltipHtmlBody={info}
          onClickAdditionalDetail={() => {
            dialogRef?.current?.open(true);
          }}
        />
      </Grid>
      <CollapsableSection
        labelSwitch={switchTitle}
        checkSwitch={switchVal}
        onResetData={resetData}
        onChangeSwitch={toggleSwitch}
        onShowDialogueOnClose={showDialogueOnClose}
        display={switchDisplay ? '' : 'none'}
      >
        <Grid item>{children}</Grid>
        <AdditionalDetailDialog
          dialogRef={dialogRef}
          value={additionalDetail}
          showInputField={showAdditionalDetailInputBox}
          onClickTwo={includeAdditionalDetail}
          onBlurData={(id, val) => onUpdate(contractTermKeyName, { [id]: val })}
          onResetData={resetAdditionalDetail}
        />
      </CollapsableSection>
    </Grid>
  );
};

export default (memo(CollapsableSwitchMenu): AbstractComponent<
  PropsType,
  mixed
>);
