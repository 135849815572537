/**
 *
 * ContractTemplatesView
 * @format
 * @flow
 *
 */

import { Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import React, { useCallback, useState } from 'react';

import type { AbstractComponent } from 'react';
import ContractTemplateCard from 'contract/ContractTemplatesView/ContractTemplateCard/ContractTemplateCard';
import type { ContractTemplatesReturnType } from 'contract/graphQL/useContractTemplatesQuery';
import type { CreateContractFromTemplateReturnType } from 'contract/graphQL/useCreateContractFromTemplateMutation';
import type { PropsType } from './types';
import SubTitle from 'app/components/SubTitle/SubTitle';
import Title from 'app/components/Title/Title';
import { useContractTemplatesQuery } from 'contract/graphQL/useContractTemplatesQuery';
import { useCreateContractFromTemplateMutation } from 'contract/graphQL/useCreateContractFromTemplateMutation';
import SelectProjectModal from 'project/SelectProjectModal/SelectProjectModal';

const ContractTemplatesView: AbstractComponent<PropsType> = () => {
  const history = useHistory();
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const { loading, error, data }: ContractTemplatesReturnType =
    useContractTemplatesQuery();
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const {
    createContractFromTemplate,
    // eslint-disable-next-line no-unused-vars
    loading: createContractFromTemplateLoading,
    // eslint-disable-next-line no-unused-vars
    error: createContractFromTemplateError,
  }: CreateContractFromTemplateReturnType = useCreateContractFromTemplateMutation();

  const [isSelectProjectModalOpen, setIsSelectProjectModalOpen] =
    useState(false);

  const onContractSelected = useCallback(
    //TODO: need to also send the project id
    (templateId: string) => {
      setIsSelectProjectModalOpen(true);
      setSelectedTemplateId(templateId);
    },
    []
  );

  const onSelectProject = (projectId: string) => {
    createContractFromTemplate({
      variables: { templateId: selectedTemplateId, projectId },
    }).then(({ data }) => {
      const ctid =
        // $FlowFixMe
        data?.createContractFromTemplate?.contract?._id || '';
      history.push(`/dashboard/${cyid}/project/${projectId}/contract/${ctid}`);
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  const { contractTemplates } = data;

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Title>Contract Templates</Title>
        <SubTitle>Select a contract template</SubTitle>
      </Grid>
      <Grid item container spacing={2}>
        {contractTemplates.map((contractTemplate, i) => {
          return (
            <Grid item xs={12} md={6} key={contractTemplate._id}>
              <ContractTemplateCard
                id={contractTemplate._id}
                title={contractTemplate.title}
                content={contractTemplate?.info?.shortDescription}
                moreInfo={contractTemplate?.info?.fullDescription}
                notes={contractTemplate?.info?.notes || ''}
                onContractSelected={onContractSelected}
                {...(contractTemplate?.info?.notes
                  ? { notes: contractTemplate?.info?.notes }
                  : {})}
              />
            </Grid>
          );
        })}
      </Grid>
      <SelectProjectModal
        open={isSelectProjectModalOpen}
        onClose={setIsSelectProjectModalOpen}
        onSelectProject={onSelectProject}
        createContractFromTemplateLoading={createContractFromTemplateLoading}
      />
    </Grid>
  );
};

export default ContractTemplatesView;
