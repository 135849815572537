/**
 *
 * useUpdateProjectSPVsMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateProjectSpVsMutation,
  UpdateProjectSpVsMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { createProjectDetailsFragment } from 'project/graphQL/fragments/projectFragments';

const UPDATE_PROJECT_SPV = gql`
  mutation UpdateProjectSPVs($data: UpdateProjectSPVCompanyInput!) {
    updateProjectSPVs(data: $data) {
      project {
        ...createProjectDetails
      }
    }
  }
  ${createProjectDetailsFragment}
`;

export type UpdateProjectSPVsReturnType = {
  updateProjectSPVs: ({
    variables: UpdateProjectSpVsMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateProjectSpVsMutation,
  error: any,
};

export const useUpdateProjectSPVsMutation = (): UpdateProjectSPVsReturnType => {
  const [updateProjectSPVs, { data, loading, error }] =
    useMutation(UPDATE_PROJECT_SPV);

  return {
    updateProjectSPVs,
    loading,
    data,
    error,
  };
};
