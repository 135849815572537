const externalDocsDefaultData = {
  Financial: {
    'Production Budget': {
      files: [],
    },
    Drawdown: {
      files: [],
    },
    Cashflow: {
      files: [],
    },
    'Provisional Certificate': {
      files: [],
    },
    'QAPE Opinion': {
      files: [],
    },
  },
  Creative: {
    Treatment: {
      files: [],
    },
    Script: {
      files: [],
    },
  },
  SPV: {
    'Certificate of Incorporation, ASIC docs': {
      files: [],
    },
    Insurance: {
      files: [],
    },
  },
  'Prod Co': {
    'Certificate of Incorporation, ASIC docs': {
      files: [],
    },
    Insurance: {
      files: [],
    },
  },
  Legal: {
    'Chain of Title Opinion': {
      files: [],
    },
    'Uploaded (non bam) contracts': {
      files: [],
    },
  },
  Other: {},
};

export default externalDocsDefaultData;
