/**
 *
 * ScheduleItem
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo } from 'react';

import DatePicker from 'app/components/DatePicker/DatePicker';
import type { PropsType } from './types';

const ScheduleItem: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    label,
    startDateId,
    startDateVal,
    endDateId,
    endDateVal,
    handleUpdateData,
    containerStyle,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={containerStyle}
    >
      <Grid item xs={6}>
        <Typography variant="body1">{label}</Typography>
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          id={startDateId}
          label=""
          value={startDateVal}
          disableMaskedInput
          hidePlaceHolder
          onValueChange={handleUpdateData}
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          id={endDateId}
          label=""
          value={endDateVal}
          disableMaskedInput
          hidePlaceHolder
          onValueChange={handleUpdateData}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(ScheduleItem): AbstractComponent<PropsType, mixed>);
