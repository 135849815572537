/**
 *
 * ProductionSchedule
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import type { PropsType, ScheduleInfoType } from './types';
import React, { memo, useEffect, useState } from 'react';

import CutDeliveryItem from './CutDeliveryItem/CutDeliveryItem';
import type { CutDeliveryItemType } from 'app/commonFlowTypes';
import DatePicker from 'app/components/DatePicker/DatePicker';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import ScheduleItem from './ScheduleItem/ScheduleItem';
import { format } from 'date-fns';

const ProductionSchedule: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const {
    scheduleDetails,
    showSaveButton = true,
    isSaving = false,
    onUpdateData,
    onUpdateProjectSchedule = () => {},
  } = props;
  const defaultDateVal = format(new Date().getTime(), 'yyyy-MM-dd');
  const defaultNameVal = '';
  const [data, setData] = useState<ScheduleInfoType>({
    preProductionStart: defaultDateVal,
    preProductionEnd: defaultDateVal,
    productionStart: defaultDateVal,
    productionEnd: defaultDateVal,
    postProductionStart: defaultDateVal,
    postProductionEnd: defaultDateVal,
    roughCutDelivery: [{ name: defaultNameVal, date: defaultDateVal }],
    fineCutDelivery: [{ name: defaultNameVal, date: defaultDateVal }],
    finalMaterialsDelivery: defaultDateVal,
  });

  useEffect(() => {
    const isScheduleDetailsEmpty = Object.keys(scheduleDetails)
      .filter((key) => key !== '__typename')
      .every(
        (key) =>
          scheduleDetails[key] === null ||
          (Array.isArray(scheduleDetails[key]) &&
            scheduleDetails[key].length === 0)
      );

    // for newly created project, scheduleDetails is empty
    if (!isScheduleDetailsEmpty) {
      setData({
        ...scheduleDetails,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDetails]);

  const {
    preProductionStart,
    preProductionEnd,
    productionStart,
    productionEnd,
    postProductionStart,
    postProductionEnd,
    roughCutDelivery,
    fineCutDelivery,
    finalMaterialsDelivery,
  } = data;

  const handleUpdateDateData = (fieldName: string, fieldValue: number) => {
    setData((prevState) => {
      const newData = { ...prevState, [fieldName]: fieldValue };
      onUpdateData(newData);
      return newData;
    });
  };

  const onSetDataCutDeliveryItems = (
    fieldName: string,
    newItems: Array<?CutDeliveryItemType>
  ) => {
    setData((prevState) => {
      const newData = { ...prevState, [fieldName]: newItems };
      onUpdateData(newData);
      return newData;
    });
  };

  const handleEditCutDeliveryItems = (
    fieldName: 'roughCutDelivery' | 'fineCutDelivery',
    property: 'date' | 'name',
    value: number | string,
    index: number
  ) => {
    const items =
      fieldName === 'roughCutDelivery' ? roughCutDelivery : fineCutDelivery;
    // we have to do it the verbose way as the shorthand version runs into a
    // strict read-only error
    const newItems = items.map((x) => ({
      name: x?.name || defaultNameVal,
      date: x?.date || defaultDateVal,
    }));
    let newDeliveryItem = {};
    if (property === 'date') {
      newDeliveryItem = {
        name: defaultNameVal,
        ...newItems[index],
        date: typeof value === 'string' ? value : defaultDateVal,
      };
    } else {
      newDeliveryItem = {
        date: defaultDateVal,
        ...newItems[index],
        name: typeof value === 'string' ? value : defaultNameVal,
      };
    }
    newItems[index] = newDeliveryItem;
    onSetDataCutDeliveryItems(fieldName, newItems);
  };

  const handleAddCutDeliveryItems = (
    fieldName: 'roughCutDelivery' | 'fineCutDelivery'
  ) => {
    const newItems = [
      ...(fieldName === 'roughCutDelivery'
        ? roughCutDelivery
        : fineCutDelivery),
      { name: '', date: defaultDateVal },
    ];
    onSetDataCutDeliveryItems(fieldName, newItems);
  };

  const handleDeleteCutDeliveryItems = (
    fieldName: 'roughCutDelivery' | 'fineCutDelivery',
    index: number
  ) => {
    const items =
      fieldName === 'roughCutDelivery' ? roughCutDelivery : fineCutDelivery;
    const newItems = items.filter((_, i) => i !== index);
    onSetDataCutDeliveryItems(fieldName, newItems);
  };

  return (
    <Grid container justifyContent="space-between" spacing={2}>
      <Grid container item xs={12} direction="column">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Typography variant="body1">Start</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1">End</Typography>
          </Grid>
        </Grid>
        <ScheduleItem
          label="Pre-Production"
          startDateId="preProductionStart"
          startDateVal={preProductionStart}
          endDateId="preProductionEnd"
          endDateVal={preProductionEnd}
          handleUpdateData={handleUpdateDateData}
        />
        <ScheduleItem
          label="Production"
          startDateId="productionStart"
          startDateVal={productionStart}
          endDateId="productionEnd"
          endDateVal={productionEnd}
          handleUpdateData={handleUpdateDateData}
        />
        <ScheduleItem
          label="Post Production"
          startDateId="postProductionStart"
          startDateVal={postProductionStart}
          endDateId="postProductionEnd"
          endDateVal={postProductionEnd}
          handleUpdateData={handleUpdateDateData}
          containerStyle={{ marginTop: 1, marginBottom: 2 }}
        />
        <CutDeliveryItem
          label="Rough cut delivery"
          items={roughCutDelivery}
          fieldName="roughCutDelivery"
          handleEditCutDeliveryItems={handleEditCutDeliveryItems}
          handleDeleteCutDeliveryItems={handleDeleteCutDeliveryItems}
          handleAddCutDeliveryItems={handleAddCutDeliveryItems}
        />
        <CutDeliveryItem
          label="Fine cut delivery"
          items={fineCutDelivery}
          fieldName="fineCutDelivery"
          handleEditCutDeliveryItems={handleEditCutDeliveryItems}
          handleDeleteCutDeliveryItems={handleDeleteCutDeliveryItems}
          handleAddCutDeliveryItems={handleAddCutDeliveryItems}
        />
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={9}>
            <Typography variant="body1">Final Materials Delivery</Typography>
          </Grid>
          <Grid item xs={3}>
            <DatePicker
              id="finalMaterialsDelivery"
              label=""
              value={finalMaterialsDelivery}
              disableMaskedInput
              hidePlaceHolder
              onValueChange={handleUpdateDateData}
            />
          </Grid>
        </Grid>
      </Grid>
      {showSaveButton && (
        <Grid item xs>
          <LoadingButton
            isLoading={isSaving}
            loadingText="Saving..."
            defaultText="Save"
            onClick={onUpdateProjectSchedule}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default (memo(ProductionSchedule): AbstractComponent<PropsType, mixed>);
