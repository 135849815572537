/**
 *
 * Tabs
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Box, Tab } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import type { PropsType } from './types';

const Tabs: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    value,
    centered,
    variant,
    scrollButtons,
    orientation,
    tabs,
    onChange,
  } = props;
  const [tabValue, setTabValue] = useState(value);

  useEffect(() => {
    if (value) {
      setTabValue(value);
    }
  }, [value]);

  const handleChange = (event: any, newValue: string | number) => {
    setTabValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            centered={centered}
            variant={variant}
            scrollButtons={scrollButtons}
            orientation={orientation}
          >
            {tabs.map(
              ({ label, wrapped, disabled, icon, iconPosition }, index) => (
                <Tab
                  label={label}
                  value={index.toString()}
                  wrapped={wrapped}
                  disabled={disabled}
                  icon={icon}
                  iconPosition={iconPosition}
                  key={index.toString()}
                />
              )
            )}
          </TabList>
        </Box>
        {tabs.map(({ component }, index) => (
          <TabPanel
            sx={{ padding: '40px 0px' }}
            value={index.toString()}
            key={index.toString()}
          >
            {component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default (memo(Tabs): AbstractComponent<PropsType, mixed>);
