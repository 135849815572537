/**
 *
 * TextGeneratingRadio
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useCallback, useContext } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import TextGeneratingRadioButtonGroup from 'app/components/TextGeneratingRadioButtonGroup/TextGeneratingRadioButtonGroup';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const TextGeneratingRadio = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    radioValue = '',
    textValue1 = '',
    textValue2 = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const {
    radioLabel1 = '',
    radioValue1 = '',
    textGenerating1 = '',
    radioLabel2 = '',
    radioValue2 = '',
    textGenerating2 = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      radioValue: '',
      textValue1: '',
      textValue2: '',
    },
  });

  const onUpdateData = useCallback((data) => {
    onUpdate(contractTermKeyName, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextGeneratingRadioButtonGroup
          radioValue={radioValue}
          textValue1={textValue1}
          textValue2={textValue2}
          radioLabel1={radioLabel1}
          radioValue1={radioValue1}
          textGenerating1={textGenerating1}
          radioLabel2={radioLabel2}
          radioValue2={radioValue2}
          textGenerating2={textGenerating2}
          onUpdate={onUpdateData}
        />
      </Grid>
    </Grid>
  );
};

export default (memo(TextGeneratingRadio): AbstractComponent<PropsType, mixed>);
