/**
 *
 * CutDeliveryItem
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import {
  ControlPoint as ControlPointIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import React, { memo } from 'react';

import DatePicker from 'app/components/DatePicker/DatePicker';
import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';

const CutDeliveryItem: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const {
    label,
    items,
    fieldName,
    handleEditCutDeliveryItems,
    handleDeleteCutDeliveryItems,
    handleAddCutDeliveryItems,
  } = props;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {items.length > 0 &&
          items.map((item, i) => (
            <React.Fragment key={i}>
              <Grid item xs={2}>
                {i === 0 && <Typography variant="body1">{label}</Typography>}
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="name"
                  value={item && item.name}
                  placeholder="add details"
                  onBlur={(id, val) => {
                    handleEditCutDeliveryItems(fieldName, 'name', val, i);
                  }}
                />
              </Grid>
              {i >= 1 ? (
                <Grid item xs={4} sx={{ alignSelf: 'center' }}>
                  <Button
                    aria-label="delete"
                    component="span"
                    onClick={() => {
                      handleDeleteCutDeliveryItems(fieldName, i);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={4}></Grid>
              )}
              <Grid item xs={3}>
                <DatePicker
                  id="date"
                  label=""
                  value={(item && item.date) || new Date().getTime()}
                  disableMaskedInput
                  hidePlaceHolder
                  onValueChange={(id, val) => {
                    handleEditCutDeliveryItems(fieldName, 'date', val, i);
                  }}
                />
              </Grid>
            </React.Fragment>
          ))}
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ marginBottom: 1 }}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => {
              handleAddCutDeliveryItems(fieldName);
            }}
            sx={{ alignSelf: 'flex-start' }}
          >
            <ControlPointIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default (memo(CutDeliveryItem): AbstractComponent<PropsType, mixed>);
