/**
 *
 * AccountSetupDialog
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useState } from 'react';

import AccountSetupStepOne from './AccountSetupStepOne/AccountSetupStepOne';
import AccountSetupStepTwo from './AccountSetupStepTwo/AccountSetupStepTwo';
import Dialog from 'app/components/core/Dialog/Dialog';
import { EntityTypeValues } from 'app/services/graphQL/generated-types';
import type { PropsType } from './types';
import { Typography } from '@mui/material';
import { useCreateCompanyMutation } from 'company/graphQL/useCreateCompanyMutation';
import { useHistory } from 'react-router-dom';
import { useUpdateUserMutation } from 'user/graphQL/useUpdateUserMutation';
import { useUserQuery } from 'user/graphQL/useUserQuery';

const AccountSetupDialog: AbstractComponent<PropsType> = memo(
  (props: PropsType): Node => {
    const { dialogRef, userId, userGivenName, userFamilyName } = props;
    const history = useHistory();
    const [currentStep, setCurrentStep] = useState(
      userGivenName || userFamilyName ? 2 : 1
    );
    const [combinedState, setCombinedState] = useState({
      givenName: '',
      familyName: '',
      companyName: '',
      companyType: EntityTypeValues.Company,
    });
    const { givenName, familyName, companyName, companyType } = combinedState;

    const { updateUser } = useUpdateUserMutation();
    const { createCompany } = useCreateCompanyMutation();
    const { refetch: refetchUser } = useUserQuery();

    const resetCombinedState = () => {
      setCombinedState(() => ({
        givenName: '',
        familyName: '',
        companyName: '',
        companyType: EntityTypeValues.Company,
      }));
    };

    const handleStateChange = (fieldName: string, fieldValue: any) => {
      // $FlowFixMe
      setCombinedState((prevState) => ({
        ...prevState,
        [fieldName]: fieldValue,
      }));
    };

    return (
      <Dialog
        // $FlowFixMe
        ref={dialogRef}
        title="Add a few more details to make using Bam easy"
        labelTwo="OK"
        showButtonOne={false}
        onClickTwo={() => {
          if (currentStep === 1) {
            updateUser({
              variables: {
                user: {
                  _id: userId,
                  givenName,
                  familyName,
                },
              },
            })?.then(() => {
              refetchUser();
            });
            setCurrentStep(2);
          } else if (currentStep === 2) {
            createCompany({
              variables: {
                company: {
                  name: companyName,
                  type: companyType,
                  email: '',
                  contractingParties: [
                    {
                      name: companyName,
                      type: companyType,
                      email: '',
                    },
                  ],
                  periodicPaymentTerms: 'Weekly',
                  periodicTerm: 'In arrears',
                  invoicePaymentTerms: '14 days',
                  accountingPeriod:
                    'Every 6 months for the first year, then once a year',
                },
              },
            })?.then((res) => {
              refetchUser();
              dialogRef?.current?.open(false);
              history.push(
                `/dashboard/${res.data.createCompany.company._id}/projects`
              );
              resetCombinedState();
            });
          }
        }}
      >
        <Typography variant="body" sx={{ marginBottom: '10px' }}>
          {currentStep}/2
        </Typography>
        {currentStep === 1 ? (
          <AccountSetupStepOne
            givenName={givenName}
            familyName={familyName}
            handleStateChange={handleStateChange}
          />
        ) : (
          <AccountSetupStepTwo
            companyName={companyName}
            companyType={companyType}
            handleStateChange={handleStateChange}
          />
        )}
      </Dialog>
    );
  }
);
export default AccountSetupDialog;
