// @flow
import { Configuration, FrontendApi, Identity, Session } from '@ory/client';
import { useEffect, useState } from 'react';

import { oryUrl } from 'app/utils/config';

const ory = new FrontendApi(
  new Configuration({
    basePath: oryUrl,
    baseOptions: {
      withCredentials: true,
    },
  })
);

const getUserName = (identity: Identity) =>
  identity?.traits?.email || identity?.traits?.username;

const getSession = async (): Session => ory.toSession();

const useIsAuthenticated = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [sessionExpiryTime, setSessionExpiryTime] = useState(0);

  const now = new Date().getTime();
  const sessionExpired = now >= sessionExpiryTime;
  console.log(
    `sessionExpired: ${
      sessionExpired ? 'true' : 'false'
    }, now: ${now}, sessionExpiryTime: ${sessionExpiryTime}`
  );
  useEffect(() => {
    console.log(`checking if is authenticated`);
    setCheckingAuth(true);
    getSession()
      .then(({ data }) => {
        console.log({ data });
        const { active, expires_at } = data || {};
        const expTime = active ? new Date(expires_at).getTime() : 0;
        const hasExpired = expTime ? new Date().getTime() >= expTime : true;
        console.log({ active, expTime, hasExpired });
        setCheckingAuth(false);
        setSessionExpiryTime(expTime);
        setAuthenticated(active && !hasExpired);
      })
      .catch((err) => {
        console.log(`sessionExpired - logging out`);
        setCheckingAuth(false);
        setSessionExpiryTime(0);
        setAuthenticated(false);
      });
  }, [sessionExpired]);

  return { authenticated, checkingAuth };
};

const loginOrRedirectUrl = async () => {
  let result: { session: Session | null, redirectUrl: string | null } = {
    session: null,
    redirectUrl: null,
  };
  try {
    result.session = await getSession();
    return result;
  } catch (error) {
    // redirect back to the dashboard page once logged in
    result.redirectUrl = oryUrl + `/ui/login?return_to=${window.location.href}`;
    return result;
  }
};

const register = () => {
  let result: { session: Session | null, redirectUrl: string | null } = {
    session: null,
    redirectUrl: oryUrl + `/ui/registration?return_to=${window.location.href}`,
  };
  return result;
};

/**
 * Logs the user out of the app
 * see https://www.ory.sh/docs/kratos/self-service/flows/user-logout#single-page-application-spa
 */
const logout = async () => {
  try {
    // Create a "logout flow" in Ory Identities
    const { data: flow } = await ory.createBrowserLogoutFlow();
    // Use the received token to "update" the flow and thus perform the logout
    await ory.updateLogoutFlow({
      token: flow.logout_token,
    });

    window.location.replace(`${window.location.href}`);
  } catch (error) {
    // The user could not be logged out
    // This typically happens if the token does not match the session,
    // or is otherwise malformed or missing
    console.log(`Logout Error: ${error}`);
  }
};

export {
  ory,
  getSession,
  getUserName,
  useIsAuthenticated,
  Identity,
  Session,
  oryUrl,
  loginOrRedirectUrl,
  register,
  logout,
};
