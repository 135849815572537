/**
 *
 * ProjectsView
 * @format
 * @flow
 *
 */

import { Fab, Grid } from '@mui/material';
import React, { useState } from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import NewProjectModal from './NewProjectModal/NewProjectModal';
import type { Node } from 'react';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import Tooltip from 'app/components/Tooltip/Tooltip';

const ProjectsView = (): Node => {
  const [openNewProjectModal, setOpenNewProjectModal] = useState(false);

  return (
    <>
      <Grid container flexDirection="column">
        <Grid item xs={12}>
          <ProjectsTable />
        </Grid>
        <Grid item xs={12} sx={{ alignSelf: 'flex-end', marginTop: 2 }}>
          <Tooltip body="Add new project" placement="top">
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => {
                setOpenNewProjectModal(true);
              }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <NewProjectModal
        open={openNewProjectModal}
        onClose={() => setOpenNewProjectModal(false)}
      />
    </>
  );
};

export default ProjectsView;
