/**
 *
 * Description
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useEffect, useState } from 'react';

import EpisodeTypeInput from './EpisodeTypeInput/EpisodeTypeInput';
import GenreAndInitialReleaseInput from './GenreAndInitialReleaseInput/GenreAndInitialReleaseInput';
import { Grid } from '@mui/material';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import type { PropsType } from './types';
import TitleAndDescriptionInput from './TitleAndDescriptionInput/TitleAndDescriptionInput';

const Description: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    projectData,
    configTypes,
    showSaveButton = true,
    isSaving = false,
    onUpdateData,
    onUpdateProjectGeneralDetails = () => {},
  } = props;

  const { genreValues, releasePlatformValues } = configTypes ?? {};
  const sortedGenreValues = Array.isArray(genreValues)
    ? [...genreValues].sort((a, b) => a.order - b.order)
    : [];
  const sortedReleasePlatformValues = Array.isArray(releasePlatformValues)
    ? [...releasePlatformValues].sort((a, b) => a.order - b.order)
    : [];

  // state captures all of the state info from our controls
  const [combinedState, setCombinedState] = useState({
    title: '',
    genreTypeId: sortedGenreValues[0].typeInfo.id,
    genreOtherSpecify: '',
    single: true,
    noOfEpisodes: 1,
    duration: 0,
    initialReleasePlatformTypeId: sortedReleasePlatformValues[0].typeInfo.id,
    initialReleaseOtherSpecify: '',
    shortDescription: '',
  });

  useEffect(() => {
    if (projectData) {
      setCombinedState((prevState) => ({
        ...prevState,
        ...projectData,
      }));
    }
  }, [projectData]);

  const handleStateChange = (key: string, value: any) => {
    setCombinedState((prev) => {
      const newCombinedState: any = { ...prev, [key]: value };
      onUpdateData(newCombinedState);
      return newCombinedState;
    });
  };

  const {
    title,
    genreTypeId,
    genreOtherSpecify,
    single,
    noOfEpisodes,
    duration,
    initialReleasePlatformTypeId,
    initialReleaseOtherSpecify,
    shortDescription,
  } = combinedState;

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={3}>
        <Grid item xs sx={{ display: 'flex' }}>
          <TitleAndDescriptionInput
            title={title}
            shortDescription={shortDescription}
            handleStateChange={handleStateChange}
          />
        </Grid>

        <Grid item xs sx={{ display: 'flex' }}>
          <EpisodeTypeInput
            single={single}
            noOfEpisodes={noOfEpisodes}
            duration={duration}
            handleStateChange={handleStateChange}
          />
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs>
          <GenreAndInitialReleaseInput
            genreTypeId={genreTypeId}
            genreValues={sortedGenreValues}
            genreOtherSpecify={genreOtherSpecify}
            initialReleasePlatformTypeId={initialReleasePlatformTypeId}
            initialReleasePlatformValues={sortedReleasePlatformValues}
            initialReleaseOtherSpecify={initialReleaseOtherSpecify}
            handleStateChange={handleStateChange}
          />
        </Grid>
      </Grid>
      {showSaveButton && (
        <Grid item xs>
          <LoadingButton
            isLoading={isSaving}
            loadingText="Saving..."
            defaultText="Save"
            onClick={onUpdateProjectGeneralDetails}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default (memo(Description): AbstractComponent<PropsType, mixed>);
