/**
 *
 * NotSynchronizeDialog
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import React, { memo, useContext } from 'react';
import { LinkDataContext } from '../../contexts/LinkDataContext';
import RadioButton from 'app/components/RadioButton/RadioButton';
import TextField from 'app/components/TextField/TextField';
import { ContractContext } from 'app/contexts/Contract/Contract';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';

const NotSynchronizeDialog = (): Node => {
  const {
    isOpenNotSynchronizeDialog,
    onSetIsOpenNotSynchronizeDialog,
    notSynchronizeOptionVal,
    onSetNotSynchronizeOptionVal,
    onSetIsOpenChangeDetailsDialog,
    componentName: { contractTermKeyName },
    sourceData,
    linkDataProps: { linkData },
    fieldName,
  } = useContext(LinkDataContext);

  const { onUpdateContractTermData } = useContext(ContractContext) || {};

  const { getDescendantSourceValue, getLinkDataProps } = useLinkDataComponent();
  const { sourceFieldId, fieldId, fieldLabel, source } =
    getLinkDataProps(linkData, fieldName) || {};
  const fieldValue = getDescendantSourceValue(sourceData?.data, sourceFieldId);

  const handleOnClickOk = () => {
    switch (notSynchronizeOptionVal) {
      case 'replace':
        onUpdateContractTermData(contractTermKeyName, {
          [fieldId]: fieldValue,
        });
        onSetIsOpenNotSynchronizeDialog(false);
        break;
      case 'changeDetails':
        onSetIsOpenNotSynchronizeDialog(false);
        onSetIsOpenChangeDetailsDialog(true);
        break;
      default:
        onSetIsOpenNotSynchronizeDialog(false);
    }
  };

  return (
    <Dialog
      open={isOpenNotSynchronizeDialog}
      onClose={() => {
        onSetIsOpenNotSynchronizeDialog(false);
      }}
    >
      <DialogContent>
        <RadioButton
          id="notSynchronizeOptionVal"
          label={`Replace (sync with default ${source} settings below)`}
          value="replace"
          checked={notSynchronizeOptionVal === 'replace'}
          onValueChange={(_, val) => onSetNotSynchronizeOptionVal(val)}
        />
        <TextField
          id=""
          label={fieldLabel}
          value={fieldValue || ''}
          placeholder={!fieldValue && 'Not set'}
          disabled
        />
        {source === 'project' && (
          <RadioButton
            id="notSynchronizeOptionVal"
            label={`Change general ${source} settings`}
            value="changeDetails"
            checked={notSynchronizeOptionVal === 'changeDetails'}
            onValueChange={(_, val) => onSetNotSynchronizeOptionVal(val)}
          />
        )}
        {source === 'company' && (
          <Typography
            variant="caption"
            sx={{ marginBottom: 1, fontStyle: 'italic' }}
          >
            Go to the company settings to update default contract terms
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSetIsOpenNotSynchronizeDialog(false);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleOnClickOk}
          disabled={!notSynchronizeOptionVal}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default (memo(NotSynchronizeDialog): AbstractComponent<mixed>);
