/**
 *
 * AmendmentDialog
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import { Delete as DeleteIcon } from '@mui/icons-material';
import type { PropsType } from './types';
import Textarea from 'app/components/Textarea/Textarea';

const AmendmentDialog: AbstractComponent<PropsType> = memo(
  (props: PropsType): Node => {
    const {
      open = false,
      labelOne,
      labelTwo,
      value = '',
      onCloseDialog,
      onClickOne,
      onClickTwo,
      onBlurData,
      onResetData,
    } = props;

    const [showInputField, setShowInputField] = useState(
      value !== undefined && value !== null
    );

    useEffect(() => {
      if (value !== undefined && value !== null) {
        setShowInputField(true);
      }
    }, [value]);

    const buttonOneLabel = labelOne ? labelOne : 'Cancel';
    const buttonTwoLabel = labelTwo ? labelTwo : 'Add anyway';
    const buttonOneClickAction = onClickOne ? onClickOne : onCloseDialog;
    const buttonTwoClickAction = onClickTwo ? onClickTwo : onCloseDialog;

    return (
      <>
        <Dialog
          open={open}
          onClose={onCloseDialog}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .MuiDialog-paper ': { boxShadow: 'unset' },
          }}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(52, 52, 52, 0.1)',
              boxShadow: 'unset',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">Add Amendment?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will affect this standard term only.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={buttonOneClickAction}>{buttonOneLabel}</Button>
            <Button onClick={buttonTwoClickAction}>{buttonTwoLabel}</Button>
          </DialogActions>
        </Dialog>

        {showInputField && (
          <Grid
            item
            xs={12}
            sx={{
              '.MuiGrid-item': {
                paddingLeft: '10px',
                paddingTop: '0px',
                marginTop: 1,
              },
            }}
          >
            <Grid container direction="row" alignItems="center">
              <Grid item xs={11}>
                <Textarea
                  id="amendment"
                  label="* Amendment"
                  maxRows={5}
                  minRows={2}
                  value={value || ''}
                  onBlur={(id, val) => onBlurData(id, val)}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() => {
                    onResetData();
                    setShowInputField(false);
                  }}
                  size="large"
                  startIcon={<DeleteIcon />}
                ></Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
);

export default AmendmentDialog;
