import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}));
