/**
 *
 * PartyDetailsProdCo
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext, useEffect, useState } from 'react';

import type { CompanyDetailsReturnType } from 'company/graphQL/useCompanyDetailsQuery';
import type { ComponentDataType } from './types';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import { useCompanyDetailsQuery } from 'company/graphQL/useCompanyDetailsQuery';
import useGetRouteParam from 'app/hooks/useGetRouteParam';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const PartyDetailsProdCo: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const cyid = useGetRouteParam('cyid');
  const {
    data: companyDetailsData,
    loading,
    error,
  }: CompanyDetailsReturnType = useCompanyDetailsQuery({
    _id: cyid,
  });
  const [contractingParties, setContractingParties] = useState([]);

  const { contractTermData, onUpdateContractTermData: onUpdate } =
    useContext(ContractContext);

  const {
    name = '',
    type = '',
    email = '',
    abn = '',
    address,
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      companyName: '',
      givenName: '',
      familyName: '',
      type: '',
      email: '',
      abn: '',
      address: {},
    },
  });

  useEffect(() => {
    if (!!companyDetailsData?.company) {
      const company = companyDetailsData.company || {};
      const { contractingParties } = company || {};
      const newContractingParties = contractingParties?.map((item) => {
        const { name, type, email, abn, address } = item || {};
        const { street, suburb, state, postcode, country, number } =
          address || {};

        return {
          name,
          type,
          email,
          abn,
          address: {
            street,
            suburb,
            state,
            postcode,
            country,
            number,
          },
        };
      });

      setContractingParties(newContractingParties);
    }
  }, [companyDetailsData]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const handleChangeProductCo = (selectedName) => {
    const selectedProductCo = contractingParties?.find(
      (item) => item.name === selectedName
    );
    onUpdate(contractTermKeyName, {
      ...selectedProductCo,
    });
  };

  if (!contractingParties?.length) {
    return '';
  }

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={6} xs={6}>
        <Select
          id="name"
          label={'Production Company'}
          value={name}
          onSelectOption={(item) => {
            !Array.isArray(item) && handleChangeProductCo(item.value);
          }}
          options={contractingParties.map((item) => {
            return {
              value: item?.name || '',
              label: item?.name || '',
            };
          })}
        />
      </Grid>
      <Grid item lg={6} md={6} xs={6}>
        <TextField id="type" label="Type" value={type || ''} disabled />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          id="number"
          label="Number"
          value={address?.number || ''}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} xs={6}>
        <TextField
          id="street"
          label="Street"
          value={address?.street || ''}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          id="suburb"
          label="City / Suburb"
          value={address?.suburb || ''}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField id="state" label="State" value={address?.state} disabled />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          id="postcode"
          label="Postcode"
          value={address?.postcode || ''}
          disabled
        />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <TextField
          id="country"
          label="Country"
          value={address?.country || ''}
          disabled
        />
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <TextField id="abn" label="ABN" value={abn || ''} disabled />
      </Grid>

      <Grid item lg={12} md={12} xs={12}>
        <TextField id="email" label="Email" value={email || ''} disabled />
      </Grid>
    </Grid>
  );
};

export default (memo(PartyDetailsProdCo): AbstractComponent<PropsType, mixed>);
