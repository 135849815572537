/**
 *
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  ArrowSort,
  CompanyListContainer,
  CompanySwitchContainer,
  CompanySwitchMenuContainer,
  CompanySwitchTitle,
  PlusSmall,
} from './styles';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import AccountSetupDialog from 'app/components/AccountSetupDialog/AccountSetupDialog';
import { Check } from 'app/assets/icons';
import type { CompanySwitchPropsType } from './types';
import { EntityTypeValues } from 'app/services/graphQL/generated-types';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useUpdateUserMutation } from 'user/graphQL/useUpdateUserMutation';
import { useUserQuery } from 'user/graphQL/useUserQuery';

const CompanySwitchMenu: AbstractComponent<CompanySwitchPropsType> =
  (): Node => {
    const theme = useTheme();
    const [actionsEl, setActionsEl] = useState(null);
    const dialogRef = useRef(null);
    const history = useHistory();
    const { updateUser } = useUpdateUserMutation();
    const { data: userData } = useUserQuery();
    const hasCompanies = userData?.user?.companies?.length;

    const onCompanyChange = (selectedCompanyId) => {
      updateUser({
        variables: {
          user: {
            _id: userData?.user?._id || '',
            selectedCompany: selectedCompanyId,
          },
        },
      })?.then(() => {
        // navigate to selected company
        history.push(`/dashboard/${selectedCompanyId}/projects`);
      });
    };

    return (
      <>
        <Box
          aria-controls="company-switch-actions"
          aria-haspopup="true"
          sx={{ marginBottom: '24px' }}
          onClick={(e) => {
            setActionsEl(e.currentTarget);
          }}
        >
          <CompanySwitchContainer container gap={1} alignItems="center">
            {hasCompanies ? (
              <>
                <Grid item>
                  <Avatar
                    sx={{
                      gridArea: 'companyLogo',
                      height: '35px',
                      width: '35px',
                      marginRight: '1px',
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.grey['100'],
                      borderRadius: '5px',
                    }}
                    variant="square"
                  >
                    {'RP'}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Grid container display="flex" direction="column">
                    <Box sx={{ gridArea: 'company' }}>
                      <CompanySwitchTitle
                        variant="subtitle2"
                        className="expMenuItem"
                        sx={{
                          fontSize: '10px',
                          fontWeight: '700',
                          color: theme.palette.grey['600'],
                          textTransform: 'uppercase',
                        }}
                      >
                        {userData?.user?.companies?.find(
                          (item) => item._id === userData?.user?.selectedCompany
                        )?.type === EntityTypeValues.Company
                          ? 'Organisation'
                          : 'Individual'}
                      </CompanySwitchTitle>
                    </Box>
                    <Box sx={{ gridArea: 'companyName' }}>
                      <CompanySwitchTitle
                        variant="subtitle1"
                        className="expMenuItem"
                      >
                        {
                          userData?.user?.companies?.find(
                            (item) =>
                              item._id === userData?.user?.selectedCompany
                          )?.name
                        }
                      </CompanySwitchTitle>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="body">No Organisation Yet</Typography>
            )}
            <Grid item sx={{ m: 'auto' }}>
              <ArrowSort style={{ height: '24px', width: '24px' }} />
            </Grid>
          </CompanySwitchContainer>
          <Divider
            variant="middle"
            sx={{
              marginLeft: {
                lg: '10px',
                sm: '5px',
              },
              marginRight: {
                sm: '0',
              },
              marginTop: '8px',
            }}
          />
        </Box>
        <CompanySwitchMenuContainer
          id="company-switch-actions"
          anchorEl={actionsEl}
          keepMounted
          open={Boolean(actionsEl)}
          onClose={() => setActionsEl(null)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {userData?.user?.companies?.map((company) => (
            <CompanyListContainer
              container
              gap={1.5}
              key={company?._id}
              onClick={() => {
                onCompanyChange(company?._id);
              }}
            >
              <Grid item>
                <Avatar
                  sx={{
                    gridArea: 'companyLogo',
                    height: '32px',
                    width: '32px',
                  }}
                  variant="square"
                >
                  RP
                </Avatar>
              </Grid>
              <Grid item>
                <Grid container display="flex" direction="column">
                  <Box sx={{ gridArea: 'company' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '10px',
                        fontWeight: '700',
                        color: theme.palette.grey['600'],
                        textTransform: 'uppercase',
                      }}
                    >
                      {company.type === EntityTypeValues.Company
                        ? 'Organisation'
                        : 'Individual'}
                    </Typography>
                  </Box>
                  <Box sx={{ gridArea: 'companyName' }}>
                    <Typography variant="subtitle1">{company.name}</Typography>
                  </Box>
                </Grid>
              </Grid>
              {company._id === userData?.user?.selectedCompany && (
                <Grid item sx={{ mt: 'auto', mr: '0', mb: '0', ml: 'auto' }}>
                  <Check style={{ height: '24px', width: '24px' }} />
                </Grid>
              )}
            </CompanyListContainer>
          ))}
          <Divider variant="middle" sx={{ marginTop: '8px', my: '16px' }} />
          <CompanyListContainer
            createOrg={true}
            onClick={() => {
              setActionsEl(null);
              dialogRef?.current?.open(true);
            }}
          >
            <Grid item>
              <PlusSmall style={{ height: '24px', width: '24px' }} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">Create Organisation</Typography>
            </Grid>
          </CompanyListContainer>
        </CompanySwitchMenuContainer>
        <AccountSetupDialog
          dialogRef={dialogRef}
          userId={userData?.user?._id || ''}
          userGivenName={userData?.user?.givenName || ''}
          userFamilyName={userData?.user?.familyName || ''}
        />
      </>
    );
  };

export default CompanySwitchMenu;
