/**
 *
 * LinkData
 * @format
 * @flow
 *
 */

import type { AbstractComponent } from 'react';
import React, { memo, useContext, useEffect, useState } from 'react';
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  LoopOutlined as LoopOutlinedIcon,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LinkDataContext } from './contexts/LinkDataContext';
import { ContractContext } from 'app/contexts/Contract/Contract';
import type { PropsType } from './types';
import Tooltip from 'app/components/Tooltip/Tooltip';
import {
  useProjectChangedAffectedContractsLazyQuery,
  ProjectChangedAffectedContractsLazyReturnType,
} from 'contract/graphQL/useProjectChangedAffectedContractsQuery';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import { CircularProgress } from '@mui/material';

/**
 * LinkData component can be used to any component (e.g TextField)
 * that needs sychronising
 * with database data
 * */
const LinkData: AbstractComponent<PropsType> = ({
  value,
  fieldName,
  applyDefaultSettings,
}): Node => {
  const theme = useTheme();
  const {
    onSetIsOpenNotSynchronizeDialog,
    onSetIsOpenChangeDetailsDialog,
    onSetIsOpenAffectedContractsDialog,
    onSetAllAffectedContractsData,
    onSetMutationHook,
    linkDataProps,
    selectedAffectedContracts,
    sourceData,
    componentName: { contractTermKeyName },
    setNewFieldValue,
    setFieldName,
    newFieldValue,
    isDataLoading,
  } = useContext(LinkDataContext);
  const { onUpdateContractTermData } = useContext(ContractContext) || {};

  const [isSynchronized, setIsSychronized] = useState<boolean>(false);

  const { linkData } = linkDataProps || {};

  const [
    { loading: isLoadingGetAffectedContracts, data: affectedContractsData },
  ]: ProjectChangedAffectedContractsLazyReturnType =
    useProjectChangedAffectedContractsLazyQuery();

  const {
    isDataSynchronized,
    getToolTipBody,
    loadLazyQuery,
    getMutation,
    getDescendantSourceValue,
    getLinkDataProps,
  } = useLinkDataComponent();

  const { source, sourceFieldId, fieldId } =
    getLinkDataProps(linkData, fieldName) || {};

  useEffect(() => {
    // if there is a list of contracts affected with the change show the next modal and display all contracts
    // if there is no list of contracts affected just save directly the new project/company settings
    if (affectedContractsData?.projectChangedAffectedContracts?.length) {
      onSetIsOpenChangeDetailsDialog(false);
      onSetIsOpenAffectedContractsDialog(true);
      onSetAllAffectedContractsData(
        affectedContractsData?.projectChangedAffectedContracts,
        isLoadingGetAffectedContracts
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affectedContractsData]);

  useEffect(() => {
    onSetMutationHook({
      ...getMutation(source, newFieldValue),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFieldValue, selectedAffectedContracts]);

  useEffect(() => {
    if (source) {
      loadLazyQuery(source); // load project/company details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const setDefaultSettings = (sourceData) => {
    if (sourceData?.data) {
      // applyDefaultSettings - for custom update contract term data
      if (applyDefaultSettings) {
        applyDefaultSettings(
          getDescendantSourceValue(sourceData?.data, sourceFieldId)
        );
      } else {
        onUpdateContractTermData(contractTermKeyName, {
          [fieldId]: getDescendantSourceValue(sourceData?.data, sourceFieldId),
        });
      }
    }
  };
  useEffect(() => {
    setIsSychronized(
      isDataSynchronized(value, sourceData?.data, sourceFieldId)
    );

    // upon create contract, this will set the default values for project/company settings
    setDefaultSettings(sourceData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceData]);

  useEffect(() => {
    setIsSychronized(
      isDataSynchronized(value, sourceData?.data, sourceFieldId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {isDataLoading ? (
        <CircularProgress
          size="1.5em"
          sx={{ alignSelf: 'center', marginLeft: 3 }}
        />
      ) : (
        <Tooltip body={getToolTipBody(isSynchronized)}>
          {isSynchronized ? (
            <CheckCircleOutlineOutlinedIcon
              sx={{ color: theme.palette.primary.main }}
            />
          ) : (
            <Box
              onClick={() => {
                onSetIsOpenNotSynchronizeDialog(true);
                setFieldName(fieldName);
                setNewFieldValue(value);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <LoopOutlinedIcon sx={{ color: theme.palette.warning.main }} />
            </Box>
          )}
        </Tooltip>
      )}
    </>
  );
};

export default (memo(LinkData): AbstractComponent<PropsType, mixed>);
