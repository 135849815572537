import DrawerBase from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';

import { styled } from '@mui/system';

export const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      left: '256px',
      width: '256px',
      top: '220px',
      borderRight: `1px solid ${theme.palette.grey['300']}`,
      height: 'calc(100vh - 200px)',
      paddingTop: '20px',
      zIndex: '1',
      visibility: 'visible !important',
      [theme.breakpoints.down('lg')]: {
        left: '70px',
        zIndex: '0',
      },
      '& .MuiAccordion-root': {
        boxShadow: 'none',
        padding: '0 0 0 3px',
        margin: '0',
        '& .MuiAccordionSummary-root': {
          padding: '0 24px',
          height: '48px',
          '&.Mui-expanded': {
            minHeight: 'unset',
            boxShadow: `-3px 0 0 0 ${theme.palette.primary.main}`,
            '& .MuiTypography-root': {
              color: theme.palette.primary.main,
              fontWeight: '600',
              '&:hover': {
                color: theme.palette.primary.clicked,
              },
            },
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: '0',
          '& .MuiListItem-root': {
            color: theme.palette.grey['750'],
            paddingLeft: '32px',
            '&.Mui-selected': {
              background: 'none',
              boxShadow: `-3px 0 0 0 ${theme.palette.primary.main}`,
              '& .MuiTypography-root': {
                color: theme.palette.primary.main,
              },
            },
          },
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        '& svg > path': {
          stroke: theme.palette.grey['700'],
        },
      },
      '& .MuiTypography-root': {
        textDecoration: 'none',
        color: theme.palette.grey['750'],
        fontWeight: '400',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  drawerInnerContainer: {
    height: '100%',
  },
  accordionDetailsListItem: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const Drawer = styled(DrawerBase)(({ theme, open }) => ({
  zIndex: '-2',
  '& .MuiDrawer-paper': {
    ...(!open && {
      transform: 'translateX(-190px) !important',
    }),
  },
}));

export { Drawer };
