/**
 *
 * GenreAndInitialReleaseInput
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Paper } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import { isOtherType } from 'app/utils/general';

const GenreAndInitialReleaseInput: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const {
    genreTypeId,
    genreValues,
    genreOtherSpecify,
    initialReleasePlatformTypeId,
    initialReleasePlatformValues,
    initialReleaseOtherSpecify,
    handleStateChange,
  } = props;

  return (
    <Paper elevation={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            id="genreTypeId"
            label="Genre"
            value={genreTypeId}
            onSelectOption={(item) => {
              !Array.isArray(item) &&
                handleStateChange('genreTypeId', item.value);
            }}
            options={genreValues.map((item) => ({
              value: String(item.typeInfo.id),
              label: item.displayValue,
            }))}
          />
        </Grid>

        {isOtherType(genreTypeId) && (
          <Grid item xs={12}>
            <TextField
              id="genreOtherSpecify"
              label="Other (specify)"
              value={genreOtherSpecify ?? ''}
              onBlur={handleStateChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Select
            id="initialReleasePlatformTypeId"
            label="Initial Release"
            value={initialReleasePlatformTypeId}
            onSelectOption={(item) => {
              !Array.isArray(item) &&
                handleStateChange('initialReleasePlatformTypeId', item.value);
            }}
            options={initialReleasePlatformValues.map((item) => ({
              value: String(item.typeInfo.id),
              label: item.displayValue,
            }))}
          />
        </Grid>

        {isOtherType(initialReleasePlatformTypeId) && (
          <Grid item xs={12}>
            <TextField
              id="initialReleaseOtherSpecify"
              label="Other (specify)"
              value={initialReleaseOtherSpecify ?? ''}
              onBlur={handleStateChange}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default (memo(GenreAndInitialReleaseInput): AbstractComponent<
  PropsType,
  mixed
>);
