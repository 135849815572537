/**
 *
 * General
 * @format
 * @flow
 *
 */

import { anyPass, isEmpty, isNil } from 'ramda';

import { pickBy } from 'lodash';

const isNilOrEmpty: (...args: Array<?(any | string | Array<any>)>) => boolean =
  anyPass([isNil, isEmpty]);

const largestItemId = (items = []): string =>
  items.reduce(
    (prev, cur) =>
      parseInt(cur.itemId, 10) > parseInt(prev, 10) ? cur.itemId : prev,
    '0'
  );

const isOtherType = (typeId) => {
  return parseInt(typeId, 10) === 0;
};

/**
 * Filters the target object removing any keys that are not in the keysToKeep array. It then adds
 * the keys from the additionalObject and finally removes any null values, returning the result.
 *
 *
 * @param {object} targetObject - The object to filter
 * @param {array} keysToKeep - The keys to keep in the targetObject
 * @param {object} additionalObject - The object to add to the targetObject
 * @returns {object} - The filtered object
 */
const filterObjectDataRemovingNulls = (
  targetObject: { ... },
  keysToKeep: Array<string>,
  additionalObject: ?{ ... }
): { ... } => {
  return pickBy({
    ...pickBy(targetObject, (value, key) => keysToKeep.includes(key)),
    ...additionalObject,
  });
};

export {
  isNilOrEmpty,
  largestItemId,
  isOtherType,
  filterObjectDataRemovingNulls,
};
