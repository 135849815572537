/**
 *
 * CollapsableSection
 *
 * This component works with the default values from the parent component
 * Optionals pros :
 *     labelSwitch
 *     labelButtonOne,
 *     labelButtonTwo,
 *     titleDialog,
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import React, { memo, useState } from 'react';

import Grid from '@mui/material/Grid';
import type { PropsType } from './types';
import Switch from 'app/components/Switch/Switch';

const CollapsableSection = (props: PropsType): Node => {
  const {
    labelOne = '',
    labelTwo = '',
    labelSwitch,
    titleDialog = '',
    checkSwitch,
    children,
    onResetData,
    onChangeSwitch,
    onShowDialogueOnClose,
    display,
  } = props;

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const closeDialog = () => setIsOpenDialog(false);

  const labelButtonOne = labelOne ? labelOne : 'NO';
  const labelButtonTwo = labelTwo ? labelTwo : 'YES';
  const title = titleDialog ? titleDialog : 'Do you want to delete this data?';

  const resetDataAndCloseDialogue = () => {
    onResetData();
    closeDialog();
  };

  const switchChanged = () => {
    if (checkSwitch && onShowDialogueOnClose()) {
      setIsOpenDialog(true);
    } else {
      onChangeSwitch();
    }
  };

  return (
    <>
      <Switch
        id={'switchVal'}
        label={labelSwitch}
        checked={checkSwitch}
        onChange={switchChanged}
        disabled={display === 'none'}
        display={display}
      />
      <Dialog
        open={isOpenDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogActions>
          <Button onClick={closeDialog}>{labelButtonOne}</Button>
          <Button onClick={resetDataAndCloseDialogue}>{labelButtonTwo}</Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Collapse in={checkSwitch} sx={{ marginTop: '16px' }}>
          {children}
        </Collapse>
      </Grid>
    </>
  );
};

export default (memo(CollapsableSection): AbstractComponent<PropsType, mixed>);
