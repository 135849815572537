/**
 *
 * TextField
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { InputAdornment, Typography } from '@mui/material';
import {
  MainContainer,
  TextFieldStyled,
  TextFieldStyledContainer,
} from './styles';
import React, { memo, useEffect, useState } from 'react';

import type { PropsType } from './types';
import Tooltip from 'app/components/Tooltip/Tooltip';

const TextField: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    id,
    label,
    value,
    type = 'text',
    placeholder,
    multiline,
    rows,
    minRows,
    maxRows,
    required,
    error,
    helperText,
    disabled,
    readOnly,
    shrink,
    formatMoney,
    numberOnly,
    onValueChange,
    onClick,
    onFocus,
    onBlur,
    onKeyPress,
    startAdornment,
    endAdornment,
    style,
    containerStyle,
    tooltipTrigger = 'click',
    tooltipPlacement = 'top',
    tooltipArrow = true,
    tooltipTitle,
    tooltipBody,
    autoComplete,
    renderLinkData,
  } = props;

  const valueType = typeof value;
  if (
    valueType !== 'string' &&
    valueType !== 'undefined' &&
    valueType !== 'number'
  ) {
    throw Error(`TextField received non string value type: ${valueType}`);
  }

  //$FlowFixMe
  const convertingValue = valueType === 'number' ? value.toString() : value;
  const [textValue, setTextValue] = useState(convertingValue || '');

  useEffect(() => {
    setTextValue(convertingValue || '');
  }, [convertingValue]);

  const onTextChange = (e) => {
    const inputVal = e.target.value;

    if (numberOnly && !inputVal.replace(/,/g, '').match(/^(\s*|\d+)$/)) {
      return;
    }

    const finalVal = formatMoney ? inputVal.replace(/,/g, '') : inputVal;

    setTextValue(finalVal);

    if (onValueChange) {
      onValueChange(id, finalVal);
    }
  };

  const onTextBlur = () => {
    if (onBlur) {
      onBlur(id, String(textValue));
    }
  };

  const renderTextField = (
    <TextFieldStyledContainer>
      <TextFieldStyled
        id={id}
        value={
          formatMoney
            ? String(textValue).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
            : textValue
        }
        type={type}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        required={required}
        error={error}
        helperText={helperText}
        disabled={disabled}
        variant="outlined"
        onChange={onTextChange}
        onClick={onClick}
        onKeyPress={onKeyPress}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={onTextBlur}
        InputProps={{
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          readOnly,
          autoComplete,
        }}
        InputLabelProps={{ shrink }}
        style={style}
      />
      {renderLinkData && renderLinkData}
    </TextFieldStyledContainer>
  );

  return (
    <MainContainer style={containerStyle}>
      {label && (
        <Typography variant="body2" sx={{ marginBottom: 1 }}>
          {label}
        </Typography>
      )}
      {tooltipTitle || tooltipBody ? (
        <Tooltip
          {...{
            trigger: tooltipTrigger,
            placement: tooltipPlacement,
            arrow: tooltipArrow,
            title: tooltipTitle,
            body: tooltipBody,
          }}
        >
          {renderTextField}
        </Tooltip>
      ) : (
        <>{renderTextField}</>
      )}
    </MainContainer>
  );
};

export default (memo(TextField): AbstractComponent<PropsType, mixed>);
