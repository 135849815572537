/**
 *
 * EpisodeTypeInput
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Paper } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';
import RadioButton from 'app/components/RadioButton/RadioButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup/RadioButtonGroup';
import TextField from 'app/components/TextField/TextField';

const EpisodeTypeInput: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { single, noOfEpisodes, duration, handleStateChange } = props;

  return (
    <Paper elevation={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RadioButtonGroup
            id="single"
            row={true}
            label="Type"
            value={single ? 'single' : 'series'}
            onValueChange={(id, val) => handleStateChange(id, val === 'single')}
          >
            <RadioButton id="Single" label="Single" value="single" />
            <RadioButton id="Series" label="Series" value="series" />
          </RadioButtonGroup>
        </Grid>

        {!single && (
          <>
            <Grid item xs={12}>
              <TextField
                id="noOfEpisodes"
                label="Number of Episodes"
                value={noOfEpisodes}
                numberOnly
                onBlur={(id, val) => handleStateChange(id, parseInt(val, 10))}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <TextField
            id="duration"
            label="Duration (mins)"
            value={duration}
            numberOnly
            onBlur={(id, val) => handleStateChange(id, parseInt(val, 10))}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default (memo(EpisodeTypeInput): AbstractComponent<PropsType, mixed>);
