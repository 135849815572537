/**
 *
 * DefaultTerms
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Alert, Grid, Paper, Snackbar, Typography } from '@mui/material';
import type { DefaultTermsInfoType, PropsType } from './types';
import React, { memo, useEffect, useState } from 'react';

import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import PredictiveTextField from 'app/components/PredictiveTextField/PredictiveTextField';
import Select from 'app/components/Select/Select';
import TextAreaSuggestions from 'app/components/TextAreaSuggestions/TextAreaSuggestions';
import Tooltip from 'app/components/Tooltip/Tooltip';
import type { UpdateCompanyDetailsReturnType } from 'company/graphQL/useUpdateCompanyDetailsMutation';
import { filterObjectDataRemovingNulls } from 'app/utils/general';
import { useCompanyDetailsQuery } from 'company/graphQL/useCompanyDetailsQuery';
import { useParams } from 'react-router-dom';
import { useUpdateCompanyDetailsMutation } from 'company/graphQL/useUpdateCompanyDetailsMutation';

const DefaultTerms: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const routeParams = useParams();
  const cyid = routeParams.cyid || '';
  const {
    data: companyDetailsData,
    loading,
    error,
  } = useCompanyDetailsQuery({
    _id: cyid,
  });
  const {
    updateCompanyDetails,
    data: updateCompanyDetailsData,
    loading: companyDetailsIsSaving,
  }: UpdateCompanyDetailsReturnType = useUpdateCompanyDetailsMutation();

  const [data, setData] = useState<DefaultTermsInfoType>({
    periodicPaymentTerms: '',
    periodicTerm: '',
    invoicePaymentTerms: '',
    accountingPeriod: '',
    jurisdiction: '',
  });
  const {
    periodicPaymentTerms,
    periodicTerm,
    invoicePaymentTerms,
    accountingPeriod,
    jurisdiction,
  } = data;

  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    severity: '',
    message: '',
  });

  useEffect(() => {
    if (companyDetailsData) {
      const company = companyDetailsData.company || {};
      // $FlowFixMe
      const { __typename, ...restCompanyData } = company;

      const filteredCompanyDataWithNullsRemoved = filterObjectDataRemovingNulls(
        restCompanyData,
        [
          'periodicPaymentTerms',
          'periodicTerm',
          'invoicePaymentTerms',
          'accountingPeriod',
          'jurisdiction',
        ]
      );
      // $FlowFixMe
      setData((prevState) => ({
        ...prevState,
        ...filteredCompanyDataWithNullsRemoved,
      }));
    }
  }, [companyDetailsData]);

  useEffect(() => {
    if (!!updateCompanyDetailsData?.updateCompanyDetails?.company) {
      setAlertInfo({
        severity: 'success',
        message: 'Success saving the default contract terms!',
      });
      setSnackbarOpen(true);
    }
    if (updateCompanyDetailsData?.updateCompanyDetails?.company === null) {
      setAlertInfo({
        severity: 'error',
        message: 'Error saving the default contract terms!',
      });
      setSnackbarOpen(true);
    }
  }, [updateCompanyDetailsData]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (!companyDetailsData?.company) return <p>Not found</p>;

  const handleStateChange = (fieldName: string, fieldValue: any) => {
    setData((prevState) => {
      let newData = {
        ...prevState,
        [fieldName]: fieldValue,
      };

      return newData;
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleUpdateCompanyDetails = () => {
    updateCompanyDetails({
      variables: {
        company: {
          _id: cyid,
          periodicPaymentTerms,
          periodicTerm,
          invoicePaymentTerms,
          accountingPeriod,
          jurisdiction,
        },
      },
    });
  };

  return (
    <Grid container spacing={1}>
      <Paper sx={{ marginTop: 5, padding: 2, width: '100%' }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 1 }}
        >
          <Tooltip
            body="For employees and any others paid periodically"
            placement="right-end"
          >
            <Typography variant="body2">Payment Terms (periodic)</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <PredictiveTextField
            id="periodicPaymentTerms"
            label=""
            freeSolo
            options={[
              { idGroup: '', selectVal: 'Weekly' },
              { idGroup: '', selectVal: 'Every 2 weeks' },
              { idGroup: '', selectVal: 'Monthly' },
            ]}
            inputValue={periodicPaymentTerms}
            onInputChange={(selected, newInputValue) =>
              handleStateChange('periodicPaymentTerms', newInputValue)
            }
          />
          <Select
            id="periodicTerm"
            value={periodicTerm}
            onSelectOption={(item) => {
              !Array.isArray(item) &&
                handleStateChange('periodicTerm', item.value);
            }}
            options={[
              'In arrears',
              'In advance',
              'Half in advance half in arrears',
            ].map((item) => ({
              value: item,
              label: item,
            }))}
            formControlStyle={{ marginTop: 10 }}
          />
        </Grid>
      </Paper>

      <Paper sx={{ marginTop: 3, padding: 2, width: '100%' }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 1 }}
        >
          <Tooltip
            body="Payment of invoices (subject to milestones and terms of agreements)"
            placement="right-end"
          >
            <Typography variant="body2">Payment Terms (invoice)</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Select
            id="invoicePaymentTerms"
            value={invoicePaymentTerms}
            onSelectOption={(item) => {
              !Array.isArray(item) &&
                handleStateChange('invoicePaymentTerms', item.value);
            }}
            options={['7 days', '14 days', '30 days', '1 month'].map(
              (item) => ({
                value: item,
                label: item,
              })
            )}
          />
        </Grid>
      </Paper>

      <Paper sx={{ marginTop: 3, padding: 2, width: '100%' }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 1 }}
        >
          <Tooltip
            body="When you pay others their share of revenue received from distribution. See suggestions for example terms)"
            placement="right-end"
          >
            <Typography variant="body2">Accounting Period</Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TextAreaSuggestions
            id="accountingPeriod"
            label=""
            value={accountingPeriod}
            suggestions={[
              'Once a year',
              'Every 6 months for the first year, then once a year',
              'Every 6 months for the first 2 years, then once a year',
              'Every 6 months for the first 4 years, then once a year',
            ]}
            onSubmitDescription={(id, val) => {
              handleStateChange(id, val);
            }}
            onChangeTextarea={(id, val) => {
              handleStateChange(id, val);
            }}
          />
        </Grid>
      </Paper>

      <Paper sx={{ marginTop: 3, padding: 2, width: '100%' }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 1 }}
        >
          <Typography variant="body2">Jurisdiction and Law</Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            id="jurisdiction"
            value={jurisdiction}
            onSelectOption={(item) => {
              !Array.isArray(item) &&
                handleStateChange('jurisdiction', item.value);
            }}
            options={[
              'SA',
              'NSW',
              'Vic',
              'Queensland',
              'WA',
              'Tasmania',
              'ACT',
              'NT',
            ].map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Grid>
      </Paper>
      <Grid item xs>
        <LoadingButton
          isLoading={companyDetailsIsSaving}
          loadingText="Saving..."
          defaultText="Save"
          onClick={handleUpdateCompanyDetails}
        />
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertInfo.severity || 'error'}
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default (memo(DefaultTerms): AbstractComponent<PropsType, mixed>);
