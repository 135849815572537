/**
 *
 * useUpdateProjectScheduleMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateProjectScheduleMutation,
  UpdateProjectScheduleMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { createProjectDetailsFragment } from 'project/graphQL/fragments/projectFragments';

const UPDATE_PROJECT_SCHEDULE = gql`
  mutation UpdateProjectSchedule($schedule: ProjectScheduleInput!) {
    updateProjectSchedule(schedule: $schedule) {
      project {
        ...createProjectDetails
        schedule {
          roughCutDelivery {
            name
            date
          }
          fineCutDelivery {
            name
            date
          }
        }
      }
    }
  }
  ${createProjectDetailsFragment}
`;

export type UpdateProjectScheduleReturnType = {
  updateProjectSchedule: ({
    variables: UpdateProjectScheduleMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateProjectScheduleMutation,
  error: any,
};

export const useUpdateProjectScheduleMutation =
  (): UpdateProjectScheduleReturnType => {
    const [updateProjectSchedule, { data, loading, error }] = useMutation(
      UPDATE_PROJECT_SCHEDULE
    );

    return {
      updateProjectSchedule,
      loading,
      data,
      error,
    };
  };
