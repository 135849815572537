/**
 *
 * ExternalDocItem
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  Delete as DeleteIcon,
  FileDownload as FileDownloadIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import React, { memo, useState } from 'react';

import ExcelLogo from 'app/assets/images/excel.png';
import { MenuStyled } from './styles';
import PDFLogo from 'app/assets/images/pdf.png';
import type { PropsType } from './types';
import WordLogo from 'app/assets/images/word.png';
// $FlowFixMe
import { format } from 'date-fns';

const ExternalDocItem: AbstractComponent<PropsType> = ({
  fileInfo,
}: PropsType): Node => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const open = Boolean(anchorEl);
  const {
    fileName,
    fileType,
    createdAt,
    uploadedBy,
    notes,
    whoDownloaded,
    url,
  } = fileInfo;

  const createdDate = format(new Date(parseInt(createdAt)), 'dd MMM yyyy');

  const onOpenDialog = () => setIsOpenDialog(true);

  const onCloseDialog = () => setIsOpenDialog(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectFileTypeLogo = (typeOfFile: string) => {
    switch (typeOfFile) {
      case 'pdf':
        return PDFLogo;
      case 'xls':
      case 'xlsx':
        return ExcelLogo;
      case 'doc':
      case 'docx':
        return WordLogo;
      default:
        return WordLogo;
    }
  };

  const downloadFile = (fileUrl, nameOfFile, fileExt) => {
    // anchor link
    const element = document.createElement('a');
    element.href = url;
    element.download = `${nameOfFile}.${fileExt}`;
    //$FlowFixMe
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    //$FlowFixMe
    document.body.removeChild(element);
  };

  const renderDownloadedByModal = () => {
    return (
      <Dialog
        open={isOpenDialog}
        onClose={onCloseDialog}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Downloaded By</Typography>
            <IconButton onClick={onCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {whoDownloaded?.map(({ downloadedBy, downloadedDate }, index) => (
            <Typography variant="subtitle1" key={index}>
              {downloadedBy} - {downloadedDate}
            </Typography>
          ))}
          {!whoDownloaded?.length && (
            <Typography variant="body2">
              No user has downloaded this file.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Paper sx={{ marginBottom: 2 }}>
      <Grid container direction="row" alignItems="center">
        <Grid
          item
          xs={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <img
            style={{ width: 50, height: 50 }}
            src={selectFileTypeLogo(fileType)}
            alt="pdf"
          />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1">
            {fileName}.{fileType}
          </Typography>
          <Grid container direction="row" alignItems="center" space={2}>
            <Typography variant="subtitle2">{uploadedBy}</Typography>
            <Typography
              variant="subtitle2"
              sx={{ marginRight: 1, marginLeft: 1 }}
            >
              -
            </Typography>
            <Typography variant="subtitle2">{createdDate}</Typography>
          </Grid>
          <Typography variant="subtitle2">{notes}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="more"
            id="more-button"
            aria-controls={open ? 'more-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <MenuStyled
            id="more-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'more-button',
            }}
          >
            <MenuItem onClick={handleClose}>
              <DeleteIcon />
              Delete
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                downloadFile(url, fileName, fileType);
              }}
            >
              <CloudDownloadIcon />
              Download
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                onOpenDialog();
              }}
            >
              <FileDownloadIcon />
              Downloaded by
            </MenuItem>
          </MenuStyled>
        </Grid>
      </Grid>
      {renderDownloadedByModal()}
    </Paper>
  );
};

export default (memo(ExternalDocItem): AbstractComponent<PropsType, mixed>);
