import Headings from './Headings';
import React from 'react';

const TableOfContents = ({ nestedHeadings }) => {
  return (
    <nav aria-label="Table of contents">
      <Headings headings={nestedHeadings} />
    </nav>
  );
};

export default TableOfContents;
