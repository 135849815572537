/**
 *
 * EngagementPeriod
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useContext, useState, useEffect } from 'react';
import { IconButton, Typography, Grid } from '@mui/material';
import type { PropsType } from '../types';
import DragDropContainer from 'app/components/DragDropContainer/DragDropContainer';
import ExclusivePeriod from './ExclusivePeriod';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { PlusIcon } from './styles';
import type { ComponentDataType } from './types';
import { omit } from 'lodash';

const EngagementPeriod: AbstractComponent<PropsType> = ({
  contractTermKeyName,
}: PropsType): Node => {
  const { contractTermProps, contractTermData, onUpdateContractTermData } =
    useContext(ContractContext);

  const { items = [], order = [] }: ComponentDataType =
    contractTermData[contractTermKeyName] || {};

  const { linkData } = contractTermProps[contractTermKeyName] || {};
  const initialData = {
    exclusivity: '',
    otherDetails: '',
    startDate: '',
    endDate: '',
    isDragDisabled: true,
    isSelected: false,
  };

  const [periods, setPeriods] = useState([
    {
      description: 'Pre-production',
      ...initialData,
    },
    {
      description: 'Production',
      ...initialData,
    },
    {
      description: 'Post-production',
      ...initialData,
    },
    {
      description: 'Final-materials-delivery',
      ...initialData,
    },
  ]);

  // only save selected items
  const saveSelectedItems = (items) => {
    return items
      ?.filter((item) => item.isSelected)
      .map((item) =>
        omit(item, [
          'startDate',
          'endDate',
          'isCustomPeriod',
          'content',
          'isDragDisabled',
          'isSelected',
        ])
      );
  };

  const onAddCustomPeriod = async () => {
    const newPeriod = {
      description: '',
      content: renderExclusivePeriod(periods.length, '', true),
      isCustomPeriod: true,
      isSelected: true,
    };
    const updatedPeriods = [newPeriod, ...periods];
    await onUpdateData(updatedPeriods);
  };

  const reorder = (periods, startIndex, endIndex) => {
    const newPeriods = Array.from(periods);
    const [removedPeriod] = newPeriods.splice(startIndex, 1);
    newPeriods.splice(endIndex, 0, removedPeriod);

    return newPeriods;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedPeriods = reorder(
      periods,
      result.source.index,
      result.destination.index
    );

    onUpdateData(reorderedPeriods);
  };

  const onUpdateData = (updatedPeriods) => {
    setPeriods(updatedPeriods);
    onUpdateContractTermData(contractTermKeyName, {
      items: saveSelectedItems(updatedPeriods),
      order: updatedPeriods.map((period) => period.description),
    });
  };

  const onSelectPeriod = (period, checked, index, isCustomPeriod = false) => {
    let updatedPeriods = [...periods];

    updatedPeriods = updatedPeriods.map((period, id) => {
      const updatedPeriod = {
        ...period,
        content: renderExclusivePeriod(id, period.description),
      };
      if (id === index) {
        return {
          ...updatedPeriod,
          isSelected: checked,
        };
      }
      return updatedPeriod;
    });

    onUpdateData(updatedPeriods);
  };

  const onChangeData = (field, value, index, isCustomPeriod) => {
    const updatedPeriods = periods?.map((period, id) => {
      if (id === index) {
        return {
          ...period,
          [field]: value,
        };
      }
      return period;
    });

    onUpdateData(updatedPeriods);
  };

  const onDeleteCustomPeriod = (index) => {
    const updatedPeriods = periods.filter((period, id) => id !== index);
    onUpdateData(updatedPeriods);
  };

  const renderExclusivePeriod = (index, period, isCustomPeriod = false) => {
    const linkDataProps = linkData?.filter(
      (l) => l.period.toLowerCase() === period.toLowerCase()
    );
    return (
      <ExclusivePeriod
        index={index}
        period={period}
        onSelectPeriod={onSelectPeriod}
        onChangeData={onChangeData}
        isCustomPeriod={isCustomPeriod}
        items={items}
        data={items[index]}
        periods={periods}
        onDeleteCustomPeriod={onDeleteCustomPeriod}
        linkDataProps={linkDataProps}
        contractTermKeyName={contractTermKeyName}
        onUpdateData={onUpdateData}
        {...contractTermProps[contractTermKeyName]}
      />
    );
  };

  const onSetDefaultPeriods = () => {
    setPeriods((prevPeriods) => {
      return prevPeriods.map((period, index) => {
        return {
          ...period,
          content: renderExclusivePeriod(
            index,
            period.description,
            period?.isCustomPeriod
          ),
          isCustomPeriod: period?.isCustomPeriod,
          isSelected: period?.isSelected,
        };
      });
    });
  };

  useEffect(() => {
    // overwrite default periods with selected items
    if (items?.length >= 1) {
      onSetDefaultPeriods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    if (items?.length >= 1) {
      let updatedPeriods = [...periods];
      items.forEach((item, id) => {
        const index = updatedPeriods.findIndex(
          (period) =>
            period.description.toLowerCase() === item.description.toLowerCase()
        );

        // selected item that is in default periods
        if (index >= 0) {
          updatedPeriods[index] = {
            ...updatedPeriods[index],
            ...item,
            isSelected: true,
            content: renderExclusivePeriod(id, item.description),
          };
        } else {
          // selected item - custom period
          updatedPeriods = [
            ...updatedPeriods,
            {
              ...item,
              isSelected: true,
              isCustomPeriod: true,
              content: renderExclusivePeriod(id, item.description),
            },
          ];
        }
      });

      onUpdateData(
        updatedPeriods.sort(
          (a, b) => order.indexOf(a.description) - order.indexOf(b.description)
        )
      );
    } else {
      onSetDefaultPeriods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <Typography variant="body1">Engagement Period</Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <IconButton onClick={onAddCustomPeriod}>
          <PlusIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <DragDropContainer items={periods} onDragEnd={onDragEnd} />
      </Grid>
    </Grid>
  );
};

export default (memo(EngagementPeriod): AbstractComponent<mixed>);
