import React, { useEffect, useState } from 'react';

import { HashLink } from 'react-router-hash-link';
import { HeadingsContainerStyled } from './styles';

const Headings = ({ headings }) => {
  const [activeId, setActiveId] = useState('');

  useEffect(() => {
    const hashText = window.location.hash;
    if (hashText) {
      setActiveId(hashText);
      document.querySelector(hashText).scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  const renderTraverseItems = (arrData = []) => {
    return (
      <ul>
        {arrData.map((heading) => (
          <li
            key={heading.id}
            className={`#${heading.id}` === activeId ? 'active' : ''}
          >
            <HashLink
              smooth
              to={`/info#${heading.id}`}
              onClick={() => setActiveId(`#${heading.id}`)}
            >
              {heading.title}
            </HashLink>
            {heading?.items?.length > 0 && renderTraverseItems(heading?.items)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <HeadingsContainerStyled>
      {renderTraverseItems(headings)}
    </HeadingsContainerStyled>
  );
};

export default Headings;
