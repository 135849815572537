/**
 *
 * useCreateContractFromTemplateMutation
 * @format
 * @flow
 *
 */

import type {
  ContractMutationResponse,
  MutationCreateContractFromTemplateArgs,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import contractMetaFragment from 'contract/graphQL/fragments/contractMetaFragment';
import contractTermsFragment from 'contract/graphQL/fragments/contractTerms';

const CREATE_CONTRACT_FROM_TEMPLATE = gql`
  mutation CreateContractFromTemplate($templateId: ID!, $projectId: ID!) {
    createContractFromTemplate(templateId: $templateId, projectId: $projectId) {
      code
      success
      message
      contract {
        ...contractMeta
        ...contractTerms
      }
    }
  }
  ${contractMetaFragment}
  ${contractTermsFragment}
`;

export type CreateContractFromTemplateReturnType = {
  createContractFromTemplate: ({
    variables: MutationCreateContractFromTemplateArgs,
  }) => void,
  loading: boolean,
  data: ContractMutationResponse,
  error: any,
};

export const useCreateContractFromTemplateMutation =
  (): CreateContractFromTemplateReturnType => {
    const [createContractFromTemplate, { data, loading, error }] = useMutation(
      CREATE_CONTRACT_FROM_TEMPLATE
    );

    return {
      createContractFromTemplate,
      loading,
      data,
      error,
    };
  };
