/**
 *
 * useUpdateCompanyContractingPartiesMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateCompanyContractingPartiesMutation,
  UpdateCompanyContractingPartiesMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { companyDetailsFragment } from 'company/graphQL/fragments/companyFragments';

const UPDATE_COMPANY_CONTRACTING_PARTIES = gql`
  mutation UpdateCompanyContractingParties(
    $data: UpdateCompanyContractingPartyInput!
  ) {
    updateCompanyContractingParties(data: $data) {
      company {
        ...companyDetails
      }
    }
  }
  ${companyDetailsFragment}
`;

export type UpdateCompanyContractingPartiesReturnType = {
  updateCompanyContractingParties: ({
    variables: UpdateCompanyContractingPartiesMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateCompanyContractingPartiesMutation,
  error: any,
};

export const useUpdateCompanyContractingPartiesMutation =
  (): UpdateCompanyContractingPartiesReturnType => {
    const [updateCompanyContractingParties, { data, loading, error }] =
      useMutation(UPDATE_COMPANY_CONTRACTING_PARTIES);

    return {
      updateCompanyContractingParties,
      loading,
      data,
      error,
    };
  };
