/**
 *
 * useUpdateContractMutation
 * @format
 * @flow
 *
 */

import type {
  ContractMutationResponse,
  MutationUpdateContractArgs,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import contractMetaFragment from 'contract/graphQL/fragments/contractMetaFragment';
import contractStandardTermSectionsFragment from 'contract/graphQL/fragments/contractStandardTermSections';
import contractTermsFragment from 'contract/graphQL/fragments/contractTerms';

const UPDATE_CONTRACT = gql`
  mutation UpdateContract(
    $contract: UpdateContractDetailsInput!
    $deleteMissingObjectAttributes: Boolean = true
  ) {
    updateContract(
      contract: $contract
      deleteMissingObjectAttributes: $deleteMissingObjectAttributes
    ) {
      code
      success
      message
      contract {
        ...contractMeta
        ...contractTerms
        ...contractStandardTermSections
      }
    }
  }
  ${contractMetaFragment}
  ${contractTermsFragment}
  ${contractStandardTermSectionsFragment}
`;

export type UpdateContractReturnType = {
  updateContract: ({
    variables: MutationUpdateContractArgs,
  }) => void,
  loading: boolean,
  data: ContractMutationResponse,
  error: any,
};

export const useUpdateContractMutation = (): UpdateContractReturnType => {
  const [updateContract, { data, loading, error }] = useMutation(
    UPDATE_CONTRACT,
    {
      onError: (err) => {
        console.error(err);
      },
    }
  );

  return {
    updateContract,
    loading,
    data,
    error,
  };
};
