/**
 *
 * Uploads
 * @format
 * @flow
 *
 */

import React, { useEffect, useState } from 'react';

import ExternalDocTab from 'app/components/ExternalDocTab/ExternalDocTab';
import { Grid } from '@mui/material';
import type { Node } from 'react';
import Tabs from 'app/components/Tabs/Tabs';
import externalDocsDefaultData from './externalDocsDefaultData';
import { useDocumentsQuery } from 'project/graphQL/useDocumentsQuery';
import { useParams } from 'react-router-dom';

const Uploads = (): Node => {
  const { ptid } = useParams();
  const { loading, error, data } = useDocumentsQuery({
    owner: ptid || '',
  });
  const [docsData, setDocsData] = useState(externalDocsDefaultData);

  useEffect(() => {
    if (loading === false && data) {
      const formattedDocuments = data.documents.reduce((acc, item) => {
        const {
          // $FlowFixMe
          _id,
          // $FlowFixMe
          tags,
          // $FlowFixMe
          fileName,
          // $FlowFixMe
          fileType,
          // $FlowFixMe
          mimeType,
          // $FlowFixMe
          notes,
          // $FlowFixMe
          uploadedBy,
          // $FlowFixMe
          whoDownloaded,
          // $FlowFixMe
          fileData,
          // $FlowFixMe
          createdAt,
          // $FlowFixMe
          updatedAt,
        } = item;
        const categoryTag =
          tags.find((x) => x.startsWith('category:')) ?? 'category:Other';
        const subCategoryTag =
          tags.find((x) => x.startsWith('subCategory:')) ??
          'subCategory:Unknown';
        const category = categoryTag.split(':')[1];
        const subCategory = subCategoryTag.split(':')[1];
        let prevFiles = [];

        if (acc[category]?.hasOwnProperty(subCategory)) {
          prevFiles = acc[category][subCategory]?.files;
        }

        return {
          ...acc,
          [category]: {
            ...{ ...acc[category] },
            [subCategory]: {
              ...(acc[category]?.hasOwnProperty(subCategory) && {
                ...acc[category][subCategory],
              }),
              files: [
                ...prevFiles,
                {
                  _id,
                  fileName,
                  fileType,
                  mimeType,
                  notes,
                  uploadedBy,
                  whoDownloaded,
                  url: fileData.downloadUrl,
                  createdAt,
                  updatedAt,
                },
              ],
            },
          },
        };
      }, {});

      const newDocsData = { ...docsData };

      for (const keyCategory in formattedDocuments) {
        const subCategoryItems = formattedDocuments[keyCategory];
        for (const keySubCategory in subCategoryItems) {
          // if the keyCategory does not exist in the defaults then we put it in other.
          if (!newDocsData[keyCategory]) {
            newDocsData['Other'][keySubCategory] =
              subCategoryItems[keySubCategory];
          } else {
            newDocsData[keyCategory][keySubCategory] =
              subCategoryItems[keySubCategory];
          }
        }
      }

      setDocsData(newDocsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <Grid container>
      <Tabs
        value="0"
        tabs={Object.keys(docsData).map((categoryKey, i) => {
          return {
            label: categoryKey,
            component: (
              <ExternalDocTab
                categoryKey={categoryKey}
                externalDocSubCat={externalDocsDefaultData[categoryKey]}
              />
            ),
          };
        })}
      />
    </Grid>
  );
};

export default Uploads;
