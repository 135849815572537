import { gql } from '@apollo/client';

export const projectUISelectValuesFragment = gql`
  fragment projectUISelectValues on UISelectValuesConfig {
    _id
    projectDetailSelectValues {
      genreValues {
        displayValue
        order
        typeInfo {
          id
          type
        }
      }
      releasePlatformValues {
        displayValue
        order
        typeInfo {
          id
          type
        }
      }
      projectStages {
        displayValue
        order
        typeInfo {
          id
          type
        }
      }
    }
  }
`;
