/**
 *
 * CompanyAccountSetting
 * @format
 * @flow
 *
 */

import { Link as LinkIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import type { AbstractComponent, Node } from 'react';
import React, { memo, useState } from 'react';
import RadioButton from '../RadioButton/RadioButton';
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup';
import type { PropsType } from './types';

const CompanyAccountSetting = (props: PropsType): Node => {
  const { fieldTitle = '', renderField } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [settings, setSettings] = useState({ contractOptionVal: '' });
  const [disabled, setDisabled] = useState(true);
  const { contractOptionVal } = settings;

  const contractOptions = [
    {
      id: '1',
      name: 'This contract only',
    },
    {
      id: '2',
      name: 'Default company setting',
    },
  ];

  const onOpenDialog = () => setIsOpenDialog(true);

  const onCloseDialog = () => setIsOpenDialog(false);

  const onValueChange = (inputId: string, inputVal: string) => {
    setSettings((prev) => ({ ...prev, [inputId]: inputVal }));
  };

  const onConfirm = () => {
    onCloseDialog();
    setDisabled(contractOptionVal !== '1');
  };

  const renderDialog = () => {
    return (
      <Dialog open={isOpenDialog} onClose={onCloseDialog}>
        <DialogContent>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {`Change ${fieldTitle}?`}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Do you want to change only this contract or the default company
            setting?
          </Typography>
          <RadioButtonGroup
            id="contractOptionVal"
            value={contractOptionVal}
            onValueChange={(id, val) => onValueChange(id, val)}
          >
            {contractOptions.map(({ id, name }) => (
              <RadioButton key={name} id={name} label={name} value={id} />
            ))}
          </RadioButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>Cancel</Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Grid container direction="row">
        {renderField(disabled)}
        <Grid item xs={1} alignSelf="center">
          <IconButton
            onClick={onOpenDialog}
            size="large"
            sx={{ marginLeft: 2 }}
          >
            <LinkIcon />
          </IconButton>
        </Grid>
      </Grid>
      {renderDialog()}
    </>
  );
};

export default (memo(CompanyAccountSetting): AbstractComponent<
  PropsType,
  mixed
>);
