import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  inputRoot: {
    color: 'inherit',
  },
  // inputInput: {
  //   padding: theme.spacing(3, 5, 2, 0),
  //   // vertical padding + font size from searchIcon
  //   paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //   transition: theme.transitions.create('width'),
  //   width: '100%',
  //   [theme.breakpoints.down('md')]: {
  //     display: 'none',
  //     marginLeft: 20,
  //   },
  // },
  helpIcon: {
    fontSize: '1em',
  },
  searchInput: {
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(9),
    },
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  searchIconOnly: {
    fontSize: '1em',
    color: 'white',
    [theme.breakpoints.up('md')]: { display: 'none' },
  },

  searchTextField: {
    width: '100%',
  },
  searchIconSvg: {
    fontSize: 30,
    '& path': {
      stroke: theme.palette.grey['600'],
    },
  },
}));
