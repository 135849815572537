// @flow
import * as React from 'react';

import { Paper } from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
import useStyles from 'contract/ContractView/ContractBuilder/ComponentWrapper/styles';

type Props = { children: any };

const ComponentWrapper = ({ children }: Props): React.Node => {
  const classes = useStyles();
  return (
    <Paper elevation={2} className={classes.paper}>
      {children}
    </Paper>
  );
};

export default ComponentWrapper;
