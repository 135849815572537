import * as React from 'react';

import { Box } from '@mui/material';
import SideMenu from './SideMenu/SideMenu';
import TopMenu from './TopMenu/TopMenu';
import ViewRouter from 'app/routes/ViewRouter';
import dashboardRoutes from 'app/routes/dashboardRoutes';
import menuData from './menuData.json';
import { useStyles } from './styles.js';

const DashboardPage = (props) => {
  const classes = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <TopMenu onToggleDrawClick={handleDrawerToggle} />
      <Box className={classes.contentBoxWrapper}>
        <Box className={classes.contentBox}>
          <SideMenu
            mobile
            open={mobileOpen}
            onClose={handleDrawerToggle}
            container={container}
            data={menuData}
          />
          <SideMenu data={menuData} />
          <Box className={classes.viewBox} component="main">
            <Box className={classes.marginBox}>
              <ViewRouter routeArray={dashboardRoutes} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardPage;
