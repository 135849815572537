/**
 *
 * ExclusivePeriod
 * @format
 * @flow
 *
 */
import type { AbstractComponent, Node } from 'react';
import type { ExclusivePeriodsPropsType } from './types';
import React, { memo, useState, useLayoutEffect, useEffect } from 'react';
import { Grid, Paper, IconButton } from '@mui/material';
import DatePicker from 'app/components/DatePicker/DatePicker';
import TextField from 'app/components/TextField/TextField';
import Checkbox from 'app/components/Checkbox/Checkbox';
import { Delete as DeleteIcon } from 'app/assets/icons';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import { format } from 'date-fns';

const ExclusivePeriod: AbstractComponent<ExclusivePeriodsPropsType> = ({
  index,
  period,
  textFieldLabel1,
  textFieldLabel2,
  onSelectPeriod,
  onChangeData,
  data,
  periods,
  onDeleteCustomPeriod,
  linkDataProps,
  contractTermKeyName,
  onUpdateData,
}: ExclusivePeriodsPropsType): Node => {
  const { description, exclusivity, otherDetails, isSelected, isCustomPeriod } =
    periods[index] || {};
  const { renderLinkData } = useLinkDataComponent();

  const [showFields, setShowFields] = useState(false);
  const [checked, setChecked] = useState(false);
  const [startDateVal, setStartDateVal] = useState('');
  const [endDateVal, setEndDateVal] = useState('');

  const sourceFieldIdStartDate =
    linkDataProps?.find((l) => l?.fieldName?.toLowerCase().includes('start'))
      ?.fieldName || 'customStartDate';
  const sourceFieldIdEndDate =
    linkDataProps?.find((l) => l?.fieldName?.toLowerCase().includes('end'))
      ?.fieldName || 'customEndDate';

  const startDate = periods[index]
    ? periods[index][sourceFieldIdStartDate]
    : '';
  const endDate = periods[index] ? periods[index][sourceFieldIdEndDate] : '';

  useLayoutEffect(() => {
    setShowFields(isSelected);
    setChecked(isSelected);
  }, [isSelected]);

  useEffect(() => {
    let updatedPeriods = [...periods];

    // sync default project settings for start date and end date
    if (startDateVal !== '' || endDateVal !== '') {
      updatedPeriods = updatedPeriods.map((period, id) => {
        if (id === index) {
          return {
            ...period,
            [sourceFieldIdStartDate]: startDate ? startDate : startDateVal,
            [sourceFieldIdEndDate]: endDate ? endDate : endDateVal,
          };
        }
        return period;
      });
      onUpdateData(updatedPeriods);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateVal, endDateVal]);

  // Use current date as default value for start date and end date
  const defaultDateVal = format(new Date().getTime(), 'yyyy-MM-dd');

  return (
    <Paper elevation={3}>
      <Grid container mb={showFields ? 5 : 0}>
        <Grid item>
          {!isCustomPeriod && (
            <Checkbox
              id="period"
              label={!isCustomPeriod && period}
              checked={checked || false}
              onCheck={(id, checked) => {
                setShowFields(checked);
                setChecked(checked);
                onSelectPeriod(period, checked, index);
              }}
              formControlLabelStyle={{
                marginRight: isCustomPeriod ? '0' : 'initial',
              }}
            />
          )}
        </Grid>
        {isCustomPeriod && (
          <>
            {' '}
            <Grid item>
              <TextField
                value={description || ''}
                containerStyle={{ marginBottom: '0' }}
                onBlur={(id, val) => {
                  onChangeData('description', val, index, isCustomPeriod);
                }}
              />
            </Grid>
            <Grid item ml="auto">
              <IconButton
                onClick={() => {
                  onDeleteCustomPeriod(index);
                }}
                sx={{ padding: '0' }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
      {showFields && (
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs={6}>
            <DatePicker
              id={sourceFieldIdStartDate}
              label="Start"
              value={startDate ?? defaultDateVal}
              onValueChange={(id, val) => {
                onChangeData(
                  sourceFieldIdStartDate,
                  format(new Date(val), 'yyyy-MM-dd'),
                  index
                );
              }}
              inputFormat={'MM/dd/yyyy'}
              renderLinkData={
                !isCustomPeriod &&
                renderLinkData(
                  startDate,
                  sourceFieldIdStartDate,
                  (sourcefieldvalue) => {
                    if (sourcefieldvalue !== null) {
                      setStartDateVal(sourcefieldvalue);
                    }
                  }
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id={sourceFieldIdEndDate}
              label="End"
              value={endDate ?? defaultDateVal}
              onValueChange={(id, val) => {
                onChangeData(
                  sourceFieldIdEndDate,
                  format(new Date(val), 'yyyy-MM-dd'),
                  index
                );
              }}
              inputFormat={'MM/dd/yyyy'}
              renderLinkData={
                !isCustomPeriod &&
                renderLinkData(
                  endDate,
                  sourceFieldIdEndDate,
                  (sourcefieldvalue) => {
                    if (sourcefieldvalue !== null) {
                      setEndDateVal(sourcefieldvalue);
                    }
                  }
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={textFieldLabel1}
              value={exclusivity || ''}
              onBlur={(_id, val) => {
                onChangeData('exclusivity', val, index);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="textVal2"
              label={textFieldLabel2}
              value={otherDetails || ''}
              onBlur={(_id, val) => {
                onChangeData('otherDetails', val, index);
              }}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};
export default (memo(ExclusivePeriod): AbstractComponent<mixed>);
