/**
 *
 * ChangeDetailsDialog
 * @format
 * @flow
 *
 */

import React, { memo, useContext, useEffect } from 'react';
import type { AbstractComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import type { PropsType } from './types';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import useLinkDataComponent from 'app/hooks/useLinkedDataComponent';
import { LinkDataContext } from '../../contexts/LinkDataContext';
import { ContractContext } from 'app/contexts/Contract/Contract';
import {
  useProjectChangedAffectedContractsLazyQuery,
  ProjectChangedAffectedContractsLazyReturnType,
} from 'contract/graphQL/useProjectChangedAffectedContractsQuery';

const ChangeDetailsDialog: AbstractComponent<PropsType> = () => {
  const [
    getAffectedContracts,
    { loading: isLoadingGetAffectedContracts, data: affectedContractsData },
  ]: ProjectChangedAffectedContractsLazyReturnType =
    useProjectChangedAffectedContractsLazyQuery();
  const { renderComponent, getLinkDataProps } = useLinkDataComponent();

  const {
    ptid,
    isOpenChangeDetailsDialog,
    onSetIsOpenChangeDetailsDialog,
    componentName,
    linkDataProps: { linkData },
    newFieldValue,
    onSetIsOpenAffectedContractsDialog,
    onSetAllAffectedContractsData,
    fieldName,
    setNewFieldValue,
  } = useContext(LinkDataContext);

  const { fieldLabel, fieldId, componentType } = getLinkDataProps(
    linkData,
    fieldName
  );

  const { contractTermProps } = useContext(ContractContext);
  const renderComponentProps =
    contractTermProps[componentName.contractTermKeyName];
  const handleOnSaveDetails = async () => {
    getAffectedContracts({
      variables: {
        _id: ptid,
        uiComponentName: componentName.component,
        fieldKey: fieldId,
        fieldValue: newFieldValue,
      },
    });
  };

  useEffect(() => {
    if (affectedContractsData?.projectChangedAffectedContracts?.length) {
      onSetIsOpenChangeDetailsDialog(false);
      onSetIsOpenAffectedContractsDialog(true);
      onSetAllAffectedContractsData(
        affectedContractsData?.projectChangedAffectedContracts,
        isLoadingGetAffectedContracts
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affectedContractsData]);

  return (
    <Dialog
      open={isOpenChangeDetailsDialog}
      onClose={() => {
        onSetIsOpenChangeDetailsDialog(false);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          {`Change ${fieldLabel}`}
        </Typography>
        {renderComponent(componentType, {
          value: newFieldValue,
          onChange: (_id, val) => {
            setNewFieldValue(val);
          },
          ...renderComponentProps,
        })}
      </DialogContent>
      <DialogActions sx={{ alignItems: 'center', gap: '8px' }}>
        <Button
          onClick={() => {
            onSetIsOpenChangeDetailsDialog(false);
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <LoadingButton
          isLoading={isLoadingGetAffectedContracts}
          loadingText="Saving..."
          defaultText="Save"
          onClick={handleOnSaveDetails}
          containerStyle={{
            marginTop: '0',
          }}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

export default (memo(ChangeDetailsDialog): AbstractComponent<PropsType, mixed>);
