/**
 *
 * useUpdateCompanyDetailsMutation
 * @format
 * @flow
 *
 */

import type {
  UpdateCompanyDetailsMutation,
  UpdateCompanyDetailsMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

import { companyDetailsFragment } from 'company/graphQL/fragments/companyFragments';

const UPDATE_COMPANY_DETAILS = gql`
  mutation UpdateCompanyDetails($company: UpdateCompanyDetailsInput!) {
    updateCompanyDetails(company: $company) {
      company {
        ...companyDetails
      }
    }
  }
  ${companyDetailsFragment}
`;

export type UpdateCompanyDetailsReturnType = {
  updateCompanyDetails: ({
    variables: UpdateCompanyDetailsMutationVariables,
  }) => void,
  loading: boolean,
  data: UpdateCompanyDetailsMutation,
  error: any,
};

export const useUpdateCompanyDetailsMutation =
  (): UpdateCompanyDetailsReturnType => {
    const [updateCompanyDetails, { data, loading, error }] = useMutation(
      UPDATE_COMPANY_DETAILS
    );

    return {
      updateCompanyDetails,
      loading,
      data,
      error,
    };
  };
