/**
 *
 * useProjectChangedAffectedContractsQuery
 * @format
 * @flow
 *
 */

import { gql, useLazyQuery } from '@apollo/client';

import contractSummaryFragment from 'contract/graphQL/fragments/contractSummaryFragment';

const PROJECT_CHANGED_AFFECTED_CONTRACTS = gql`
  query GetProjectChangedAffectedContracts(
    $_id: ID!
    $uiComponentName: String!
    $fieldKey: String!
    $fieldValue: String!
  ) {
    projectChangedAffectedContracts(
      _id: $_id
      uiComponentName: $uiComponentName
      fieldKey: $fieldKey
      fieldValue: $fieldValue
    ) {
      ...contractSummary
    }
  }
  ${contractSummaryFragment}
`;

export type ProjectChangedAffectedContractsLazyReturnType = [
  any,
  { loading: boolean, data: any, error: any }
];

export const useProjectChangedAffectedContractsLazyQuery =
  (): ProjectChangedAffectedContractsLazyReturnType => {
    const query = useLazyQuery<any>(PROJECT_CHANGED_AFFECTED_CONTRACTS, {
      notifyOnNetworkStatusChange: true,
    });

    return query;
  };
