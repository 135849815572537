// @flow

import { Grid, Typography } from '@mui/material';

import React from 'react';

type Props = {
  color?: string | null,
  children: string | null,
  align?: string | null,
  variant?: string | null,
};

const Title = ({
  color = 'primary',
  children,
  align,
  variant = 'h4',
}: Props): React$Element<any> => {
  return (
    <Grid>
      <Typography align={align} variant={variant} gutterBottom color={color}>
        {children}
      </Typography>
    </Grid>
  );
};

export default Title;
