import { Paper as MPaper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

const Paper = ({ children }) => {
  const classes = useStyles();
  return <MPaper className={classes.MPaper}>{children}</MPaper>;
};

export default Paper;
