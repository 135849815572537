/**
 *
 * useUpdateProjectContractsMutation
 * @format
 * @flow
 *
 */

import { gql, useMutation } from '@apollo/client';

import { projectSummaryFragment } from 'project/graphQL/fragments/projectFragments';

const UPDATE_PROJECT_CONTRACTS = gql`
  mutation UpdateProjectContracts(
    $_id: ID!
    $uiComponentName: String!
    $fieldKey: String!
    $fieldValue: String!
    $contractIds: [ID!]
    $termId: String!
    $sourceFieldKey: String!
  ) {
    updateProjectContracts(
      _id: $_id
      uiComponentName: $uiComponentName
      fieldKey: $fieldKey
      fieldValue: $fieldValue
      contractIds: $contractIds
      termId: $termId
      sourceFieldKey: $sourceFieldKey
    ) {
      project {
        ...projectSummary
        schedule {
          preProductionStart
          preProductionEnd
          productionStart
          productionEnd
          postProductionStart
          postProductionEnd
          finalMaterialsDelivery
        }
      }
    }
  }
  ${projectSummaryFragment}
`;

export type UpdateProjectContractsReturnType = {
  updateProjectContracts: ({
    variables: {
      _id: string,
      uiComponentName: string,
      fieldKey: string,
      fieldValue: string,
      contractIds?: Array<string>,
    },
  }) => void,
  loading: boolean,
  data: any,
  error: any,
};

export const useUpdateProjectContractsMutation =
  (): UpdateProjectContractsReturnType => {
    const [updateProjectContracts, { data, loading, error }] = useMutation(
      UPDATE_PROJECT_CONTRACTS
    );

    return {
      updateProjectContracts,
      loading,
      data,
      error,
    };
  };
