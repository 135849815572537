/**
 *
 * Contract Nav Bar
 * @format
 * @flow
 *
 */

import { useState, useContext } from 'react';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Drawer, useStyles } from './styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ArrowDown } from 'app/assets/icons';

const ContractNavBar = ({ contractData, open }) => {
  const { sectionNames, terms } = contractData || {};
  const { onSetExpandedContractSectionTerms, contractExpandedPanels } =
    useContext(ContractContext);

  const classes = useStyles();
  const theme = useTheme();

  const expandedPanelsData = Array(sectionNames?.length).fill(false);
  const [expandedPanels, setExpandedPanels] = useState(expandedPanelsData);

  const onAccordionChange =
    (i, sectionName) => (e, newExpanded) => {
      let newExpandedPanels = [...expandedPanels];
      newExpandedPanels[i] = newExpanded ? true : false;
      setExpandedPanels(newExpandedPanels);
      onSetExpandedContractSectionTerms({
        ...contractExpandedPanels,
        expandedPanels: newExpandedPanels,
        contractSection: {
          sectionName,
          index: i,
        },
        termId: undefined,
      });
    };

  const onClickTerms = (termId) => {
    onSetExpandedContractSectionTerms({
      ...contractExpandedPanels,
      termId,
    });
  };

  return (
    <Box className={classes.drawerWrapper}>
      <Drawer variant="persistent" open={open}>
        <Box className={classes.drawerInnerContainer}>
          {sectionNames?.map((sectionName, id) => {
            const termComponents = terms.filter(
              (item) => item.uiComponentSectionIndex.indexOf(id) !== -1
            );
            return (
              <Accordion
                key={id}
                onChange={onAccordionChange(id, sectionName)}
                expanded={expandedPanels[id]}
                elevation={1}
                sx={{
                  '&:before': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDown color={theme.palette.grey['700 ']} />}
                  aria-controls={`panel-${id + 1}-content`}
                  id={`panel-${id + 1}-header`}
                >
                  <Typography
                    variant="menuTitle"
                    sx={{ color: theme.palette.grey['750'], fontWeight: '500' }}
                  >
                    {sectionName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {termComponents.map(
                      ({ props: { switchTitle }, termId }, i) => (
                        <ListItem
                          key={`termComponent-${i}`}
                          className={classes.accordionDetailsListItem}
                          onClick={() => {
                            onClickTerms(termId);
                          }}
                          selected={contractExpandedPanels?.termId === termId}
                        >
                          <ListItemText>{switchTitle}</ListItemText>
                        </ListItem>
                      )
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Drawer>
    </Box>
  );
};

export default ContractNavBar;
