/**
 *
 * PredictiveTextField
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { TextField } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import type { PropsType } from './types';
import Typography from '@mui/material/Typography';
import { PredictiveTextFieldContainer, AutocompleteStyled } from './styles';

const PredictiveTextField = (props: PropsType): Node => {
  const {
    id,
    label,
    freeSolo,
    options,
    inputValue,
    onInputChange,
    sortByGroup,
    onBlur,
    renderLinkData,
  } = props;

  const [value, setValue] = useState(inputValue || '');

  useEffect(() => {
    setValue(inputValue || '');
  }, [inputValue]);

  // take a copy of the options before sorting
  const sortedOptions = [...options].sort(
    (a, b) => -b.idGroup.localeCompare(a.idGroup)
  );

  const inputValueChange = (e, value) => {
    setValue(value);

    if (onInputChange) {
      onInputChange(e, value);
    }
  };

  const onTextBlur = () => {
    if (onBlur) {
      onBlur(id, String(value));
    }
  };

  return (
    <PredictiveTextFieldContainer>
      <AutocompleteStyled
        id={id}
        freeSolo={freeSolo}
        inputValue={value}
        onInputChange={inputValueChange}
        options={sortedOptions}
        groupBy={(option) => sortByGroup && option.idGroup}
        getOptionLabel={(option) => (option.selectVal ? option.selectVal : '')}
        renderInput={(params) => (
          <>
            {label && (
              <Typography
                variant="body2"
                sx={{ marginBottom: 1, color: 'rgba(0, 0, 0, 0.6)' }}
              >
                {label}
              </Typography>
            )}
            <TextField {...params} onBlur={onTextBlur} />
          </>
        )}
      />
      {renderLinkData && renderLinkData}
    </PredictiveTextFieldContainer>
  );
};

export default (memo(PredictiveTextField): AbstractComponent<PropsType, mixed>);
