/**
 *
 * NetProfitPay
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import React, { memo, useContext } from 'react';

import CompanyAccountSetting from 'app/components/CompanyAccountSetting/CompanyAccountSetting';
import { ContractContext } from 'app/contexts/Contract/Contract';
import { Grid } from '@mui/material';
import type { PropsType } from '../types';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import TextGeneratingCheckbox from 'app/components/TextGeneratingCheckbox/TextGeneratingCheckbox';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';

const NetProfitPay = ({ contractTermKeyName }: PropsType): Node => {
  const {
    contractTermData,
    contractTermProps,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    netProfitLabel = 'Net Profit Share',
    revenueShareLabel = 'Revenue share payable',
    checkboxLabel = 'Reduced for Proportional Use of Script',
    showCheckbox = true,
    revenueOptions = [],
    approvalCheckboxText = '',
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    netProfitShare = '',
    revenueSharePayable = '',
    approvalCheckboxTextValue = '',
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      netProfitShare: '',
      revenueSharePayable: '',
      approvalCheckboxTextValue: '',
    },
  });

  const onUpdateData = (fieldName: string, fieldValue: string | boolean) =>
    onUpdate(contractTermKeyName, { [fieldName]: fieldValue });

  return (
    <>
      <Grid item xs={1.5}>
        <TextField
          id="netProfitShare"
          label={netProfitLabel}
          value={netProfitShare}
          numberOnly
          endAdornment={<>%</>}
          onBlur={onUpdateData}
        />
      </Grid>
      <Grid item xs={12}>
        <CompanyAccountSetting
          fieldTitle="when the revenue share is payable"
          renderField={(disabled: boolean) => {
            return (
              <Grid item xs={11}>
                <Select
                  id="revenueSharePayable"
                  label={revenueShareLabel}
                  value={revenueSharePayable}
                  defaultValue={revenueSharePayable}
                  fullWidth
                  disabled={disabled}
                  onSelectOption={(item) => {
                    !Array.isArray(item) &&
                      onUpdateData('revenueSharePayable', item.value);
                  }}
                  options={revenueOptions.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                />
              </Grid>
            );
          }}
        />
      </Grid>
      {showCheckbox && (
        <TextGeneratingCheckbox
          id="approvalCheckboxTextValue"
          label={checkboxLabel}
          value={approvalCheckboxTextValue}
          textGenerating={approvalCheckboxText}
          onCheck={onUpdateData}
        />
      )}
    </>
  );
};

export default (memo(NetProfitPay): AbstractComponent<PropsType, mixed>);
