import { useContext, useEffect } from 'react';
import TextField from 'app/components/TextField/TextField';
import PredictiveTextField from 'app/components/PredictiveTextField/PredictiveTextField';
import Select from 'app/components/Select/Select';
import LinkData from 'app/components/LinkData/LinkData';
import DatePicker from 'app/components/DatePicker/DatePicker';
import { LinkDataContext } from 'app/components/LinkData/contexts/LinkDataContext';

import { useProjectDetailsLazyQuery } from 'project/graphQL/useProjectDetailsQuery';
import { useCompanyDetailsLazyQuery } from 'company/graphQL/useCompanyDetailsQuery';
import { useUpdateProjectContractsMutation } from 'project/graphQL/useUpdateProjectContractsMutation';
import { useUpdateCompanyDetailsMutation } from 'company/graphQL/useUpdateCompanyDetailsMutation';
import { format } from 'date-fns';

const useLinkDataComponent = () => {
  const [getProjectDetails, { data: projectData, loading: projecDataLoading }] =
    useProjectDetailsLazyQuery();
  const [
    getCompanyDetails,
    { data: companyData, loading: companyDataLoading },
  ] = useCompanyDetailsLazyQuery();
  const { updateProjectContracts } = useUpdateProjectContractsMutation();
  const { updateCompanyDetails } = useUpdateCompanyDetailsMutation();
  const {
    cyid,
    ptid,
    componentName,
    linkDataProps,
    selectedAffectedContracts,
    onSetSourceData,
    sourceData,
    fieldName,
    onSetIsDataLoading,
  } = useContext(LinkDataContext) || {};

  const { linkData } = linkDataProps || {};

  useEffect(() => {
    if (projectData) {
      onSetSourceData({
        ...sourceData,
        data: projectData?.project,
      });
    }
    if (companyData) {
      onSetSourceData({
        ...sourceData,
        data: companyData?.company,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, companyData]);

  useEffect(() => {
    if (projecDataLoading || companyDataLoading) {
      onSetIsDataLoading(true);
    } else {
      onSetIsDataLoading(false);
    }
  }, [projecDataLoading, companyDataLoading, onSetIsDataLoading]);

  const isDataSynchronized = (fieldValue, data, sourceFieldId) =>
    fieldValue === getDescendantSourceValue(data, sourceFieldId);

  const renderComponent = (componentType: string, componentProps) => {
    const { options, value, onChange } = componentProps || {};
    switch (componentType) {
      case 'textField':
        return <TextField onBlur={onChange} {...componentProps} />;
      case 'predictiveTextField':
        return (
          <PredictiveTextField
            id="listSelect"
            sortByGroup
            freeSolo
            options={options}
            inputValue={value}
            onBlur={onChange}
          />
        );
      case 'select':
        return (
          <Select
            {...componentProps}
            options={options.map((item) => ({
              value: item,
              label: item,
            }))}
            onBlur={onChange}
          />
        );
      case 'datePicker':
        return (
          <DatePicker
            {...componentProps}
            inputFormat={'MM/dd/yyyy'}
            onValueChange={(_id, val) => {
              onChange(_id, format(new Date(val), 'yyyy-MM-dd'));
            }}
          />
        );
      default:
        return <div />;
    }
  };

  const renderLinkData = (value, fieldName = '', applyDefaultSettings) => {
    return (
      <LinkData
        value={value}
        fieldName={fieldName}
        applyDefaultSettings={applyDefaultSettings}
      />
    );
  };

  const getToolTipBody = (isSynchronized: boolean) => {
    if (isSynchronized) {
      return 'Synchronized';
    } else {
      return 'Not Synchronized';
    }
  };

  const loadLazyQuery = (source: string) => {
    switch (source) {
      case 'project':
        getProjectDetails({ variables: { _id: ptid } });
        return;
      case 'company':
        getCompanyDetails({ variables: { _id: cyid } });
        return;
      default:
        return undefined;
    }
  };

  const getMutation = (source: string, value: string) => {
    const { fieldId, sourceFieldId } =
      getLinkDataProps(linkData, fieldName) || {};
    switch (source) {
      case 'project':
        return {
          mutateFunc: updateProjectContracts,
          variables: {
            _id: ptid,
            uiComponentName: componentName.component,
            fieldKey: fieldId,
            fieldValue: value,
            contractIds: selectedAffectedContracts,
            termId: componentName.contractTermKeyName.split('-')[1],
            sourceFieldKey: sourceFieldId,
          },
        };
      case 'company':
        return {
          mutateFunc: updateCompanyDetails,
          variables: {
            company: {
              _id: cyid,
              [sourceFieldId]: value,
            },
          },
        };
      default:
        return undefined;
    }
  };

  // supports nested sourceFieldId, e.g. 'project.schedule.productionStart'
  const getDescendantSourceValue = (data, path) =>
    path?.split('.')?.reduce((acc, part) => acc && acc[part], data);

  const getLinkDataProps = (linkDataProps, fieldId) => {
    return (
      (Array.isArray(linkDataProps) &&
        linkDataProps?.find((p) =>
          p?.fieldName ? p.fieldName === fieldId : p?.fieldId === fieldId
        )) ||
      {}
    );
  };
  return {
    isDataSynchronized,
    renderComponent,
    renderLinkData,
    getToolTipBody,
    loadLazyQuery,
    getMutation,
    getDescendantSourceValue,
    getLinkDataProps,
  };
};

export default useLinkDataComponent;
