/**
 *
 * useProjectsQuery
 * @format
 * @flow
 *
 */

import { gql, useQuery } from '@apollo/client';

import type { GetProjectUiSelectValuesQuery } from 'app/services/graphQL/generated-types';
import { projectUISelectValuesFragment } from 'project/graphQL/fragments/uiSelectValuesFragments';

const PROJECT_UI_SELECT_VALUES = gql`
  query GetProjectUISelectValues {
    uiSelectValuesConfig {
      ...projectUISelectValues
    }
  }
  ${projectUISelectValuesFragment}
`;

export type UISelectValuesConfigReturnType = {
  loading: boolean,
  data: GetProjectUiSelectValuesQuery,
  error: any,
  refetch: () => void,
};

export const useUISelectValuesConfigQuery =
  (): UISelectValuesConfigReturnType => {
    const { loading, data, error, refetch } =
      useQuery<GetProjectUiSelectValuesQuery>(PROJECT_UI_SELECT_VALUES);

    return {
      loading,
      data,
      error,
      refetch,
    };
  };
