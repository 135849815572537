/**
 *
 * Spv
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { PropsType, SpvType } from './types';
import React, { memo, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import TextField from 'app/components/TextField/TextField';

const Spv: AbstractComponent<PropsType> = (props: PropsType): Node => {
  const {
    spvs,
    showSaveButton = true,
    isSaving = false,
    onUpdateData,
    onUpdateProjectSPVs = () => {},
  } = props;

  const [combinedState, setCombinedState] = useState<SpvType>({
    companyName: '',
    type: '',
    abn: 0,
    acn: 0,
    address: {
      number: 0,
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
  });

  const { companyName, abn, acn, address } = combinedState;
  const { street, suburb, state, postcode, country } = address || {};

  useEffect(() => {
    if (spvs?.length) {
      setCombinedState({
        ...spvs[0],
      });
    }
  }, [spvs]);

  const handleUpdateDateData = (
    fieldName: string,
    fieldValue: number | string
  ) => {
    // $FlowFixMe
    setCombinedState((prevState) => {
      let newData = {};

      if (
        ['street', 'suburb', 'state', 'postcode', 'country'].includes(fieldName)
      ) {
        newData = {
          ...prevState,
          address: { ...prevState.address, [fieldName]: fieldValue },
        };
      } else {
        newData = { ...prevState, [fieldName]: fieldValue };
      }

      onUpdateData(newData);
      return newData;
    });
  };

  return (
    <Grid container>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="companyName"
            label="Company full name"
            value={companyName || ''}
            onBlur={handleUpdateDateData}
            tooltipBody="Include full name company name here (including Pty Ltd etc)."
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="street"
            label="Street"
            value={street || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="suburb"
            label="City / Suburb"
            value={suburb || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="state"
            label="State"
            value={state || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="postcode"
            label="Postcode"
            value={postcode || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="country"
            label="Country"
            value={country || ''}
            autoComplete="none"
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="abn"
            label="ABN"
            value={abn || ''}
            numberOnly
            onBlur={handleUpdateDateData}
          />
        </Grid>
        <Grid item lg={5.5} md={5.5} xs={12}>
          <TextField
            id="acn"
            label="ACN"
            value={acn || ''}
            numberOnly
            onBlur={handleUpdateDateData}
          />
        </Grid>
      </Grid>
      {showSaveButton && (
        <Grid item xs>
          <LoadingButton
            isLoading={isSaving}
            loadingText="Saving..."
            defaultText="Save"
            onClick={onUpdateProjectSPVs}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default (memo(Spv): AbstractComponent<PropsType, mixed>);
