/**
 *
 * Contract Top Bar
 * @format
 * @flow
 *
 */

import { ContractContext } from 'app/contexts/Contract/Contract';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Breadcrumbs,
  Typography,
  IconButton,
  MenuItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import { Container } from './styles';
import Menu from 'app/components/core/Menu/Menu';
import DownloadDocsModal from './DownloadDocsModal';

import {
  GreyContract as ContractIcon,
  MoreVertical as MoreActionsIcon,
} from 'app/assets/icons';
import { useStyles } from './styles.js';

const ContractTopBar = ({ contractData, ptid, cyid, partyName }) => {
  const classes = useStyles();
  const {
    onSaveContractData,
    isLoadingSavingContract,
    isLoadingSaveAndCreateWordDoc,
    onSaveContractAndCreateWordDocument,
  } = useContext(ContractContext);
  const theme = useTheme();
  const [actionsEl, setActionsEl] = useState(null);
  const [docsModalOpen, setDocsModalOpen] = useState(false);

  return (
    <Container maxWidth="false">
      {ptid ? (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb" separator="/">
                  <Link to={`/dashboard/${cyid}/projects`}>Projects</Link>
                  <Link to={`/dashboard/${cyid}/project/${ptid}?tab=1`}>
                    Contributor Contracts
                  </Link>
                  <Typography color="text.primary">
                    {contractData?.contractTemplateName || ''}
                  </Typography>
                </Breadcrumbs>
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Grid container columnSpacing={1} alignItems="center">
                      <Grid item>
                        <LoadingButton
                          isLoading={isLoadingSavingContract}
                          loadingText="Saving..."
                          defaultText="Save Draft"
                          containerStyle={{ marginTop: '0' }}
                          onClick={onSaveContractData}
                          variant="secondary"
                        />
                      </Grid>
                      {/* TODO: Add more actions; Share and Sign button */}
                      <Grid item>
                        <IconButton
                          sx={{ padding: '0' }}
                          onClick={(e) => setActionsEl(e.currentTarget)}
                          className={classes.moreActionTopBar}
                        >
                          <MoreActionsIcon />
                        </IconButton>
                        <Menu
                          id="saved-documents"
                          anchorEl={actionsEl}
                          keepMounted
                          open={Boolean(actionsEl)}
                          onClose={() => setActionsEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          className={classes.menu}
                        >
                          <MenuItem
                            onClick={() => {
                              setDocsModalOpen(true);
                              setActionsEl(null);
                            }}
                          >
                            Saved documents
                          </MenuItem>
                        </Menu>
                        <DownloadDocsModal
                          documents={contractData?.documents || []}
                          modalOpen={docsModalOpen}
                          onClose={() => setDocsModalOpen(false)}
                        />
                      </Grid>
                      <Grid item>
                        <LoadingButton
                          isLoading={isLoadingSaveAndCreateWordDoc}
                          loadingText="Creating..."
                          defaultText="Create Contract"
                          onClick={() =>
                            onSaveContractAndCreateWordDocument([
                              'category:Other',
                              'subCategory:Contracts',
                            ])
                          }
                          containerStyle={{ marginTop: '0' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <ContractIcon
                      style={{
                        height: '32px',
                        width: '32px',
                        padding: '6px',
                        background: theme.palette.grey['200'],
                        borderRadius: '8px',
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h1" color="text.primary">
                      {contractData?.contractTemplateName || ''} | {partyName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link to={`/dashboard/${cyid}/contracts`}>Contracts</Link>
          <Typography color="text.primary">
            {contractData?.contractTemplateName || ''}
          </Typography>
        </Breadcrumbs>
      )}
    </Container>
  );
};

export default ContractTopBar;
