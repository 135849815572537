/**
 *
 * useContractTemplatesQuery
 * @format
 * @flow
 *
 */

import { gql, useQuery } from '@apollo/client';

import type { GetAllContractTemplatesInfoQuery } from 'app/services/graphQL/generated-types';

const CONTRACT_TEMPLATES_INFO = gql`
  query GetAllContractTemplatesInfo {
    contractTemplates {
      _id
      title
      info {
        shortDescription
        fullDescription
        notes
      }
    }
  }
`;

export type ContractTemplatesReturnType = {
  loading: boolean,
  data: GetAllContractTemplatesInfoQuery,
  error: any,
};

export const useContractTemplatesQuery = (): ContractTemplatesReturnType => {
  const { loading, data, error } = useQuery<GetAllContractTemplatesInfoQuery>(
    CONTRACT_TEMPLATES_INFO
  );

  return {
    loading,
    data,
    error,
  };
};
