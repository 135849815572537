/**
 *
 * useContractDetailsQuery
 * @format
 * @flow
 *
 */

import type {
  GetContractDetailsQuery,
  GetContractDetailsQueryVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useQuery } from '@apollo/client';

import contractMetaFragment from 'contract/graphQL/fragments/contractMetaFragment';
import contractStandardTermSectionsFragment from 'contract/graphQL/fragments/contractStandardTermSections';
import contractTermsFragment from 'contract/graphQL/fragments/contractTerms';

const CONTRACT_DETAILS_INFO = gql`
  query GetContractDetails($_id: ID!) {
    contract(_id: $_id) {
      ...contractMeta
      ...contractTerms
      ...contractStandardTermSections
    }
  }
  ${contractMetaFragment}
  ${contractTermsFragment}
  ${contractStandardTermSectionsFragment}
`;

export type ContractDetailsReturnType = {
  loading: boolean,
  data: GetContractDetailsQuery,
  error: any,
};

export const useContractDetailsQuery = ({
  _id,
}: GetContractDetailsQueryVariables): ContractDetailsReturnType => {
  const { loading, data, error } = useQuery<GetContractDetailsQuery>(
    CONTRACT_DETAILS_INFO,
    { variables: { _id } }
  );

  return {
    loading,
    data,
    error,
  };
};
