/**
 *
 * useCreateCompanyMutation
 * @format
 * @flow
 *
 */

import type {
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
} from 'app/services/graphQL/generated-types';
import { gql, useMutation } from '@apollo/client';

const CREATE_COMPANY = gql`
  mutation CreateCompany($company: CreateFunctionalCompanyInput!) {
    createCompany(company: $company) {
      company {
        _id
      }
    }
  }
`;

export type CreateCompanyReturnType = {
  createCompany: ({
    variables: CreateCompanyMutationVariables,
  }) => void,
  loading: boolean,
  data: CreateCompanyMutation,
  error: any,
};

export const useCreateCompanyMutation = (): CreateCompanyReturnType => {
  const [createCompany, { data, loading, error }] = useMutation(CREATE_COMPANY);

  return {
    createCompany,
    loading,
    data,
    error,
  };
};
