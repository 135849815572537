import {
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import IconWithMenu from 'app/containers/IconWithMenu/IconWithMenu';
import { useTheme } from '@mui/material/styles';

import { useStyles } from './styles.js';
import {
  Search as SearchIcon,
  PlusSmall,
  ArrowDown,
  Account,
} from 'app/assets/icons';
import Button from 'app/components/core/Button/Button';

const IconsMenuBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchAnchorElement, setSearchAnchorElement] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const helpIconData = {
    icon: <HelpIcon className={classes.helpIcon} />,
    menuData: [
      {
        type: 'menu_item',
        text: 'Help',
        url: '/help',
      },
      {
        type: 'menu_item',
        text: 'This contract',
        url: '/contract/help',
      },
      {
        type: 'menu_item',
        text: 'Wiki',
        url: '/Wiki',
      },
      {
        type: 'menu_item',
        text: 'Ask a Lawyer',
        url: '/Wiki',
      },
      {
        type: 'divider',
      },
      {
        type: 'menu_item',
        text: 'Tech Support',
        url: '/support',
      },
      {
        type: 'menu_item',
        text: 'BusinessAffairs.com',
        url: '/businessAffairs',
      },
    ],
  };

  const profileIconData = {
    icon: (
      <Avatar
        sx={{
          width: '48px',
          height: '48px',
          bgcolor: theme.palette.primary.background,
        }}
      >
        <Account />
      </Avatar>
    ),
    menuData: [
      {
        type: 'menu_item',
        text: 'User Profile',
        url: '/dashboard/profile',
      },
      {
        type: 'divider',
      },
      {
        type: 'menu_item',
        text: 'Company Name',
        url: '/company/1',
      },
      {
        type: 'divider',
      },
      {
        type: 'menu_item',
        text: 'All Projects Dashboard',
        url: '/company/1/projects',
      },
      // {
      //   type: 'functional_menu_item',
      //   text: 'Logout',
      //   onClick: () => {
      //     instance.logoutRedirect({
      //       postLogoutRedirectUri:
      //         process.env.REACT_APP_AZ_B2C_POST_LOGOUT_REDIRECT_URI,
      //     });
      //   },
      // },
      {
        type: 'logout_menu_item',
        text: 'Logout',
      },
    ],
  };

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      direction="row"
      alignItems="center"
    >
      <Grid item className={classes.searchInput} xs={6}>
        <TextField
          className={classes.searchTextField}
          placeholder="search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIconSvg} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item className={classes.searchIconOnly}>
        <IconButton
          aria-controls="simple-menu-search"
          aria-haspopup="true"
          onClick={(e) => setSearchAnchorElement(e.currentTarget)}
          color="inherit"
          size="large"
        >
          <SearchIcon className={classes.searchIconOnly} />
        </IconButton>
        <Menu
          id="simple-menu-search"
          anchorEl={searchAnchorElement}
          keepMounted
          open={Boolean(searchAnchorElement)}
          onClose={() => setSearchAnchorElement(null)}
        >
          <MenuItem>
            <TextField
              className={classes.searchTextField}
              fullWidth
              variant="outlined"
              placeholder="search..."
              label="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item display="flex" gap={2}>
        <Button
          variant="secondary"
          startIcon={<PlusSmall />}
          endIcon={<ArrowDown />}
          minWidth={104}
        >
          New
        </Button>
        <IconWithMenu iconAndMenu={profileIconData} />
      </Grid>
    </Grid>
  );
};

export default IconsMenuBar;
