/**
 *
 * @format
 * @flow
 *
 */

import * as React from 'react';

import { MenuItemStartIcon, MenuItemTitle } from './styles';

// $FlowFixMe
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import Collapse from '@mui/material/Collapse';
import ExpandMenuButton from './ExpandMenuButton';
import type { ExpandableMenuItemPropsType } from './types';
import { ExpandableMenuItemRoot } from './styles';
import { ListItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const ExpandableMenuItem: React.AbstractComponent<ExpandableMenuItemPropsType> =
  ({
    title,
    startIcon: Icon,
    children,
    expandable,
    to,
    activeChild,
  }: ExpandableMenuItemPropsType): React.Node => {
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);

    const navLinkStyle = {
      display: 'flex',
      padding: '8px',
      textDecoration: 'none',
      color: `${theme.palette.text.secondary}`,
      textAlign: 'left',
      alignItems: 'center',
    };

    return (
      <>
        <ListItem
          sx={{
            margin: '0px',
            padding: '0px',
            border: '0px',
            '&:hover': {
              '.expMenuItem': {
                color: `${theme.palette.primary.main}`,
              },
            },
          }}
        >
          {expandable ? (
            <ButtonUnstyled
              component={ExpandableMenuItemRoot}
              title={title}
              onClick={() => setExpanded(!expanded)}
              activeChild={activeChild}
            >
              <MenuItemStartIcon className="expMenuItem">
                {Icon}
              </MenuItemStartIcon>
              <MenuItemTitle className="expMenuItem" variant={'subtitle2'}>
                {title}
              </MenuItemTitle>
              <ExpandMenuButton
                expand={expanded}
                visibility={expandable ? 'visible' : 'hidden'}
              />
            </ButtonUnstyled>
          ) : (
            <NavLink
              exact
              to={to ?? ''}
              style={navLinkStyle}
              activeStyle={{ color: theme.palette.primary.light }}
              className="navLink"
            >
              <MenuItemStartIcon className="expMenuItem">
                {Icon}
              </MenuItemStartIcon>
              <MenuItemTitle className="expMenuItem" variant={'subtitle2'}>
                {title}
              </MenuItemTitle>
              <ExpandMenuButton
                expand={expanded}
                visibility={expandable ? 'visible' : 'hidden'}
              />
            </NavLink>
          )}
        </ListItem>

        {expandable && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {children}
          </Collapse>
        )}
      </>
    );
  };

export default (React.memo(ExpandableMenuItem): React.AbstractComponent<
  ExpandableMenuItemPropsType,
  mixed
>);
