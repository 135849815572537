/**
 *
 * Contract Standard Terms Condition Parser
 * @format
 * @flow
 *
 */

const getExpressionValue = (condition, contractTermData) => {
  const { termId, operator, conditionalValue, termDataIndex } = condition;
  // eslint-disable-next-line no-unused-vars
  const [_, contractData] = contractTermData.find(
    ([termName, termData], i) => {
      const contractTermId = termName?.split('-')[1];
      if (contractTermId === termId) return termData;
      return undefined;
    }
  );

  if (operator === '==') {
    return (
      contractData[termDataIndex]?.toString()?.toLowerCase() ===
      conditionalValue?.toString()?.toLowerCase()
    );
  } else if (operator === '!=') {
    return (
      contractData[termDataIndex].toString().toLowerCase() !==
      conditionalValue.toString().toLowerCase()
    );
  } else if (operator === '>') {
    return contractData[termDataIndex] > conditionalValue;
  } else if (operator === '<') {
    return contractData[termDataIndex] < conditionalValue;
  } else {
    return undefined;
  }
};

export const processContractStandardTermSections = (
  contractStandardTermSections,
  contractTermData
) => {
  return contractStandardTermSections?.map(
    (standardTermSections, standardTermSectionIdx) => {
      const { terms } = standardTermSections || {};
      const updatedTerms = terms?.filter(
        (term) =>
          !term?.references?.length ||
          // references is an OR array; if any of the references is true, then the term is rendered
          term?.references?.some((reference) =>
            getExpressionValue(reference, Object.entries(contractTermData))
          )
      );
      return {
        ...standardTermSections,
        terms: updatedTerms,
      };
    }
  );
};
