/**
 *
 * FeeMilestones
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import type { ComponentDataType, ComponentPropsType } from './types';
import { Grid, IconButton } from '@mui/material';
import React, { memo, useContext, useState, useCallback } from 'react';

import { ContractContext } from 'app/contexts/Contract/Contract';
import OptionalMilestone from 'app/components/OptionalMilestone/OptionalMilestone';
import PaymentSchedule from 'app/components/PaymentSchedule/PaymentSchedule';
import type { PropsType } from '../types';
import TextField from 'app/components/TextField/TextField';
import useSetContractTermDefaultData from 'app/hooks/useSetContractTermDefaultData';
import { MoreVertical, Filter as FilterIcon } from 'app/assets/icons';
import { useTheme } from '@mui/material/styles';
import Tooltip from 'app/components/Tooltip/Tooltip';

const FeeMilestones = ({ contractTermKeyName }: PropsType): Node => {
  const theme = useTheme();
  const {
    contractTermProps,
    contractTermData,
    onUpdateContractTermData: onUpdate,
  } = useContext(ContractContext);

  const {
    feeLabel = 'Fee',
    defaultsMilestonesList = [],
    allowedMilestoneNames,
    optionalTitle,
  }: ComponentPropsType = contractTermProps[contractTermKeyName] || {};

  const {
    fee = '',
    milestoneOptionsValues = [],
    totalAmount = 0,
    totalPercentage = 0,
  }: ComponentDataType = contractTermData[contractTermKeyName] || {};

  const [open, setOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  useSetContractTermDefaultData({
    contractTermKeyName,
    defaultValues: {
      fee: '',
      milestoneOptionsValues: defaultsMilestonesList.map(
        (defaultMilestones) => ({
          name: defaultMilestones,
          percentage: '0',
          amount: '0',
        })
      ),
      totalPercentage: 0,
      totalAmount: 0,
    },
    dependency: [defaultsMilestonesList],
  });

  const toggleDialog = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const sumInputsValues = (totalArray) => {
    return totalArray.reduce((prev, cur) => prev + cur, 0);
  };

  const calculateTotals = useCallback(
    (data) => {
      let amountArr = [];
      let percentageArr = [];

      const milestones = data || [];

      milestones?.forEach((val) => {
        const { amount, percentage } = val;
        amountArr.push((+(!percentage ? 0 : percentage) * +fee) / 100);
        percentageArr.push((+(!amount ? 0 : amount) * 100) / +fee);
      });

      const sumPercentage = sumInputsValues(percentageArr);
      const sumAmount = sumInputsValues(amountArr);

      onUpdate(contractTermKeyName, {
        totalPercentage: sumPercentage,
        totalAmount: sumAmount,
      });
    },
    [contractTermKeyName, fee, onUpdate]
  );

  const onUpdateData = useCallback(
    (fieldName: string, fieldValue) => {
      onUpdate(contractTermKeyName, { [fieldName]: fieldValue });
      calculateTotals(fieldValue);
    },
    [contractTermKeyName, onUpdate, calculateTotals]
  );

  const iconBtnStyle = {
    padding: '0',
    height: '40px',
    width: '40px',
    border: `1px solid ${theme.palette.grey['400']}`,
  };

  return (
    <>
      <Grid container direction="row" flexWrap="noWrap">
        <Grid item xs={3}>
          <TextField
            id="fee"
            label={feeLabel}
            value={Number(fee)}
            formatMoney
            numberOnly
            startAdornment={<>$</>}
            onValueChange={onUpdateData}
          />
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Tooltip body="Add optional milestones" placement="top-end">
              <IconButton sx={iconBtnStyle}>
                <FilterIcon
                  onClick={() => {
                    toggleDialog();
                    setIsCustom(false);
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip body="Add custom milestones" placement="top-end">
              <IconButton sx={iconBtnStyle}>
                <MoreVertical
                  onClick={() => {
                    setIsCustom(true);
                    toggleDialog();
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <OptionalMilestone
        onDialogClose={toggleDialog}
        dialogOpen={open}
        allowedPropNames={allowedMilestoneNames}
        optionalMilestones={milestoneOptionsValues}
        onUpdate={(_, checkData) => {
          onUpdateData('milestoneOptionsValues', checkData);
        }}
        optionalTitle={isCustom ? 'Custom Milestones' : optionalTitle}
        isCustom={isCustom}
        calculateTotals={(data) => calculateTotals(data)}
      />
      <PaymentSchedule
        value={{
          milestoneOptionsValues: Array.isArray(milestoneOptionsValues)
            ? milestoneOptionsValues
            : [...milestoneOptionsValues?.generalMilestones],
          fee,
          totalAmount,
          totalPercentage,
        }}
        onUpdate={(fieldName, data) => onUpdateData(fieldName, data)}
        calculateTotals={(data) => calculateTotals(data)}
      />
    </>
  );
};

export default (memo(FeeMilestones): AbstractComponent<PropsType, mixed>);
