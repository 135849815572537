/**
 *
 * ExternalDocTab
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import React, { memo } from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import ExternalDocItem from 'app/components/ExternalDocItem/ExternalDocItem';
import ExternalDocUpload from 'app/components/ExternalDocUpload/ExternalDocUpload';
import type { PropsType } from './types';
import { isEqual } from 'lodash';

const ExternalDocTab: AbstractComponent<PropsType> = ({
  categoryKey,
  externalDocSubCat,
}: PropsType): Node => {
  return (
    <>
      <Grid container>
        <div style={{ width: '100%' }}>
          {Object.keys(externalDocSubCat).map((subCategoryKey, i) => {
            const files = externalDocSubCat[subCategoryKey]?.files;
            return (
              <Accordion key={subCategoryKey}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel-${subCategoryKey}-content`}
                  id={`panel-${subCategoryKey}-header`}
                >
                  <Typography>{subCategoryKey}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {files.map((file) => (
                    <div key={file._id}>
                      <ExternalDocItem fileInfo={file} />
                    </div>
                  ))}

                  {!files.length && (
                    <Typography variant="body2">
                      No files uploaded yet.
                    </Typography>
                  )}

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ExternalDocUpload
                      isContractParty={
                        subCategoryKey ===
                        'subCategory:Uploaded (non bam) contracts'
                      }
                      tags={[
                        `category:${categoryKey}`,
                        `subCategory:${subCategoryKey}`,
                      ]}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Grid>
      <Grid container justifyContent="flex-end" sx={{ marginTop: 4 }}>
        <ExternalDocUpload
          isContractParty={false}
          tags={[categoryKey, '']}
          isAddAnotherDocType
        />
      </Grid>
    </>
  );
};

const areEqual = (prev, next) => {
  return !isEqual(prev.externalDocSubCat, next.externalDocSubCat);
};

export default (memo(ExternalDocTab, areEqual): AbstractComponent<
  PropsType,
  mixed
>);
