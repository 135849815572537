/**
 *
 * SelectProjectModal
 * @format
 * @flow
 *
 */
import type { AbstractComponent, Node } from 'react';
import React, { memo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Select from 'app/components/Select/Select';
import TextField from 'app/components/TextField/TextField';
import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import { Close as CloseIcon, PlusSmall as PlusIcon } from 'app/assets/icons';
import { useProjectsViewProjectsQuery } from 'project/graphQL/useProjectsViewProjectsQuery';
import { useCreateProjectMutation } from 'project/graphQL/useCreateProjectMutation';

const SelectProjectModal = ({
  open,
  onClose,
  onSelectProject,
  createContractFromTemplateLoading,
}): Node => {
  const { cyid } = useParams();
  const { data } = useProjectsViewProjectsQuery();
  const { createProject, loading: createProjectLoading } =
    useCreateProjectMutation();
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isNewProjectSelected, setIsNewProjectSelected] = useState(false);

  const projects =
    data?.projects?.map((p) => ({
      label: p.title,
      value: p._id,
    })) || [];

  useEffect(() => {
    if (selectedProjectId === 'newProject') {
      setIsNewProjectSelected(true);
    } else {
      setIsNewProjectSelected(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  useEffect(() => {
    if (isNewProjectSelected) {
      if (!newProjectTitle) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }
  }, [isNewProjectSelected, newProjectTitle]);

  const onCreateNewProject = () => {
    createProject({
      variables: {
        project: {
          company: cyid,
          title: newProjectTitle,
          genreTypeId: 0,
          genreOtherSpecify: '',
          single: true,
          noOfEpisodes: 0,
          duration: 0,
          currency: 'AUD',
          form: 'test form',
          initialReleasePlatformTypeId: 0,
          initialReleaseOtherSpecify: '',
          shortDescription: '',
          shootLocation: '',
          developmentBudgets: [],
          productionBudget: 0,
          companySPVs: [],
        },
      },
    }).then(({ data }) => {
      const projectId = data?.createProject?.project?._id || '';
      onSelectProject(projectId);
    });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={() => onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: '10px' }}>
            <Typography variant="title">
              Select a project or create a new one
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '30px' }}>
            <Select
              id="project"
              value={selectedProjectId || ''}
              options={[
                {
                  label: (
                    <Box style={{ fontWeight: 600, display: 'flex' }}>
                      <PlusIcon />{' '}
                      <Typography sx={{ fontWeight: 600 }} mt={0.2}>
                        New Project
                      </Typography>
                    </Box>
                  ),
                  value: 'newProject',
                },
                ...projects,
              ]}
              onSelectOption={(item) => {
                setSelectedProjectId(item.value);
              }}
            />
          </Grid>
          {isNewProjectSelected && (
            <>
              <Grid item xs={12} sx={{ marginBottom: '40px' }}>
                <TextField
                  id="newProjectTitle"
                  label="Project Title"
                  value={newProjectTitle || ''}
                  onBlur={(id, val) => {
                    setNewProjectTitle(val, newProjectTitle);
                  }}
                  error={hasError}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              sx={{ marginBottom: '40px' }}
            >
              <Button onClick={(e) => {}}>Cancel</Button>
              <LoadingButton
                isLoading={
                  createProjectLoading || createContractFromTemplateLoading
                }
                loadingText="Creating..."
                defaultText="Create"
                onClick={() => {
                  if (!hasError) {
                    if (selectedProjectId === 'newProject') {
                      // create new project
                      onCreateNewProject();
                    } else {
                      onSelectProject(selectedProjectId);
                    }
                  }
                }}
                containerStyle={{ marginTop: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default (memo(SelectProjectModal): AbstractComponent<mixed>);
