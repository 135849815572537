import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import Table from 'app/components/core/Table/Table';
import {
  Close as CloseIcon,
  MoreVertical as MoreActionsIcon,
  Delete as DeleteIcon,
  DocDownload as DownloadIcon,
} from 'app/assets/icons';
import { useStyles } from './styles.js';
import { format } from 'date-fns';

const DownloadDocsModal = ({ documents, modalOpen, onClose }) => {
  const classes = useStyles();
  const [actionsEl, setActionsEl] = useState(null);

  const downloadFile = (fileUrl, nameOfFile, fileExt) => {
    // anchor link
    const element = document.createElement('a');
    element.href = fileUrl;
    element.download = `${nameOfFile}.${fileExt}`;
    //$FlowFixMe
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    //$FlowFixMe
    document.body.removeChild(element);
  };

  const columns = [
    {
      name: '_id',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'fileName',
      label: 'File name',
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Date',
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: '',
      options: {
        customBodyRender: (value, tableMeta) => {
          const { fileData, fileName, fileType } = value || {};
          return (
            <>
              <MoreActionsIcon
                aria-controls="table-actions"
                aria-haspopup="true"
                onClick={(e) => setActionsEl(e.currentTarget)}
                style={{ cursor: 'pointer' }}
              />
              <Menu
                id="table-actions"
                anchorEl={actionsEl}
                keepMounted
                open={Boolean(actionsEl)}
                onClose={() => setActionsEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                className={classes.menu}
              >
                <MenuItem
                  className={classes.menuItemTest}
                  onClick={() => {
                    setActionsEl(null);
                    onClose();
                  }}
                >
                  <DeleteIcon className={classes.icon} />
                  Delete
                </MenuItem>
                <MenuItem
                  className={classes.menuItemTest}
                  onClick={() => {
                    onClose();
                    downloadFile(fileData?.downloadUrl, fileName, fileType);
                  }}
                >
                  <DownloadIcon className={classes.icon} />
                  Download
                </MenuItem>
              </Menu>
            </>
          );
        },
      },
    },
  ];
  const options = {
    filterType: 'checkbox',
    selectableRows: false,
    pagination: false,
    download: false,
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
  };

  return (
    <Dialog open={modalOpen} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Table
          data={documents.map((doc, id) => {
            const { _id, fileName, createdAt, fileType, fileData } = doc;
            return [
              _id,
              `${fileName}.${fileType}`,
              format(new Date(parseInt(createdAt)), 'dd MMM yyyy'),
              {
                fileData,
                fileName,
                fileType,
              },
            ];
          })}
          columns={columns}
          tableOptions={options}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DownloadDocsModal;
