import ContainerBase from '@mui/material/Container';
import { styled } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';

const Container = styled(ContainerBase)(({ theme, mobile }) => ({
  position: 'sticky',
  flexBasis: '100%',
  flexGrow: '1',
  left: '256px',
  width: 'calc(100% - 256px)',
  padding: '20px 24px',
  top: '80px',
  background: theme.palette.background.paper,
  zIndex: '2',
  borderBottom: `1px solid ${theme.palette.grey['300']}`,
  [theme.breakpoints.down('lg')]: {
    left: '70px',
    width: '100%',
  },
}));

const useStyles = makeStyles((theme) => ({
  moreActionTopBar: {
    height: '40px',
    width: '40px',
    border: `1px solid ${theme.palette.grey['400']}`,
  },
  menu: {
    '& .MuiPaper-root': {
      padding: '16px',
    },
  },
  menuItemTest: {
    padding: '5px 0',
  },
  icon: {
    marginRight: '10px',
    '& path': {
      stroke: theme.palette.grey['600'],
    },
  },
}));

export { Container, useStyles };
