/**
 *
 * ExternalDocUpload
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import {
  Add as AddIcon,
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Typography,
} from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';

import LoadingButton from 'app/components/LoadingButton/LoadingButton';
import type { PropsType } from './types';
import TextField from 'app/components/TextField/TextField';
import Textarea from 'app/components/Textarea/Textarea';
import Tooltip from 'app/components/Tooltip/Tooltip';
import { useCreateDocumentMutation } from 'project/graphQL/useCreateDocumentMutation';
import { useDocumentsQuery } from 'project/graphQL/useDocumentsQuery';
import { useParams } from 'react-router-dom';

const ExternalDocUpload: AbstractComponent<PropsType> = ({
  isContractParty,
  tags,
  isAddAnotherDocType,
}: PropsType): Node => {
  const { ptid } = useParams();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenFileExistDialog, setIsOpenFileExistDialog] = useState(false);
  const docDetailsInitVal = useMemo(
    () => ({
      project: ptid || '',
      tags,
      fileName: '',
      fileType: '',
      mimeType: '',
      notes: '',
      contractType: '',
      partyName: '',
    }),
    [ptid, tags]
  );
  const [docDetails, setDocDetails] = useState(docDetailsInitVal);
  const [docFileData, setDocFileData] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const {
    createDocument,
    data,
    loading,
    // eslint-disable-next-line no-unused-vars
    error,
  } = useCreateDocumentMutation();
  const { data: allDocumentData, refetch } = useDocumentsQuery({
    owner: ptid || '',
  });
  const isLoading = loading || isUploading;
  const [anotherDocType, setAnotherDocType] = useState('');

  useEffect(() => {
    if (data) {
      const documentUploadUrl =
        data.createDocument.document?.fileData.uploadUrl;
      handleStartFileUpload(documentUploadUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onOpenDialog = () => setIsOpenDialog(true);

  const onCloseDialog = () => setIsOpenDialog(false);

  const onClickSubmit = () => {
    if (!docDetails.fileName) {
      setUploadError('Select a file to upload!');
    } else if (isAddAnotherDocType && !anotherDocType) {
      setUploadError('Please provide the name for another doc type field!');
    } else if (uploadError) {
      return;
    } else {
      onCreateDocument();
    }
  };

  const onCreateDocument = () => {
    const newDocDetails = {
      ...docDetails,
      ...(anotherDocType && { tags: [tags[0], anotherDocType] }),
    };
    createDocument({ variables: { document: newDocDetails } });
  };

  const onOverwriteDocument = () => {
    onCreateDocument();
  };

  const handleStartFileUpload = async (uploadUrl: string = '') => {
    try {
      setIsUploading(true);
      const { statusText = '' } = await fetch(uploadUrl, {
        method: 'PUT',
        body: docFileData,
      });
      if (statusText === 'OK') {
        refetch();
        setIsUploading(false);
        onCloseDialog();
        setDocDetails(docDetailsInitVal);
        setAnotherDocType('');
      }
    } catch (error) {
      setUploadError('Failed to upload file!');
      setIsUploading(false);
      console.log('Failed to upload', error);
    }
  };

  const handleFileCapture = ({ target }) => {
    setUploadError('');
    const { name, type: mimeType, size } = target.files[0] || {};
    const fileType = name.substr(name.lastIndexOf('.') + 1);
    const fileName = name.substring(0, name.lastIndexOf('.')) || name;

    // allowed files
    const supportedFiles = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
    ];

    // allowed max file size
    const isGt25MB = size / 1024 / 1024 > 25;

    const isFileNameAlreadyExist = allDocumentData.documents.find(
      (item) => item?.fileName === fileName
    );

    setDocDetails((prevState) => ({
      ...prevState,
      fileName,
      fileType,
      mimeType,
    }));

    if (supportedFiles.indexOf(target.files[0].type) === -1) {
      setUploadError(
        'You can only upload this supported files (doc, docx, pdf, xls & xlsx)!'
      );
    } else if (isGt25MB) {
      setUploadError('Document must not exceed 25MB!');
    } else if (isFileNameAlreadyExist) {
      setUploadError('The filename already exist!');
      setIsOpenFileExistDialog(true);
      setDocFileData(target.files[0]);
    } else {
      setDocFileData(target.files[0]);
    }
  };

  const onUpdateDocDetailsData = (
    fieldName: string,
    fieldValue: string | boolean
  ) =>
    setDocDetails((prevState) => ({ ...prevState, [fieldName]: fieldValue }));

  const renderFileAlreadyExistModal = () => {
    return (
      <Dialog open={isOpenFileExistDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <Typography variant="h6" sx={{ color: 'red' }}>
            The file already exist! Do you want to overwrite it?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpenFileExistDialog(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setIsOpenFileExistDialog(false);
              setUploadError('');
              onOverwriteDocument();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderUploadModal = () => {
    const { fileName, notes, partyName, contractType } = docDetails;
    return (
      <Dialog
        open={isOpenDialog}
        onClose={onCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Add new document</Typography>
            <IconButton onClick={onCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Button component="label" startIcon={<CloudUploadIcon />}>
            <input type="file" hidden onChange={handleFileCapture} />
            {fileName ? fileName : 'Select file'}
          </Button>
          {isAddAnotherDocType && (
            <TextField
              id="anotherDocType"
              label="Another Doc Type"
              value={anotherDocType}
              onBlur={(_, val) => {
                // $FlowFixMe
                setAnotherDocType(val);
              }}
            />
          )}
          <Textarea
            label="Notes"
            minRows={3}
            id="notes"
            value={notes}
            onBlur={onUpdateDocDetailsData}
          />
          {isContractParty && (
            <>
              <TextField
                id="partyName"
                label="Party Name"
                value={partyName}
                onBlur={onUpdateDocDetailsData}
              />
              <TextField
                id="contractType"
                label="Contract Type"
                value={contractType}
                onBlur={onUpdateDocDetailsData}
              />
            </>
          )}
          {uploadError && (
            <Typography variant="h6" sx={{ color: 'red' }}>
              {uploadError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCloseDialog();
              setUploadError('');
              setDocDetails(docDetailsInitVal);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            isLoading={isLoading}
            loadingText="Submitting..."
            defaultText="Submit"
            onClick={onClickSubmit}
            containerStyle={{ marginTop: 0 }}
          />
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {isAddAnotherDocType ? (
        <Tooltip body="Add another type" placement="top-end">
          <Fab color="primary" aria-label="add" onClick={onOpenDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      ) : (
        <IconButton onClick={onOpenDialog} size="large" sx={{ marginLeft: 2 }}>
          <CloudUploadIcon />
        </IconButton>
      )}
      {renderUploadModal()}
      {renderFileAlreadyExistModal()}
    </>
  );
};

export default (memo(ExternalDocUpload): AbstractComponent<PropsType, mixed>);
