/**
 *
 * TitleAndDescriptionInput
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Paper } from '@mui/material';
import React, { memo } from 'react';

import type { PropsType } from './types';
import TextArea from 'app/components/Textarea/Textarea';
import TextField from 'app/components/TextField/TextField';

const TitleAndDescriptionInput: AbstractComponent<PropsType> = (
  props: PropsType
): Node => {
  const { title, shortDescription, handleStateChange } = props;

  return (
    <Paper elevation={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="title"
            label="Title"
            value={title}
            onBlur={handleStateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextArea
            id="shortDescription"
            label="Short description"
            value={shortDescription}
            onBlur={handleStateChange}
            maxRows={5}
            minRows={2}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default (memo(TitleAndDescriptionInput): AbstractComponent<
  PropsType,
  mixed
>);
