/**
 *
 * SelectDescription
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import React, { memo, useCallback, useEffect, useState } from 'react';

import Checkbox from 'app/components/core/Checkbox/Checkbox';
import Dialog from 'app/components/core/Dialog/Dialog';
import { Grid } from '@mui/material';
import type { PropsType } from './types';

const SelectDescription = (props: PropsType): Node => {
  const { dialogRef, value = '', options = [], onUpdate } = props;
  const [checkBoxValue, setCheckboxValue] = useState({});

  useEffect(() => {
    if (value) {
      //this line convert the string with newline `val1\nval2` into an object {val1 : false , val2: false ...}
      const newValue = value
        .split('\n')
        .reduce((prev, cur) => ({ ...prev, [cur]: true }), {});
      setCheckboxValue(newValue);
    }
  }, [value]);

  const onCheckboxChange = (property: string, eventCheck: boolean) => {
    setCheckboxValue({ ...checkBoxValue, [property]: eventCheck });
  };

  const handleCheckAll = (checkVal) => {
    const newCheckBoxValue = options.reduce(
      (prev, cur) => ({ ...prev, [cur]: checkVal }),
      {}
    );
    setCheckboxValue(newCheckBoxValue);
  };

  const isAllCheckboxSelected = useCallback(() => {
    const currentOptions = options.reduce(
      (prev, cur) => ({ ...prev, [cur]: true }),
      {}
    );

    if (JSON.stringify(currentOptions) === JSON.stringify(checkBoxValue)) {
      return true;
    }

    return false;
  }, [checkBoxValue, options]);

  const onSubmit = () => {
    // filter the selected vals (val === true)
    let newValArray = [];
    Object.keys(checkBoxValue).map((key) => {
      //collect the true values
      if (checkBoxValue[key] === true) {
        newValArray.push(key);
      }
      return newValArray;
    });
    //Reset the data.
    setCheckboxValue({});
    onUpdate(newValArray);
    dialogRef?.current.open(false);
  };

  return (
    <Dialog
      // $FlowFixMe
      ref={dialogRef}
      title="Select items and edit as required"
      labelTwo="Add Items"
      onClickTwo={onSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Checkbox
            id="all"
            label="Select all"
            value={isAllCheckboxSelected()}
            onCheck={(_, val) => {
              handleCheckAll(val);
            }}
          />
        </Grid>
        {options.map((option, i) => {
          return (
            <Grid item xs={12} key={i}>
              <Checkbox
                id={option}
                label={option}
                value={checkBoxValue[option] || false}
                onCheck={(id, val) => onCheckboxChange(option, val)}
              />
            </Grid>
          );
        })}
      </Grid>
    </Dialog>
  );
};

export default (memo(SelectDescription): AbstractComponent<PropsType, mixed>);
