/**
 *
 * ContractTermMenu
 * @format
 * @flow
 *
 */

import type { AbstractComponent, Node } from 'react';
import { Grid, Typography } from '@mui/material';
import React, { memo } from 'react';

// $FlowFixMe
import type { PropsType } from './types';
// $FlowFixMe
import ReactMarkdown from 'react-markdown';
import Tooltip from '../Tooltip/Tooltip';
// $FlowFixMe
import rehypeRaw from 'rehype-raw';
// $FlowFixMe
import remarkGfm from 'remark-gfm';
import { useTheme } from '@mui/material/styles';
import { InfoFilledICon } from './styles';

const ContractTermMenu = (props: PropsType): Node => {
  const theme = useTheme();

  const {
    disableAdditionalDetail = true,
    infoTooltipHtmlBody,
    onClickAdditionalDetail,
  } = props;

  return (
    <Grid container alignItems="center" gap={0.75}>
      <Grid item>
        <Typography
          fontWeight="600"
          sx={{ color: theme.palette.grey['750'], cursor: 'pointer' }}
          onClick={onClickAdditionalDetail}
          disabled={disableAdditionalDetail}
        >
          Add Detail
        </Typography>
      </Grid>
      {infoTooltipHtmlBody ? (
        <Tooltip
          trigger="hover"
          htmlBody={
            <ReactMarkdown
              children={JSON.parse(`"${infoTooltipHtmlBody || ''}"`)}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            />
          }
        >
          <InfoFilledICon />
        </Tooltip>
      ) : (
        <InfoFilledICon />
      )}
    </Grid>
  );
};

export default (memo(ContractTermMenu): AbstractComponent<PropsType, mixed>);
