/**
 *
 * @format
 * @flow
 *
 */

import * as React from 'react';

import { Drawer, List, MobileDraw } from './styles';

import SideMenuItems from './SideMenuItems';
import CompanySwitchMenu from './CompanySwitchMenu';
import type { SideMenuPropsType } from './types';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { HelpIcon } from 'app/assets/icons';

const SideMenu: React.AbstractComponent<SideMenuPropsType> = ({
  data,
  mobile,
  open,
  onClose,
  container,
}: SideMenuPropsType): React.Node => {
  const theme = useTheme();

  if (mobile) {
    return (
      <MobileDraw
        container={container}
        variant="temporary"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={open}
        onClose={onClose}
        sx={{
          display: { xs: 'block', sm: 'none' },
          zIndex: 1000,
        }}
      >
        <List>
          <SideMenuItems data={data} />
        </List>
      </MobileDraw>
    );
  }
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
        zIndex: 1000,
      }}
      open
    >
      <List>
        <CompanySwitchMenu />
        <SideMenuItems data={data} />
      </List>
        <Box>
          <NavLink
            to={'/'}
            style={{
              padding: '4px 9px',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              color: theme.palette.text.secondary,
            }}
          >
            <HelpIcon />
            Get Help
          </NavLink>
        </Box>
    </Drawer>
  );
};

export default (React.memo(SideMenu): React.AbstractComponent<
  SideMenuPropsType,
  mixed
>);
